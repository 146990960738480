import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const sx = {
    title: {
        fontFamily: "Inter",
        fontWeight: 600,
        fontSize: 18,
    },
    description: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: 12,
        color: "#5B5C5C"
    }
};

function FieldWrapper(props) {
    return (
        <Grid
            item
            xs={6}
            pb={props?.pb || 2}
            px={props?.px || 2}
            maxWidth={props?.width ? props.width : 300}
            {...(props?.GridItemProps || {})}
            style={{ flexGrow: props?.flexGrow ? 1 : undefined }}
        >
            <Typography sx={sx.title} pb={props?.paddingBottom||""} >
                {props.title}
            </Typography>
            <Typography sx={sx.description} >
                {props.description}
            </Typography>
            {props.children}
            <FormHelperText error>{props.error && props.helperText}</FormHelperText>
        </Grid>
    );
}

export default FieldWrapper;