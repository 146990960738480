import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CenterFlexBox from 'components/CenterFlexBox';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { SUB_CATEGORIES_DESCRIPTION, Tips, Tags, Categories } from './constants';
import { makeStyles } from "@mui/styles";
import { ParsingLookup } from 'utils/features';
import { ArrayParameter } from './ParameterScore';

const useStyles = makeStyles(theme => ({
    errorBox: {
        // backgroundColor:"#E3F4FF",
        display: "flex",
        minHeight: "300px",
        [theme.breakpoints.down(980)]: {
            flexDirection: "column"
        }
    },
}))

const sx = {
    root: {
        width: '100%',
        position: "relative",
        px: 2,
        mt: "25px"
    },
    flexBox: {
        display: 'flex',
        flexDirection: "column",
        gap: 1,
    },
    number: {
        border: "1px solid white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 22,
        height: 22,
        borderRadius: 22,
        ml: 1,
        backgroundColor: "white",
        color: "#02569D",
        fontFamily: "inter",
        fontSize: 12,
        fontWeight: 600,
    },
    subheadings: {
        color: "#02569D",
        fontSize: "13px",
        fontWeight: "600",
        mb:"2px",
        textTransform: "capitalize"
    },
    description: {
        color: "#5B5C5C",
        fontSize: "13px",
        fontWeight: "500"
    },
    repeatedBox:{
        fontSize: "13px",
        border: "1px solid #02569D",
        color: "#02569D",
        fontWeight: "500",
        borderRadius: "5px",
        padding: "3px 5px"
    },
}

function Tag({ tag }) {
    const { subCategory, value } = tag;
    const showNumber = ["FILLER_WORDS", "FILLER_PHRASES"].includes(subCategory);

    return value ? (
        <Box py={"2px"}>
            <Typography
                sx={sx.subheadings}
            >
                {subCategory.toLowerCase().replace("_", " ")}
            </Typography>
            <Typography fontWeight={500} color="GrayText" fontSize={12}>
                {SUB_CATEGORIES_DESCRIPTION[subCategory]}
            </Typography>
            {Array.isArray(value) ?
                showNumber ? (
                    <ArrayParameter
                        data={value}
                    />
                ) : (
                    <ArrayParameter data={value} />
                ) : (
                    <Typography fontWeight={500} fontSize={14}>
                        {value}
                    </Typography>
                )
            }
        </Box>
    ) : null;
}

function Tip({ tip }) {
    return (
        <Typography sx={sx.description}>
            {tip}
        </Typography>
    );
}

function NoTags({category}) {
    return (
        <Box>
            <Typography sx={sx.description}>
            {
                Tags?.[category]
            }
            </Typography>
        </Box>
    );
}

function AnalysisError() {
    const classes= useStyles();
    return (
        <CenterFlexBox className={classes.errorBox} p={2} gap={4}>
            <img
                src={"https://assets.languify.in/images/speaker-in-pc.svg"}
                alt="Happy Student"
                height={100}
            />
            <div>
                <Typography fontWeight={600} fontSize={16} lineHeight={"100%"}>
                    Parameter-wise analysis<br />not generated
                </Typography> <br />
                <Typography fontWeight={500} fontSize={12} >
                    Either you didn’t attempt the question <br />
                    or your answer was not recorded properly
                </Typography>

            </div>
        </CenterFlexBox>
    );
}

export default function TagsContainer({
    tags = [], error, score = 0, category = '',
}) {

    const level = React.useMemo(() => (
        score >= 70 ? "high" : score >= 40 ? "okay" : "low"
        ), [score]);
        
        const parsedTags = React.useMemo(() => {
            const parsedTags = [];
            tags.filter(tag => tag.category === category)
            .forEach((tag) => {
                const value = ParsingLookup[tag.subCategory](tag)
                if (value !== null) {
                    parsedTags.push({ ...tag, value });
                }
            });
            
            return parsedTags;
        }, [category, tags]);

    return (
        <Box sx={sx.root}>
            {
                error? <AnalysisError/> : 
                <Box>
                    <Box>
                        <Typography color= "#02569D" fontSize= "15px" fontWeight= "600">
                            {
                                Categories?.[category]
                            }
                        </Typography>
                    </Box>
                    {parsedTags.length ? (
                        <Box sx={sx.flexBox}>
                            {parsedTags.map((commTag, index) => (
                                <Tag key={index} tag={commTag} />
                            ))
                            }
                        </Box>
                    ) : (
                        error ? <AnalysisError /> : <NoTags category={category}/>
                    )}
                </Box>
            }
            {
                !error? 
                <Box mt="8px">
                    <Typography sx={sx.subheadings}>
                        <AutoFixHighIcon sx={{ fontSize: "14px" }}/> Tips to improve&nbsp;
                        <span style={{ textTransform: "capitalize" }}>
                            {Categories?.[category]}
                        </span>
                    </Typography>
                    {   Tips?.[category]?.[level]?.map((tip, i) => (
                        <Tip key={i} tip={tip} />
                    ))}
                    
                </Box>
                : null
            }
            
        </Box>
    );
}