import { styled } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    label: {
        background: "#E4E3E8",
        borderRadius: 4,
        cursor: "pointer",
        padding: "0px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 'fit-content',
        transition: "all 200ms",
        "&:hover": {
            background: "#dcdcde",
        }
    }
});

const Input = styled('input')({ display: 'none' });

function ImageUploadButton(props) {
    const classes = useStyles();
    return (
        <label
            htmlFor="icon-button-file"
            className={classes.label}
            title="Upload Logo"
        >
            <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onClick={(e) => e.target.value = null}
                {...props}
            />
            <AddPhotoIcon color="primary" fontSize="small" />
            <Typography variant="caption" ml={1}>
                Replace Logo
            </Typography>
        </label >
    );
}

export default ImageUploadButton;