import Client from "./_client";

export const getInterviewAttempts = async (attemptFilters = {}) => {
  let url = "/interviewAttempt?";

  Object.keys(attemptFilters).forEach((key) => {
    if (attemptFilters[key] !== undefined) url += `${key}=${attemptFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to fetch attempts. Please try again"
    );

  const page = result?.data?.pageNumber;
  const pageSize = result?.data?.pageSize;

  result?.data?.interviewAttempts.forEach((ia, i) => {
    ia.sNo = (((+page) - 1) * (+pageSize)) + (i + 1)
  })

  return result.data || {};
}

export const getInterviewAttemptsForTemplate = async (filters) => {
  let url = "/interviewAttempt?";

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! save the interview attempt. Please try again"
    );
  }

  return result?.data;
};

export const getInterviewAttemptById = async (attemptId) => {
  const url = `/interviewAttempt/${attemptId}`;

  let result = await new Client({
    path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get attempt. Please try again."
    );
  }

  return result.data;
}

export const getTemplateScoreDistribution = async (filters) => {
  let url = `/interviewAttempt/score-distribution?`;

  if (filters.templateId) url += `templateId=${filters.templateId}`
  if (filters.personalizedTemplateDraftId) url += `personalizedTemplateDraftId=${filters.personalizedTemplateDraftId}`

  let result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get score distribution. Please try again."
    );
  }

  return result.data;
}
