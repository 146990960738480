import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";

import DoughnutGraph from "components/Home/DoughnutGraph";
import ShadowBox from "components/ShadowBox";
import { getTemplateScoreDistribution } from "services";
import VerticalProgressBar from "components/BatchAnalytics/VerticalProgressBar";

const sx = {
  rangeBox: {
    display: "flex",
    mt:'15px', 
    justifyContent:'center',
    flexDirection:'column',
    px:'50px',
    gap:'10px'
  },
  avgBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px 12px',
    border: '1px solid #02569D',
    backgroundColor: '#E8F0FD'
  }
}

const AverageScoreLegends = [
  { range: "0-30", color: "#FF2D55" },
  { range: "31-60", color: "#FFCC00" },
  { range: "61-100", color: "#41D7A1" },
];

function TemplateStats({ template, forPersonalizedTemplate }) {
  const { id } = useParams();

  const [scoreDistribution, setScoreDistribution] = React.useState({
    "0-30": 0,
    "31-60": 0,
    "61-100": 0,
    averageScore: 0,
  });

  const [attemptRate, setAttemptRate] = React.useState({
    rate: 0,
    attempted: 0,
    unAttempted: 0,
  });

  React.useEffect(() => {
    const attempted = template?.attemptedBy?.length || 0;
    const total = template?.assignedTo?.length || 0;
    const unAttempted = total - attempted;

    setAttemptRate({
      attempted,
      unAttempted,
      rate: Math.round((attempted / total) * 100),
    });
  }, [template]);

  const totalStudents = React.useMemo(()=>{
    let total = 0;
    AverageScoreLegends.map(data=>
      total += scoreDistribution[data.range]
    )

    return total;
  },[scoreDistribution, AverageScoreLegends])

  React.useEffect(() => {
    getTemplateScoreDistribution(forPersonalizedTemplate ? {personalizedTemplateDraftId: id} : {templateId: id})
      .then((scoreDistribution) => setScoreDistribution(scoreDistribution))
      .catch(console.error);
  }, [id]);

  return (
    <ShadowBox width="65%" display="flex" justifyContent="space-evenly">
      <Box width='50%' display='flex' justifyContent='center' flexDirection='column'>
        <DoughnutGraph
          rootStyle={{ padding: 0 }}
          color="#0263FF"
          score={attemptRate.rate || 0}
          data={[attemptRate.attempted, attemptRate.unAttempted]}
          labels={["No. of students", "No. of students"]}
          title="Attempt Rate"
          muiltiProgress={true}
          isPercentage
          bgColors={['#2DABFF', '#FF476B']}
        />
        <Box sx={sx.rangeBox}>
          <Typography fontSize={15} fontWeight={600} textAlign='center'>
            Attempt rate of Students
          </Typography>
          {[
            {color: '#2DABFF', range: attemptRate.attempted , tag: 'Students taken the test'},
            {color: '#FF476B', range: attemptRate.unAttempted, tag: 'Students not taken the test'}
          ].map((x,index) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent='space-between'
            >
                <Box display="flex" alignItems="center" gap='10px'>
                  <Box width={20} bgcolor={x.color} p={0.5} borderRadius={8} />
                  <Typography fontSize={12} fontWeight={600}>
                    {x.tag}
                  </Typography>
                </Box>
              <Typography fontSize={12}>
                {x.range}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width='50%' display='flex' justifyContent='center'>
        <Box height='60%'>
          <VerticalProgressBar
            averageScoreStats={scoreDistribution || {}}
            averageScoreLegends={AverageScoreLegends || []}
            xAsix={'Score Range'}
            yAsix={'No. of Students'}
            totalStudents={totalStudents}
          />
          <Box display='flex' justifyContent='center' mt='20px'>
            <Box sx={sx.avgBox}>
              <Typography color='#02569D' fontWeight={600} fontSize='16px'>
                {scoreDistribution.averageScore || 0}
              </Typography>
              <Typography fontSize='15px' fontWeight={600}>
                Batch Average
              </Typography>
              <Typography fontSize='12px' fontWeight={500} mt={0}>
                Score consisting of {totalStudents} students
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ShadowBox>
  );
}

export default TemplateStats;
