import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import MUIChip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import ShadowBox from 'components/ShadowBox';
import { formatDate } from 'utils';

function Chip(props) {
  return (
    <MUIChip
      size="small"
      variant="outlined"
      color="primary"
      component="span"
      {...props}
    />
  );
}

const styles = {
  detail: { marginTop: 1, fontWeight: 600, fontSize: 14 },
  label: { fontStyle: "normal", color: "#636363", fontWeight: 400 },
  capitalizeText: { textTransform: 'capitalize' }
};

function Detail({ label, children, capitalize }) {
  return (
    <Typography
      sx={styles.detail}
      style={capitalize ? styles.capitalizeText : {}}
    >
      <span style={styles.label}>{label}:&nbsp;</span>{children}
    </Typography>
  );
}

function TemplateInfo({ template }) {
  const averageTime = template?.metadata?.averageTime || 0

  const mins = Math.floor(averageTime / 60);
  const secs = averageTime % 60;

  let atText = 'NA';

  if (mins) atText = `${mins} min`;

  if (secs) atText = `${mins > 0 ? mins + ' min ' : ''}${secs} secs`

  return (
    <ShadowBox display="flex" alignItems="stretch" gap={2} m={0}>
      <Box flexGrow={1} flexShrink={0}>
        <Typography variant="h6">
          {template?.name}&nbsp;
          <Chip
            label={template?.type === 'personalized' ? 'Mock Interview' : template?.type}
            style={{ textTransform: 'capitalize' }}
          />
        </Typography>
        <Detail label="Id" capitalize>
          {template?._id?.toString()}
        </Detail>
        <Detail label="Total Attempts" capitalize>
          {template?.metadata?.usedBy?.toString() || template?.totalAttemptsCount}
        </Detail>
        {
          template?.difficulty ?
            <Detail label="Difficulty" capitalize>
              {template?.difficulty}
            </Detail>
        : null
        }
        <Detail label="Published On" >
          {formatDate(
            template?.publishOn || template?.createdAt, 'Do MMM, YYYY (dddd)'
          )}
        </Detail>
      </Box>
      <Box flexGrow={1} flexShrink={0}>
        <Detail label="Batch">
          {template?.batch?.name || 'N/A'}
        </Detail>
        <Detail label="Domain">
          {template?.domain?.name}
        </Detail>
        <Detail label="Average Attempt Time">
          {atText}
        </Detail>

        <Detail label="Expires On">
          {
            (dayjs(template?.expiresOn).year() === 9999) ?
              'Never' :
              formatDate(template?.expiresOn, "Do MMM YY, dddd")
          }
        </Detail>
      </Box>
    </ShadowBox>
  );
}

export default TemplateInfo;