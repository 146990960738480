import React from "react";
import { useNavigate } from "react-router-dom";
import { restoreSession, signOut } from "../services/auth";
import Session from "../utils/Session";
import { subMinutes } from "../utils";
import { Box, CircularProgress } from "@mui/material";

function SessionHelper({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);

  const isTokenExpiring = () => {
    console.log("DEBUG::isTokenExpiring");
    return subMinutes(new Date(Session.accessTokenExpiry), 5) <= new Date();
  };
  const isRefreshTokenExpiring = () => {
    return (
      subMinutes(new Date(Session.refreshTokenExpiry), 5).getTime() <=
      new Date().getTime()
    );
  };

  const goToError = async () => {
    console.log("DEBUG::gotToError");
    await signOut();
    navigate("/auth/login");
  };

  async function onLoad() {
    setLoading(true);

    try {
      // if the refresh token is expiring then sign out and redirect to sign in
      const languifyRefreshTokenExpiry = localStorage.getItem(
        "languifyRefreshTokenExpiry"
      );

      if (languifyRefreshTokenExpiry) {
        const refreshTokenExpiryTime = new Date(
          parseInt(languifyRefreshTokenExpiry, 10)
        ).getTime();
        Session.refreshTokenExpiry =
          refreshTokenExpiryTime <= new Date().getTime()
            ? new Date().getTime()
            : refreshTokenExpiryTime;
      }

      if (isRefreshTokenExpiring()) {
        console.log("DEBUG::RefreshTokenExpired");
        if (Session.isLoggedIn()) await goToError();
        return;
      }

      if (Session.isLoggedIn()) {
        console.log("DEBUG::isLoggedIn: ", Session.isLoggedIn());
        if (isTokenExpiring()) {
          console.log("DEBUG::AccessTokenExpired");
          Session.accessToken = "";
        }

        await restoreSession();

        const deactivated = Session.checkDeactivatedStatus();

        if (deactivated) {
          console.log("DEBUG::DEACTIVATED");

          await signOut();

          navigate("/deactivated");
        }

        setLoading(false);
      }
    } catch (e) {
      console.log("DEBUG::onLoad error: ", e);
      goToError();
    } finally {
      setLoading(false);
    }
  }

  async function setupRefresh() {
    try {
      if (isRefreshTokenExpiring()) {
        console.log("DEBUG::RefreshTokenExpired-2");
        await goToError();
      }

      if (Session.isLoggedIn()) {
        if (isTokenExpiring()) Session.refreshToken = "";

        await restoreSession();
      }
    } catch (e) {
      console.error("setupTokenRefresh -> error refreshing token", e);
      await goToError();
    }
  }

  React.useEffect(() => {
    onLoad()
      .then(() => setLoading(false))
      .catch((e) => setLoading(false));

    const timerId = setInterval(setupRefresh, 3600000);

    return () => clearInterval(timerId);
  }, []);

  React.useEffect(() => {
    Session.setPageHistory(window.location.pathname);
  }, [navigate]);

  return loading ? (
    <Box
      width={"100vw"}
      height={"100vh"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress variant="indeterminate" />
    </Box>
  ) : (
    <>{children}</>
  );
}

export default SessionHelper;
