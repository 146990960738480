import React from "react";

import { useNavigate } from "react-router-dom";

import Backdrop from "@mui/material/Backdrop";

import TutorialTip from "components/TutorialTip";
import { disableScroll, enableScroll } from "utils/scroll";

export const TourProviderContext = React.createContext({
  _play: false,
  _setPlay: () => { },
  _steps: [],
  playTour: (steps = []) => { },
  _setSteps: () => { },
});

const skipData = {
  id: "tutorial-button",
  title: "Tutorial",
  message: "In case of any confusion, you can learn about the platform anytime by clicking on this icon.",
  action: "Got it",
  hideClose: true,
};

export const TourSteps = [
  {
    id: "welcome-to-languify-tip",
    title: "Welcome to Languify!",
    message: "We are excited to show you all the new features and improvements we have made to our website.",
    skipText: "No thanks, I want to explore it myself",
    action: "Let’s get started",
    link: "/batches",
    hideClose: true,
    data: {
      templates: [
        {
          name: "B1 - Assessment 4",
          type: "assignment",
          tags: [],
          infos: [],
          communicationAverage: 67,
          contentAverage: 72,
          attemptedBy: ['student1', 'student2'],
          batch: { templateCount: 4, studentCount: 30, name: "Batch 1" }
        },
        {
          name: "B2 - Assessment 3",
          type: "assignment",
          tags: [],
          infos: [],
          batch: { templateCount: 3, studentCount: 35, name: "Batch 2" }
        },
        {
          name: "B1 - Assessment 2",
          type: "practice",
          tags: [],
          infos: [],
          batch: { templateCount: 4, studentCount: 30, name: "Batch 1" }
        },
        {
          name: "B3 - Assessment 5",
          type: "practice",
          tags: [],
          infos: [],
          batch: { templateCount: 5, studentCount: 25, name: "Batch 3" }
        },
        {
          name: "B4 - Assessment 11",
          type: "assignment",
          tags: [],
          infos: [],
          batch: { templateCount: 11, studentCount: 30, name: "Batch 4" }
        },
      ],
    },
  },
  {
    id: "batches-tip",
    title: "Step 1 of 5: Batches Page",
    message: "You can now easily create and edit batches, and view analytics to gain a deeper understanding of your students' performance on a batch level.",
    action: "Got it",
    link: "/assessments",
    data: {
      batches: [
        { templateCount: 4, studentCount: 30, name: "Batch 1", createdOn: '2022-11-24T11:22:51.537+00:00', _id: "batch1", },
        { templateCount: 3, studentCount: 35, name: "Batch 2", createdOn: '2022-12-24T11:22:51.537+00:00', _id: "batch2", },
        { templateCount: 5, studentCount: 25, name: "Batch 3", createdOn: '2022-10-24T11:22:51.537+00:00', _id: "batch3", },
        { templateCount: 11, studentCount: 30, name: "Batch 4", createdOn: '2022-08-23T11:22:51.537+00:00', _id: "batch4", },
      ],
    },
  },
  {
    id: "assessments-tip",
    title: "Step 2 of 5: Assessments Page",
    message: "You can create and edit assessments with ease. Also, view analytics to better understand students' performance for each assessment.",
    action: "Got it",
    link: "/personalizedInterview",
    data: {
      templates: [
        {
          _id: 'b1-a4',
          name: "B1 - Assessment 4",
          type: "assignment",
          tags: [],
          infos: [],
          communicationAverage: 67,
          contentAverage: 72,
          attemptedBy: ['student1', 'student2'],
          batch: { templateCount: 4, studentCount: 30, name: "Batch 1" },
          metadata: { numberOfQuestions: 5, averageTime: 400 },
          domain: { name: "Digital Marketing" },
          createdOn: '2022-11-24T11:22:51.537+00:00',
        },
        {
          _id: 'b2-a3',
          name: "B2 - Assessment 3",
          type: "assignment",
          tags: [],
          infos: [],
          attemptedBy: ['student1', 'student2', 'student3'],
          batch: { templateCount: 3, studentCount: 35, name: "Batch 2" },
          metadata: { numberOfQuestions: 8, averageTime: 930 },
          domain: { name: "Software Development" },
          createdOn: '2022-12-24T11:22:51.537+00:00',
        },
        {
          _id: 'b1-a2',
          name: "B1 - Assessment 2",
          type: "practice",
          tags: [],
          infos: [],
          attemptedBy: ['student1', 'student2', 'student3', 'student4'],
          batch: { templateCount: 4, studentCount: 30, name: "Batch 1" },
          metadata: { numberOfQuestions: 10, averageTime: 1300 },
          domain: { name: "Sales" },
          createdOn: '2022-11-24T11:22:51.537+00:00',
        },
        {
          _id: 'b3-a5',
          name: "B3 - Assessment 5",
          type: "practice",
          tags: [],
          infos: [],
          attemptedBy: ['student1'],
          batch: { templateCount: 5, studentCount: 25, name: "Batch 3" },
          metadata: { numberOfQuestions: 3, averageTime: 260 },
          domain: { name: "Data Analyst" },
          createdOn: '2022-10-24T11:22:51.537+00:00',
        },
        {
          _id: 'b4-a11',
          name: "B4 - Assessment 11",
          type: "assignment",
          tags: [],
          infos: [],
          attemptedBy: ['student1', 'student2'],
          batch: { templateCount: 11, studentCount: 30, name: "Batch 4" },
          metadata: { numberOfQuestions: 6, averageTime: 490 },
          domain: { name: "Product Management" },
          createdOn: '2022-08-23T11:22:51.537+00:00',
        },
      ],
    }
  },
  {
    id: "mock-interview",
    title: "Step 3 of 5: Mock Interview Page",
    message: "Publish company & job specific interviews for your students interview perp & track their performance",
    action: "Got it",
    link: "/students",
    data: {}
  },
  {
    id: "students-tip",
    title: "Step 4 of 5: Students Page",
    message: "View the performance of each individual student and control their status. This will help you to better understand the progress of each student.",
    action: "Got it",
    link: "/settings",
    data: {
      students: [
        {
          _id: '0',
          name: "Pushpendra Upadhyay",
          email: "pushpendra@languify.in",
          metadata: { lastActive: "2023-04-13T21:17:22.713+00:00" },
          deactivated: false,
          profileUrl: '',
        },
        {
          _id: '1',
          name: "Shivam Sahu",
          email: "shivam@languify.in",
          metadata: { lastActive: "2022-04-13T05:00:22.713+00:00" },
          deactivated: true,
          profileUrl: '',
        },
        {
          _id: '2',
          name: "Asif Hashmi",
          email: "asif@languify.in",
          metadata: { lastActive: "2023-01-13T15:45:22.713+00:00" },
          deactivated: false,
          profileUrl: '',
        },
        {
          _id: '3',
          name: "Aman Saini",
          email: "aman@languify.in",
          metadata: { lastActive: "2023-02-13T11:39:22.713+00:00" },
          deactivated: false,
          profileUrl: '',
        },
      ]
    }
  },
  {
    id: "settings-tip",
    title: "Step 5 of 5: Settings Page",
    message: "Explore the settings and customize the platform as per your needs. This will help you to better manage your students and their progress.",
    action: "Finish",
    link: "/home",
  },
];

export const TourProvider = ({ children }) => {
  const navigate = useNavigate();
  const [_play, _setPlay] = React.useState(false);
  const [_active, _setActive] = React.useState(0);
  const [skipped, setSkipped] = React.useState(false);
  const [_steps, _setSteps] = React.useState(TourSteps || []);

  const onHandleNext = () => {
    if (_play) {
      if (_steps[_active + 1]) {
        _setActive(a => {
          const step = _steps[a];

          if (step.link) navigate(step.link);

          return a + 1
        });
      } else {
        if (_steps[_active].link) navigate(_steps[_active].link);
        _setPlay(false);
        _setActive(0);
        setSkipped(true);
      }
    }
    if (skipped) {
      setSkipped(false);
    }
  }

  const onHandleSkip = () => {
    _setPlay(false)
    _setActive(0);
    setSkipped(true);
  }

  const playTour = (_steps = []) => {
    if (Array.isArray(_steps) && _steps?.length > 0) _setSteps(_steps);
    _setPlay(true);
};

  React.useEffect(() => {
    if (_play || skipped) {
      disableScroll();

      return () => enableScroll();
    }
  }, [_play, skipped]);

  return (
    <TourProviderContext.Provider value={{ _play, _setPlay, _setSteps, _setActive, playTour }}>
      <Backdrop
        open={_play || skipped}
        sx={{
          backgroundColor: 'transparent',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        {(_play || skipped) && <TutorialTip
          tip={skipped ? skipData : _steps[_active]}
          onSkip={onHandleSkip}
          onNext={onHandleNext}
        />}
      </Backdrop>
      {children}
    </TourProviderContext.Provider>
  );
};

export const useTour = () => {
  const navigate = useNavigate();

  const {
    _setPlay, _play, _setSteps, _setActive
  } = React.useContext(TourProviderContext);

  const show = React.useCallback(() => {
    _setActive(0);
    navigate("/home");
    _setPlay(true);
  }, [_setPlay, navigate, _setActive]);

  const hide = React.useCallback(() => _setPlay(false), [_setPlay]);

  const setSteps = React.useCallback((steps) => _setSteps(steps), [_setSteps]);

  return { show, hide, setSteps, state: _play };
};

export default TourProvider;