import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoading, useSnackbar, useTour } from 'contexts';
import { getBatches, getTemplates } from "services";
import { formatDate, Session } from "utils";
import dayjs from 'dayjs';
import StudentsIcon from '@mui/icons-material/PeopleOutlineOutlined';
import AssessmentsIcon from '@mui/icons-material/ArticleOutlined';
import CardList from 'components/CardList';
import OverallPerformance from 'components/Home/OverallPerformance';
import QuickHighlight from 'components/Home/QuickHighlight';
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box";

const getTemplateInfos = (data) => [
  <><StudentsIcon style={{ fontSize: 13 }} />&nbsp;{data?.batch?.name || 'NA'}</>,
  <><b>Published:&nbsp;</b> {formatDate(data.publishOn, "Do MMM YY")}
    <b>&nbsp;&nbsp;Expiry:&nbsp;</b> {
      (dayjs(data.expiresOn).year() === 9999) ?
        'Never' :
        formatDate(data.expiresOn, "Do MMM YY")}
  </>,
];

function Home(props) {
  const { show } = useTour();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        loading.show();

        const { batches } = await getBatches({
          client: Session.userId, orderBy: "createdAt", order: "desc",
          pageSize: 5,
        });

        batches.forEach(batch => {
          batch.tags = [];
          batch.onClick = () => navigate("/batches/" + batch._id);
          batch.infos = [
            <><StudentsIcon style={{ fontSize: 13 }} />&nbsp;{batch?.totalStudentCount} Students</>,
            <><AssessmentsIcon style={{ fontSize: 13 }} />&nbsp;{batch?.totalTemplateCount} Assessments</>,
            <><b>Created on</b>: {formatDate(batch?.createdAt, "Do MMM YY")}</>,
          ]
        })
        setBatches(batches);
      } catch (error) {
        console.error(error);
        snackbar.showSnackbar("No batches found", "error");
      } finally {
        loading.hide();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        loading.show();

        const { interviewTemplates } = await getTemplates({
          user: Session.userId, orderBy: "createdAt", order: "desc",
          pageSize: 5, include: "batch"
        });

        const modifiedTemplates = [];
        interviewTemplates.forEach((it) => {
          if (it.type === 'follow_up') return;

          const tags = [];
          if (it.expiresOn && dayjs().isAfter(it.expiresOn)) {
            tags.push({ name: `Expired`, expire: true });
          } else if (it.publishOn && dayjs().isAfter(it.publishOn)) {
            tags.push({ name: "Live", expire: true });
          }

          modifiedTemplates.push({
            onClick: () => navigate("/assessments/" + it._id),
            name: it.name, type: it.type, tags,
            infos: getTemplateInfos(it),
          });
        });

        setTemplates(modifiedTemplates);
      } catch (error) {
        console.error(error);
        snackbar.showSnackbar("No templates found", "error");
      } finally {
        loading.hide();
      }
    })();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("tour-visited")) {
      localStorage.setItem("tour-visited", true);
      show();
    }
  }, []);

  return (
    <>
      <Box marginTop={"5rem"} marginBottom={"0.75rem"}>
        <Typography
          variant="h4" color="primary" fontWeight={"bold"} fontSize='1.8rem' lineHeight='2.3'
        >
          Admin Dashboard
        </Typography>
      </Box>
      {
        batches.length ? <QuickHighlight /> : null
      }
      <OverallPerformance />
      <CardList
        showMore
        items={batches}
        title="Batches"
        newPath={'/batches/create'}
        seeMorePath={`/batches`}
        cardTitle="Click to edit this batch"
      />
      <CardList
        showMore
        items={templates}
        title="Assessments"
        newPath={'/assessments/create'}
        seeMorePath={`/assessments`}
        cardTitle="Click to edit this assessment"
      />
      <br />
    </>
  );
}

export default Home;