import { useCallback } from 'react';
import { useState } from 'react';
import { getDraftForUser, saveDraft } from 'services';

/**
 *
 * @returns A hook to save draft of some data
 */
export const useDraft = ({
    name, draftId, docId, fields = new Set(), onNewDraft = (id) => { }
}) => {
    const [data, setData] = useState(null);

    const load = useCallback(async (_draftId, _docId) => {
        try {
            const documentId = docId || _docId;
            const _id = draftId || _draftId;

            const draft = await getDraftForUser({
                _id,
                name,
                docId: documentId !== "create" ? documentId : undefined,
            });

            if (draft) setData(draft?.data);
        } catch (error) {
            console.error(error);
        }
    }, [name, docId, draftId]);


    const draftSaver = useCallback((values) => async () => {
        try {
            const filteredValues = Object.keys(values).reduce((acc, key) => {
                if (fields.has(key) && values[key] !== undefined) {
                    acc[key] = values[key];
                }
                return acc;
            }, {});

            if (docId && docId !== 'create')
                filteredValues.templateId = docId;

            const { _id } = await saveDraft(draftId, { name, data: filteredValues });

            if (!draftId) onNewDraft(_id);
        } catch (error) {
            console.error(error);
        }
    }, [name, draftId, onNewDraft, docId, fields]);

    return { data, load, draftSaver };
};

export default useDraft;