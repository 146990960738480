import create from "zustand";

const defaults = {
    domain: '',
    autoGenerate: false,
    activeStep: 0,
    stepsCompleted: {},
    draftId: undefined,
    templateId: undefined,
    draftSaved: false,
    filters: {},
    details: {},
    questions: [],
    templatesAdded: [],
    questionsAdded: [],
    pageFilters: null,
    difficulty: '',
    importedQuestions: false,
};

const useEditorStore = create(
    (set) => ({
        ...defaults,
        setDomain: (domain) => set({ domain }),
        setDifficulty: (difficulty) => set({ difficulty }),
        setAutoGenerate: (autoGenerate) => set({ autoGenerate }),
        setTemplateId: (templateId) => set({ templateId }),
        setDraftId: (draftId) => set({ draftId }),
        setActiveStep: (activeStep) => set({ activeStep }),
        nextStep: () => {
            set(state => ({
                stepsCompleted: { ...state.stepsCompleted, [state.activeStep]: true },
                activeStep: Math.min(state.activeStep + 1, 3),
            }));
        },
        resetStepsCompleted: () => set({ stepsCompleted: {} }),
        setStepCompleteStatus: (step, status) => set((state) => ({
            stepsCompleted: { ...state.stepsCompleted, [step]: status }
        })),
        setFilters: (filters) => set({ filters }),
        setDetailss: (details) => set({ details }),
        setQuestions: (questions) => set({ questions }),
        setImportedQuestions: (importedQuestions) => set({ importedQuestions }),
        setTemplatesAdded: (templatesAdded) => set({ templatesAdded }),
        setQuestionsAdded: (questionsAdded) => set({ questionsAdded }),
        setPageFilters: (pageFilters) => set({ pageFilters }),
        setVisitedPageHistory: (visitedPageHistory) => set({ visitedPageHistory }),
        addQuestionFromLibrary: (ques) => {
            let {
                question,
                hint,
                resources,
                expectedAnswer,
                type,
                allottedTime,
                sampleAnswers,
            } = ques;

            if (allottedTime > 10) allottedTime = Math.round(allottedTime / 60);

            if (Array.isArray(resources)) resources = resources.join();

            set((state) => ({
                questions: [
                    ...state.questions,
                    {
                        question,
                        hint,
                        resources,
                        expectedAnswer,
                        sampleAnswer: sampleAnswers?.[0] || '',
                        type,
                        allottedTime,
                        id: ques._id
                    }
                ],
                questionsAdded: [...state.questionsAdded, ques._id],
            }));
        },
        removeQuestion: (byId, key) => set((state) => ({
            questions: state.questions.filter((q, i) => (
                byId ? key !== q?.id : i !== key
            )),
            questionsAdded: state.questionsAdded.filter(q => q !== key),
        })),
        newQuestion: (question) => {
            set((state) => ({ questions: [...state.questions, { type: "technical", allottedTime: 1 }] }));
        },
        addQuestions: async (_questions) => set((state) => {
            const questions = [...state.questions];

            _questions.forEach((q) => {
                let {
                    question,
                    hint,
                    resources,
                    expectedAnswer,
                    type,
                    allottedTime,
                    sampleAnswers,
                } = q;

                if (allottedTime > 10) allottedTime = Math.round(allottedTime / 60);

                if (Array.isArray(resources)) resources = resources.join();

                questions.push({
                    question,
                    hint,
                    resources,
                    expectedAnswer,
                    sampleAnswer: sampleAnswers?.[0] || '',
                    type,
                    allottedTime,
                    id: q._id,
                    templateId: q.interviewTemplate,
                })
            });

            return { questions };
        }),
        removeQuestions: async (templateId) => set((state) => ({
            templatesAdded: state.templatesAdded.filter(tid => tid !== templateId),
            questions: state.questions.filter(q => (
                q?.templateId !== templateId
            )),
        })),
        updateQuestion: async (index, question) => {
            set((state) => ({
                questions: [
                    ...state.questions.slice(0, index),
                    question,
                    ...state.questions.slice(index + 1)
                ]
            }))
        },
        addTemplate: (id) => set((state) => ({ templatesAdded: [...state.templatesAdded, id] })),
        removeTemplate: (id) => set((state) => ({ templatesAdded: state.templatesAdded.filter(x => x !== id) })),
        savedDraft: () => {
            set({ draftSaved: true });
            setTimeout(() => set({ draftSaved: false }), 1500);
        },
        reset: () => set(defaults),
    }),
);

export default useEditorStore;