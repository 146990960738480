import React from 'react';
import { useField } from "formik";
import { makeStyles } from "@mui/styles";
import MUITextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { useSnackbar } from "contexts";
import { copyToClipboard } from "utils";

const useStyles = makeStyles((theme) => ({
  root:(props) =>  ({
    margin: "8px 0",
    borderRadius: "6px",
    fontSize: 12,
    fontWeight: "normal",
    "& .MuiInputLabel-root": {
      fontSize: "14px !important",
      fontWeight: "normal !important",
    },
    "& .MuiFilledInput-root": {
      fontSize: 12,
      fontWeight: "normal",
      borderRadius: "6px",
      backgroundColor: "#F1F2F6",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
      '&>*': {
        padding: 10
      }
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: props?.background || "#F1F2F6",
      borderRadius: "6px",
      fontSize: 14,
      fontWeight: "normal",
      paddingLeft: 10,
      border : props.border,
      '& fieldset': {
        borderRadius: "6px",
        borderColor: "#D9D9D9",
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: 12,
      },
      '&:hover fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",

      },
      '&.Mui-focused fieldset': {
        borderColor: '#02569D',
        borderRadius: "6px",
        fontSize: 12,
        fontWeight: "normal",
      },
    },
  }),
  numericInput: {
    minWidth: 180, width: 400, height: 34, borderRadius: "6px",
    fontWeight: "normal", fontStyle: "normal", border: '1px solid #D9D9D9',
    fontFamily: "Inter", fontSize: 14, backgroundColor: "#F1F2F6",
    paddingLeft: 12,
    '&:hover': { border: '1px solid #02569D' },
    '&:focus': { outline: 'none', border: '2px solid #02569D' }
  }
}));

export function TextField(props) {
  const classes = useStyles(props);

  return (
    <MUITextField
      className={classes.root}
      disabled={props.readOnly}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        minWidth: 180,
        ...(props.sx ? { ...props.sx } : {}),
      }}
      InputLabelProps={{ style: { fontSize: "1rem", fontWeight: 200 } }}
      {...props}
    />
  );
}

export function NumericField(props) {
  const classes = useStyles();

  return (
    <input type='number' className={classes.numericInput}
      placeholder={props.placeholder} min={2}
      {...props}
    />
  );
}

export function FormTextField({ label, ...props }) {
  const classes = useStyles();

  const [field, meta] = useField(props);

  return (
    <MUITextField
      className={classes.root}
      {...props}
      InputProps={{ ...field, ...props.InputProps }}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        ...(props.sx ? { ...props.sx } : {})
      }}
      label={label}
      error={!!(meta.touched && meta.error)}
      helperText={meta.error}
    />
  );
};

export function FormCopyTextField({ InputProps, ...props }) {
  const snackbar = useSnackbar();

  const [field] = useField(props);

  return (
    <FormTextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: (
          < InputAdornment position="end" >
            <IconButton
              title="copy content"
              onClick={() => {
                copyToClipboard(field.value);
                snackbar.showSnackbar(`${field.name} copied to clipboard!`, 'info', true);
              }}
            >
              <ContentCopyOutlinedIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default TextField;