import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import ScreenHeading from 'components/ScreenHeading';
import General from 'components/Settings/General';
import Scoring from 'components/Settings/Scoring';
import Session from "utils/Session";

import { makeStyles } from '@mui/styles';
import Profile from 'components/Settings/Profile';
import Usage from 'components/Settings/Usage';

const useStyle = makeStyles({
  tab: {
    fontFamily: 'Inter',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 14,
    height: 20,
  },
  tabPanel: {
    paddingLeft: 14,
    paddingTop: 0,
  }
});

function SettingTitle({ title = 'Title' }) {

  return (
    <Typography fontFamily='Inter' fontSize={22} fontWeight={600} my={3}>
      {title}
    </Typography>
  )
}

function Settings(props) {
  const user = Session.getUser();
  const classes = useStyle();

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <Box display="flex" flexDirection="column" minHeight='calc(100vh - 56px)' pb={6}>
      <ScreenHeading title="Settings" id="settings-tip">
        <Box>
          <Typography fontWeight="bold">{user.name}</Typography>
          <Typography variant="caption">{user.email}</Typography>
        </Box>
      </ScreenHeading>
      <Paper elevation={0} style={{ padding: '10px' , marginTop:'16px', borderRadius: 8 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange} aria-label="lab API tabs example"
            >
              <Tab label="General" value="1" className={classes.tab} />
              <Tab label="Scoring" value="2" className={classes.tab} />
              {/* <Tab label="Email" value="3" className={classes.tab} /> */}
              <Tab label="Profile" value="4" className={classes.tab} />
              <Tab label="Usage" value="5" className={classes.tab} />
            </TabList>
          </Box>
          <TabPanel value="1" className={classes.tabPanel}>
            <SettingTitle title='Customize the experience for your users' />
            <General />
          </TabPanel>
          <TabPanel value="2" className={classes.tabPanel}>
            <SettingTitle title='Adjust weightage for Marking' />
            <Scoring />
          </TabPanel>
          {/* <TabPanel value="3" className={classes.tabPanel}>
            <SettingTitle title='Edit your Email copy' />
            <CustomizeEmail />
          </TabPanel> */}
          <TabPanel value="4" className={classes.tabPanel}>
            <SettingTitle title='Profile' />
            <Profile />
          </TabPanel>
          <TabPanel value="5" className={classes.tabPanel}>
            <Usage/>
          </TabPanel>
        </TabContext>
      </Paper>
    </Box>
  );
}

export default Settings;