import create from "zustand";

import Session from "utils/Session";
import { getAverageScore } from "utils";

export const defaults = {
    percentile: 80,
    content: 82,
    speech: 65,
    template: {
        name: "Assessment 2",
    },
    questions: [
        {
            type: "background",
            question: "Tell something about yourself.",
            bookmark: null,
            hint: "Speak about your educational background, skills, experience, etc.",
            expectedAnswer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci amet semper est egestas a tincidunt urna. Velit ut vehicula nulla viverra et. Mattis purus eu nibh netus nec. Sodales et quis quis arcu curabitur. Vel, pretium est volutpat enim venenatis, non pharetra. Faucibus ac, proin velit, odio nunc. Dictum leo fermentum, dictum quis.",
            resources: ["https://youtube.com"],
            subjectiveUnfilled: true,
            allotedTime: 180,
            communication: {
                "ratings": {
                    "FLUENCY": 0,
                    "WORD_CHOICE": 0,
                    "DELIVERY": 0,
                    "CONFIDENCE": 0,
                    "OVERALL": 0
                },
                tags: [],
            },
            content: {
                ratings: {
                    "SIMILARITY": 0,
                    "RELEVANCE": 0,
                    "LOGIC_FLOW": 0,
                    "OVERALL": 0
                },
                tags: [],
            }
        },
    ],
    currentQuestion: 0,
    templateId: null,
    templateAttempts: 0,
    attemptNumber: 0,
    attemptId: null,
    demoApplication: null,
    allAttempts: []
};

const useStore = create(
    (set, get) => ({
        ...defaults,
        setTemplate: (template) => set({ template }),
        setQuestions: (questions) => set({ questions }),
        setCurrentQuestion: (currentQuestion) => set({ currentQuestion }),
        setTemplateId: (templateId) => set({ templateId }),
        setAttemptId: (attemptId) => set({ attemptId }),
        setAttemptNumber: (attemptNumber) => set({ attemptNumber }),
        setAllAttempts: (allAttempts) => set({ allAttempts }),
        setDemoApplication: (demoApplication) => set({ demoApplication }),
        next: () => set(state => ({
            currentQuestion: Math.min(state.currentQuestion + 1, state.questions.length - 1),
        })),
        prev: () => set(state => ({
            currentQuestion: Math.max(state.currentQuestion - 1, 0),
        })),
        init: ({
            percentile = 80,
            template,
            questions,
            content = 82,
            speech = 65,
            templateAttempts = 0
        }) => {
            const score = getAverageScore(speech, content);

            set({
                percentile,
                template,
                questions,
                templateAttempts,
                templateId: template._id,
                content,
                speech,
                score,
            })
        },
        reset: () => set(defaults),
    }),
);

export default useStore;