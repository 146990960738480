
import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import ArrowBack from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';

import CustomButton from './CustomButton';

const useStyles = makeStyles({
  button: {
    height: 56,
    margin: '0',
    border: 'none',
    borderRadius: '0px',
    width: 100,
  }
});

function BackButton(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/home";

  return isHomePage ? null : (
    <>
      <CustomButton
        variant="text"
        size="small"
        className={classes.button}
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
      >
        Back
      </CustomButton> &nbsp;&nbsp;
    </>
  )
}

export default BackButton;