import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

import Sidebar from "./Sidebar";

const useStyles = makeStyles({
    contentRoot: {
        flex: "auto",
        marginLeft: "0px",
        marginTop: "0",
        width: "calc(100vw - 242px)",
        padding: "0px 0px 32px 242px",
    },
});

export default function AppShell({ children }) {
    const classes = useStyles();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                height: "calc(100vh - 0px)",
                marginTop: "0px",
            }}
        >
            <Sidebar />
            <Box className={classes.contentRoot} >
                <div style={{ padding: "0px 16px" }}>
                    {children}
                </div>
            </Box>
        </Box>
    );
}