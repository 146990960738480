import React, { useMemo } from 'react';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import shallow from 'zustand/shallow';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import RightArrowIcon from '@mui/icons-material/ArrowForward';
import CustomButton from 'components/CustomButton';
import QuestionForm from './QuestionForm';
import useEditorStore from './editorStore';

import { ConfirmationDialog } from 'dialogs';
import { QuestionTypes } from './constants';
import { useLoading, useSnackbar } from 'contexts';
import { useDraft } from 'hooks';

import "./styles.css";
import { generateSituationalAnswer, generateTechnicalAnswer } from 'services/contentAPI';
import { Typography } from '@mui/material';

const ValidationSchema = yup.object().shape({
    questions: yup.array().of(
        yup.object().shape({
            question: yup.string().required("Question is required"),
            expectedAnswer: yup.string().required("Answer is required"),
            allottedTime: yup
                .number()
                .min(1, "Should be greater than 0")
                .max(5, "Should be less than 5 mins")
                .required("Must be greater than 1 and less than 5 mins"),
            resources: yup.string(),
            type: yup
                .string()
                .oneOf(QuestionTypes.map(type => type.value))
                .required("Question type is required"),
            sampleAnswer: yup.string().optional(),
        }).required(),
    ).min(1, "Add questions to save").required(),
});

const grid = 8;

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "whitesmoke" : "transparent",
    padding: isDraggingOver ? 2 : 0,
});

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 4,
    borderRadius: "8px",
    margin: isDragging ? `0 0 ${grid}px 0` : 0,
    background: isDragging ? "#a7bfd3" : "white",
    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

function Questions(props) {
    const snackbar = useSnackbar();
    const loading = useLoading();

    const docId = useEditorStore(state => state.templateId);
    const draftId = useEditorStore(state => state.draftId);
    const setDraftId = useEditorStore(state => state.setDraftId);
    const setQuestions = useEditorStore(state => state.setQuestions);
    const questions = useEditorStore(state => state.questions);
    const nextSection = useEditorStore(state => state.nextStep, shallow);
    const savedDraft = useEditorStore(state => state.savedDraft);
    const domain = useEditorStore(state => state.domain);
    const setDomain = useEditorStore(state => state.setDomain);
    const difficulty = useEditorStore(state => state.difficulty);
    const handleAdd = useEditorStore(state => state.newQuestion);
    const setTemplatesAdded = useEditorStore(state => state.setTemplatesAdded);
    const setQuestionsAdded = useEditorStore(state => state.setQuestionsAdded);
    const setAutoGenerate = useEditorStore(state => state.setAutoGenerate);

    const [confirmDeleteAll, setConfirmDeleteAll] = React.useState(false);
    const [confirmGenerateAll, setConfirmGenerateAll] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [totalTime, setTotalTime] = React.useState(0);

    React.useEffect(() => {
        let time = 0;
        questions.forEach((question) => {
            time += parseInt(question.allottedTime);
        });

        setTotalTime(time);
    }, [questions]);

    const { load, draftSaver, data } = useDraft({
        docId,
        draftId,
        onNewDraft: setDraftId,
        name: "TEMPLATE_QUESTIONS",
        fields: new Set(["questions"]),
    });

    React.useEffect(() => load(draftId, docId), [docId, draftId]);

    React.useEffect(() => {
        data?.questions?.forEach((q) => {
            const { resources, allottedTime } = q;
            if (Array.isArray(resources))
                q.resources = resources?.join() || "";

            if (allottedTime > 10) q.allottedTime = Math.round(allottedTime / 60);

            q.sampleAnswer = q.sampleAnswers?.[0] || '';
        })
        if (data && Object.keys(data).length !== 0) {
            setQuestions(data?.questions || []);
            setDomain(data?.domain || '');
        };
    }, [data]);

    const autoGenerateAll = React.useCallback(async () => {
        loading.show();
        setConfirmGenerateAll(false);
        setAutoGenerate(true);

        const newQuestions = [];

        for (let i = 0; i < questions.length; i++) {
            const q = questions[i];

            const { question, type, expectedAnswer, sampleAnswer } = q;

            if (question && question.length > 10) {
                const service = type === 'situational' ?
                    generateSituationalAnswer :
                    generateTechnicalAnswer;

                if (!expectedAnswer || expectedAnswer?.length === 0) {
                    try {
                        const [text] = await service({
                            domain, question, difficulty
                        });

                        q['expectedAnswer'] = text?.trim();
                    } catch (error) {
                        console.error(error);
                    }
                }

                if (
                    type === 'situational' &&
                    (!sampleAnswer || sampleAnswer?.length === 0)
                ) {
                    try {
                        const { choices: [{ text }] } = await service({
                            domain, question
                        });

                        q['sampleAnswer'] = text?.trim();
                    } catch (error) {
                        console.error(error);
                    }
                }

                newQuestions.push({ ...q });
            }
        }

        setQuestions(newQuestions);

        loading.hide();
    }, [questions]);

    const handleDragEnd = React.useCallback((result) => {
        if (!result.destination) return;

        const reorderedQuestions = reorder(
            questions,
            result.source.index,
            result.destination.index
        );

        setQuestions(reorderedQuestions)
    }, [questions]);

    const deleteAllQuestions = React.useCallback(() => {
        setQuestions([]);
        setTemplatesAdded([]);
        setQuestionsAdded([]);
        setConfirmDeleteAll(false);
        setErrors({});
    }, []);

    const handleSubmit = async () => {
        try {
            loading.show();
            setErrors({});

            const values = ValidationSchema.validateSync(
                { questions }, { abortEarly: false }
            );

            if (!docId) {
                await draftSaver(values)();
                savedDraft();
            }

            nextSection();
        } catch (error) {
            console.log('DEBUG', error.inner);
            const _errors = {};
            error.inner.forEach(({ message, path }) => {
                const splitedPath = path.split(/\[|\]|\./);
                _errors[splitedPath[1]] = {
                    ..._errors[splitedPath[1]],
                    [splitedPath[3]]: message
                };
            });
            setErrors(_errors);
            console.log('DEBUG', _errors);
        } finally {
            loading.hide();
        }
    }


    return (
        <div style={{ display: props.show ? "block" : "none" }}>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {questions.map((q, index) => (
                                <Draggable
                                    key={index}
                                    draggableId={index.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <QuestionForm
                                                key={index}
                                                index={index}
                                                readOnly={false}
                                                errors={errors}
                                                editMode={props?.editMode}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Box display='flex' justifyContent='flex-end'>
                {
                    questions.length ?
                        totalTime > 30 ?
                            <Typography style={{ color: '#f55446', fontSize: '14px' }}>
                                Adjust question time or reduce the number of questions to stay within a 30-minute timeframe
                            </Typography>
                            :
                            <Typography fontSize='14px'>
                                Total Time for this Assessment:
                                <span style={{ fontWeight: 'bold' }}> {totalTime} mins </span>
                            </Typography>
                        : null
                }
            </Box>
            <Box display="flex" alignItems="center" gap={1} mt={1}>
                <div style={{
                    borderTop: "2px dashed #02569D", flexGrow: 1,
                    borderColor: questions.length >= 15 && 'gray'
                }}
                />
                <IconButton
                    color="primary"
                    sx={{ border: "3px dashed #02569D" }}
                    style={{ borderColor: questions.length >= 15 && 'gray' }}
                    onClick={handleAdd}
                    disabled={questions.length >= 15}
                >
                    <AddIcon />
                </IconButton>
                <div style={{
                    borderTop: "2px dashed #02569D", flexGrow: 1,
                    borderColor: questions.length >= 15 && 'gray'
                }}
                />
            </Box>
            <Box display="flex" my={2} justifyContent="space-between">
                <CustomButton
                    onClick={() => setConfirmGenerateAll(true)}
                    variant="outlined"
                    disabled={questions.length === 0}
                >
                    Auto-Generate All Answers
                </CustomButton>
                <div>
                    <CustomButton
                        endIcon={<DeleteIcon />}
                        onClick={() => setConfirmDeleteAll(true)}
                        variant="outlined"
                        color="error"
                        disabled={questions.length === 0}
                    >
                        Delete All
                    </CustomButton>
                    <CustomButton
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={questions.length === 0 || totalTime > 30}
                        endIcon={<RightArrowIcon />}
                    >
                        Proceed
                    </CustomButton>
                </div>
            </Box>
            <ConfirmationDialog
                open={confirmDeleteAll}
                onPrimaryClick={() => setConfirmDeleteAll(false)}
                onSecondaryClick={deleteAllQuestions}
                title='Delete All Questions'
                primaryAction='Cancel'
                secondaryAction='Delete All'
                secondaryActionVariant="error"
                message={(
                    <p style={{
                        textAlign: "center", fontWeight: 500, color: "#747575"
                    }}>
                        Are you sure you want to delete all questions?<br />
                        This action cannot be undone and all data will be lost permanently.
                    </p>
                )}
            />
            <ConfirmationDialog
                open={confirmGenerateAll}
                onPrimaryClick={autoGenerateAll}
                onSecondaryClick={() => setConfirmGenerateAll(false)}
                title='Auto-Generate All Answers'
                primaryAction='Auto-Generate All'
                secondaryAction='Cancel'
                message={(
                    <p style={{
                        textAlign: "center", fontWeight: 500, color: "#747575"
                    }}>
                        This will generate answers for all the questions for
                        which no answer has been entered.
                    </p>
                )}
            />
        </div>
    );
}

export default Questions;