import React from 'react';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useStore from './store';
import { Button, Divider, Link } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const sx = {
    item: {
        height: 40,
        minWidth: 40,
        maxWidth: 40,
        mx: 1,
        borderRadius:'0px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.1em",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    },
    itemText: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "1.1em",
    },
    button: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.3)",
        border: 'none',
        cursor: "pointer",
    },
    navigationBtn: {
        display: "flex",
        gap: '5px',
        alignItems: "center",
        fontSize: "14px",
        textTransform: "Capitalize",
        fontWeight: "600",
        cursor: 'pointer',
    }
};

function Item({ active, index, disabled, error, onClick }) {
    return (
        <Button
            disableElevation
            color={error ? "error" : "primary"}
            variant={active ? "contained" : "outlined"}
            sx={sx.item}
            onClick={onClick}
            style={!active? {backgroundColor: "white"} : {}}
        >
            Q{index + 1}
        </Button>
    );
}

function TopNavigation(props) {
    const next = useStore(state => state.next, shallow);
    const prev = useStore(state => state.prev, shallow);
    const questions = useStore(state => state.questions, shallow);
    const [currentQuestion, setCurrentQuestion] = useStore(state =>
        [state.currentQuestion, state.setCurrentQuestion]
        , shallow
    );
    const [searchParams] = useSearchParams();
    const viewQuestion = searchParams.get('question') || 0;
    React.useEffect(()=>{
        questions && questions.forEach((data,index)=>{
            if(viewQuestion === data._id){
                setCurrentQuestion(index);
            }
        })
    },[viewQuestion])

    return (
        <Box
            width="fit-content"
            borderRadius={3}
            mb={2}
            display="flex"
            flexDirection="column"
            gap='10px'
        >
            <Typography
                fontSize='16px'
                component='div'
                fontWeight='600'
            >
                Question-wise Feedback
            </Typography>
            <Box display='flex'>
                {questions.map((x, index) => (
                    <Item
                        key={index}
                        index={index}
                        onClick={() => setCurrentQuestion(index)}
                        active={(index) === currentQuestion}
                        error={questions[index].isError}
                    />
                ))}
            </Box>
            {
                !props.demo?
                <Box display='flex' gap={1} ml={1}>
                    <Link
                        onClick={prev}
                        sx={sx.navigationBtn}
                    >
                        <ArrowBackIosIcon style={{fontSize:'12px'}}/> Previous
                    </Link>
                    <Divider orientation="vertical" flexItem sx={{ width: 2 }}/>
                    <Link
                        onClick={next}
                        sx={sx.navigationBtn}
                    >
                        Next <ArrowForwardIosIcon style={{fontSize:'12px'}}/>
                    </Link>
                    
                    <Typography fontSize='12px'>
                        {currentQuestion+1} out of {questions.length} questions
                    </Typography>
                </Box>
                :""
            }
        </Box>
    );
}

export default TopNavigation;