import React from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CustomButton from 'components/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import StaticTable from 'components/Tables/StaticTable';

import { useLoading, useSnackbar } from 'contexts';
import { resendInviteLink } from 'services';
import { Session } from 'utils';
import { IconButton } from '@mui/material';

export const ResendMail = ({
  setResendMail, resendMail, failedEmailData, inviteUsersData, onClose
}) => {
  const loading = useLoading();
  const snackbar = useSnackbar();
  const [error, setError] = React.useState(null);

  const [modifiedfailedData, setModifiedFailedData] = React.useState(failedEmailData.map((userData, index) => {
    const user = { ...userData, 's.no': index + 1 }; // Add S.No with sequential numbering
    return user;
  }))

  React.useEffect(() => {
    setModifiedFailedData(failedEmailData.map((userData, index) => {
      const user = { ...userData, 's.no': index + 1 }; // Add S.No with sequential numbering
      return user;
    }))
  }, [failedEmailData])

  const sendInvites = async () => {
    loading.show();
    try {
      const modifiedUsers = failedEmailData.map((userData, index) => {
        const user = { ...userData };
        if (user.reason) {
          delete user.reason;
        }
        return user;
      });

      const { unsuccessful } = await resendInviteLink(Session.userId, modifiedUsers);

      if (unsuccessful.length) {
        setError({ unsuccessful });
        snackbar.showSnackbar(
          `${unsuccessful.length} Invitations not sent!`, "info"
        );
      } else {
        setResendMail(false);
        snackbar.showSnackbar("Invitations sent successfully!!", "success");
      }
    } catch (error) {
      console.error("DEBUG::sendMail", error);
      snackbar.showSnackbar(error.inner[0]?.errors[0] || error.message, "error");
    } finally {
      loading.hide();
    }
  };

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") onClose(reason);
  }

  const cols = [
    { id: "S.No", label: "S.No", field: "s.no", align: "left" },
    { id: 'name', label: 'Name', field: 'name', align: "center" },
    { id: 'Emailid', field: 'email', label: 'Email Id', minWidth: 100, align: 'center' }
  ]

  return (
    <>
      <Dialog
        open={resendMail}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown={true}
        onClose={handleClose}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="responsive-dialog-title"
          bgcolor={"#FFF6E5"}
        >
          <Box
            display='flex'
            alignItems='center'
            gap={"0.25rem"}
            color={"#664100"}
            fontSize={"16px"}
            padding={"0.3rem"}
          >
            <WarningAmberRoundedIcon sx={{ fontSize: '17px', fontWeight: 'medium' }} />
            <Typography>
              Due to unknown reasons, mail was not sent to {failedEmailData.length} out of {inviteUsersData.length} students.
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent style={{ margin: "1rem 0 0.5rem" }}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography
              color="#3C434D"
              fontSize={"19px"}
              fontWeight={500}
              padding={"0.15rem"}
            >
              Resend Mail
            </Typography>
          </Box>
          <StaticTable
            height="300px"
            columns={cols}
            rows={modifiedfailedData}
            showColumnSelection={false}
            showExports={false}
          />
        </DialogContent>
        <DialogActions style={{ padding: '20px 24px' }}>
          <CustomButton
            variant='contained'
            color={'primary'}
            width='134px'
            onClick={sendInvites}
          >
            Resend Mail
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
