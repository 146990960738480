import React from 'react';
import { Outlet, Route, Routes } from "react-router-dom";
import AppShell from 'components/AppShell';
import HomeScreen from 'screens/Home';

function Home(props) {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <HomeScreen />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default Home;

function HomeLayout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}