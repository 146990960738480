import React from 'react';

import CopiedIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';

import CustomButton from 'components/CustomButton';
import DynamicTable from 'components/DynamicTable';
import ScreenHeading from 'components/ScreenHeading';
import { deleteBatchById, generateJoiningLink, getBatches } from 'services';
import { copyToClipboard, applyFiltersByHistory, Session } from 'utils';
import { TourSteps, useTour } from 'contexts';
import useEditorStore from 'editors/TemplateEditor/editorStore';

function JoiningLink({ id, joiningLink }) {
  const [link, setLink] = React.useState(joiningLink);
  const [copied, setCopied] = React.useState(false);
  const [generating, setGenerating] = React.useState(false);

  React.useEffect(() => {
    setLink(joiningLink);
  }, [joiningLink]);

  const handleCopy = () => {
    if (copied) return;

    copyToClipboard(link);

    setCopied(true);

    setTimeout(() => setCopied(false), 1000);
  };

  const handleGenerate = async () => {
    setGenerating(true);

    try {
      const { joiningLink } = await generateJoiningLink(id);

      setLink(joiningLink);
    } catch (error) {
      console.error(error);
    } finally {
      setGenerating(false);
    }
  };

  return !!link ? (
    <IconButton onClick={handleCopy} title='Copy Invite URL'>
      {
        copied
          ? <CopiedIcon color='success' style={{ width: '14px', height: '14px' }} />
          : <CopyIcon style={{ width: '14px', height: '14px' }} />}
    </IconButton>
  ) : (
    <IconButton
      onClick={handleGenerate}
      disabled={generating}
      title='Generate Invite URL'
    >
      {generating ?
        <CircularProgress
          color='inherit'
          variant="indeterminate"
          style={{ height: '5px', width: '5px' }}
        /> : <LinkIcon style={{ height: '16px', width: '16px' }} />}
    </IconButton>
  );
}

const columns = [
  { id: 'name', label: 'Name' },
  {
    id: 'studentCount', label: 'Students', type: 'number', align: 'center',
    renderCell: ({ row }) => row?.totalStudentCount || 0,
  },
  {
    id: 'templateCount', label: 'Assessments', type: 'number', align: 'center',
    renderCell: ({ row }) => row?.totalTemplateCount || 0,
  },
  {
    id: 'createdAt',
    label: 'Created On', align: 'center', type: 'date',
    renderCell: ({ row }) => dayjs(row.createdAt).format('DD-MMM-YYYY, hh:mma')
  },
];

const filters = [
  { field: 'studentCount', type: 'number', label: 'Students' },
  { field: 'templateCount', type: 'number', label: 'Assessments' },
];

function Batches(props) {
  const navigate = useNavigate();
  const { state: tourState } = useTour();
  const pageFilters = useEditorStore((state) => state.pageFilters);
  const setPageFilters = useEditorStore((state) => state.setPageFilters);
  const pageHistory = Session.pageHistory;
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [initialFilters, setInitialFilters] = React.useState({
    client: Session.userId, includeTotal: true
  });

  React.useEffect(() => {
    applyFiltersByHistory(pageFilters, setPageFilters, setInitialFilters);
  }, [pageHistory])


  return (
    <Box display="flex" flexDirection="column" minHeight='calc(100vh - 76px)'>
      <ScreenHeading title="Batches" id="batches-tip">
        <Link to="create" style={{ textDecoration: 'none' }}>
          <CustomButton variant="contained">Create Batch</CustomButton>
        </Link>
      </ScreenHeading>

      <Paper elevation={0} style={{ paddingLeft:'20px' , paddingRight:'20px' , marginTop:'16px' }}>
        <DynamicTable
          showDummyRows={tourState}
          dummyRows={TourSteps[1].data.batches}
          hideDeleteAction
          entity="Batch"
          noRowsMessage="No Batch Found"
          columns={columns}
          filters={filters}
          useListProps={{
            getItems: getBatches,
            deleteItemById: deleteBatchById,
            itemName: 'batches',
            initialFilters: initialFilters
          }}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          actions={[{
            label: "View Performance",
            renderIcon:(param)=> <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 22 22" fill="none">
              <rect y="14" width="6" height="8" rx="1" fill="#025093" />
              <rect x="8" y="8" width="6" height="14" rx="1" fill="#5856D6" />
              <rect x="16" width="6" height="22" rx="1" fill="#007AFF" />
            </svg>,
            onClick: (param) => navigate(`${param.id}/performance`)
          },]}
        />
      </Paper>
    </Box>
  );
}

export default Batches;