import { Form, Formik } from "formik";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { FormTextField } from "components/TextField";
import CustomButton from "components/CustomButton";

const useStyles = makeStyles((theme) => ({
  formRoot: { padding: 8, border: "1px solid #e9e9e9" }
}));

const TextFieldTypes = ["text", "email", "number"];

function FormikForm({
  initialValues = {},
  fields = [],
  formOptions = {},
  validationSchema = null,
  onSubmit = () => { },
  onCancel = () => { },
  primaryLabel = "Save",
  secondaryLabel = "Cancel",
}) {
  const classes = useStyles();
  return (
    <Box className={classes.formRoot}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={1} p={1}>
              {fields.map((field, index) => (
                <Grid key={index} item xs={field.xs || 6} md={field.md || 4}>
                  {TextFieldTypes.includes(field.type) && (
                    <FormTextField
                      {...field}
                      InputProps={{ readOnly: !!field.readOnly }}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <Box
              m={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              <CustomButton
                type="submit"
                variant="contained"
                size={'small'}
              >
                {primaryLabel}
              </CustomButton>
              <CustomButton
                size={'small'}
                variant="outlined"
                onClick={onCancel}
              >
                {secondaryLabel}
              </CustomButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default FormikForm;