import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from '@mui/material/Typography'
import RightArrowIcon from "@mui/icons-material/ArrowForward";
import AutoComplete from 'components/Autocomplete'
import makeStyles from "@mui/styles/makeStyles"
import DebouncedTextField from 'components/DebouncedTextField'
import Select from "components/Select"
import ShadowBox from 'components/ShadowBox'
import StaticTable from 'components/Tables/StaticTable'
import AllowedAttempts from './AllowedAttempts'
import DatePickerField from "./DatePickerField"
import ProctorSettings from './ProctorSettings'
import dayjs from 'dayjs'
import * as Yup from "yup"
import advancedFormat from "dayjs/plugin/advancedFormat"

import React, { useEffect, useState } from 'react'
import { CustomLoadingButton } from 'components/CustomButton'
import { useSnackbar } from "contexts"
import { useNavigate, useParams } from "react-router-dom"
import { getBatches, getDomains, getFollowUpStudents, getMostUsedDomain, getTemplates } from 'services'
import { Session, getAverageScore, round } from 'utils'
import { useFormik } from "formik";
import { createFollowUp } from "services"
dayjs.extend(advancedFormat);

const ActionStyle = { marginRight: 8 };
const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: "column",
        rowGap: "1rem",
        height: 'fit-content',
    },
    header: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    dropdown: {
        display: "flex",
        flexDirection: "row",
        columnGap: 10,
        alignItems: 'center',
        fontWeight: "700"
    },
    contentContainer: {
        display: "flex",
        flexDirection: 'column',
        rowGap: "1rem",
    },
    contentHeader: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    contentHeaderSubTitle: {
        color: "#747575",
        fontWeight: "500"
    },
    contentBody: {
        height: "200px",
        width: "500px"
    },
    contentFooter: {
        fontWeight: "500",
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    formControlClass: {
        display: "flex",
        flexDirection: "row",
    }
}))

function DomainField({
    value = "", onChange, error, helperText, options = [], readOnly
}) {
    const handleChange = (e, option) => onChange({
        target: { name: "domain", value: option.id }
    });

    return (
        <AutoComplete
            readOnly={readOnly}
            options={options.map(o => ({ id: o._id, option: o.name }))}
            value={value}
            onChange={handleChange}
            defaultValue={value}
            size={300}
        />
    );
}

const ValidationSchema = Yup.object({
    publishOn: Yup.date().required(),
    expiresOn: Yup.date().required(),
    maxAllowedAttempts: Yup.number()
        .when("type", {
            is: (type) => type === "practice",
            then: () => Yup.number().max(10, "Cannot be more than 10"),
            otherwise: () => Yup.number(),
        })
        .required("Required")
        .notOneOf([0], "Cannot be zero"),
    proctorSettings: Yup.object({
        copyPasteProctoring: Yup.bool().optional().default(true),
        generalProctoring: Yup.bool().optional().default(false),
        imageCaptureProctoring: Yup.bool().optional().default(false),
    }).optional(),
});

const DefaultValues = {
    publishOn: "",
    expiresOn: "",
    maxAllowedAttempts: -1,
    proctorSettings: {
        copyPasteProctoring: true,
        generalProctoring: true,
        imageCaptureProctoring: false,
    },
};

const title = `Follow up (${dayjs().format('DD/MM/YY, h:m A')})`;

const columns = [
    { field: "name", label: "Assessment Name", type: "string", minWidth: 120, flex: 1, fixed: 1, sort: true, sortOrder: true },
    {
        field: "metadata.numberOfQuestions",
        type: "number",
        label: "Questions",
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (row["metadata"]["numberOfQuestions"] || 0),
    },
    {
        field: "publishOn",
        label: "Date of Addition", type: 'date',
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (dayjs(row[field]).format('DD-MM-YYYY')),
    },
    {
        field: "expiresOn",
        label: "Date of Expiry",
        type: 'date',
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (dayjs(row[field]).year() === 9999 ?
            'Never' :
            dayjs(row[field]).format('DD-MM-YYYY')),
    },
    {
        field: "averageScore",
        label: "Average Score",
        align: 'center',
        renderCell: ({ row, field }) => (row['averageScore'] ?? 0),
        minWidth: 120,
    },
    {
        field: "attemptRate",
        label: "Attempt Rate",
        renderCell: ({ row, field }) => (row['attemptRate'] ?? 0) + '%',
        align: "center",
        type: "number",
        minWidth: 120,
    },
];

const studentColumn = [
    {
        field: "name",
        label: "Name",
        type: "string",
        minWidth: 120,
        flex: 1,
        fixed: 1,
        sort: true,
        renderCell: ({ row, field }) => (row["studentDetails"]?.[field] || ""),
    },
    {
        field: "email",
        label: "Email",
        minWidth: 120,
        align: 'center',
        renderCell: ({ row, field }) => (row["studentDetails"]?.[field] || ""),
    },
    {
        field: "questionAttempted",
        label: "Attempted Ques.",
        type: 'number',
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (row[field]?.length || 0),
    },
    {
        field: "average",
        label: "Overall Score",
        type: 'number',
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (row['average'] ?? 0),
    },
    {
        field: "studentDetails.average.communication",
        label: "Content Avg",
        type: 'number',
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (round(row["studentDetails"]?.["average"]?.["communication"] || 0)),
    },
    {
        field: "studentDetails.average.content",
        label: "Speech Avg.",
        type: 'number',
        minWidth: 80,
        align: 'center',
        renderCell: ({ row, field }) => (round(row["studentDetails"]?.["average"]?.["content"] || 0)),
    },

]

function FollowUpEditor() {

    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const { id: batchId } = useParams();
    const {
        container,
        header,
        dropdown,
        contentContainer,
        contentHeader,
        contentHeaderSubTitle,
        contentFooter,
    } = useStyles();

    const [batches, setBatches] = useState([]);
    const [domain, setDomain] = useState('');
    const [domains, setDomains] = useState();
    const [rows, setRows] = useState([]);
    const [studentRows, setStudentRows] = useState([]);
    const [selectedAssessments, setSelectedAssesment] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [assessmentSearch, setAssessmentSearch] = useState('');
    const [studentSearch, setStudentSearch] = useState('');
    const [templateLoading, setTemplateLoading] = useState(false);
    const [studentLoading, setStudentLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        values,
        handleChange,
        setFieldValue,
    } = useFormik({
        initialValues: DefaultValues,
        validationSchema: ValidationSchema,
        onSubmit: async () => {
            try {
                setLoading(true);
                if (selectedAssessments.length === 0 || selectedStudents.length === 0) {
                    throw Error("Please choose atleast one assessment and corresponding student");
                }

                await createFollowUp({
                    "name": title,
                    "user": Session.userId,
                    "domain": domain,
                    "type": "follow_up",
                    "batch": batchId,
                    "assignedTo": selectedStudents,
                    "templatesForFollowup": selectedAssessments,
                    "proctorSettings": values.proctorSettings,
                    "maxAllowedAttempts": values.maxAllowedAttempts,
                    "publishOn": values.publishOn,
                    "expiresOn": values.expiresOn
                });

                navigate(`/batches`);
                snackbar.showSnackbar("Follow up assessment created succesfully!!");
            } catch (error) {
                snackbar.showSnackbar(
                    error.message || "Uh Oh! Unable to save your changes.",
                    "error"
                );
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        getBatches({ client: Session.userId })
            .then(({ batches }) => setBatches(batches))
            .catch((error) => {
                console.error(error);
                snackbar.error('Unable to get batches, Try again later.');
            })
    }, []);

    useEffect(() => {
        getDomains()
            .then(({ interviewDomains }) => setDomains(interviewDomains))
            .catch((error) => {
                console.error(error);
                snackbar.error('Unable to get domains, Try again later.');
            });
    }, []);

    useEffect(() => {
        if (!domain) return;

        setTemplateLoading(true);
        getTemplates({ user: Session.userId, domain: domain, batch: batchId })
            .then(({ interviewTemplates }) => {

                const filteredTemplates = interviewTemplates.flatMap((tem) => {
                    if (tem.type !== 'follow_up') {
                        tem['averageScore'] = getAverageScore(
                            tem?.communicationAverage ?? 0,
                            tem?.contentAverage ?? 0
                        );

                        tem['attemptRate'] = round(
                            (tem?.attemptedBy?.length / tem?.assignedTo.length)
                            * 100
                        );
                        return tem;
                    }
                    return [];
                });

                setRows(filteredTemplates);
                setSelectedAssesment(filteredTemplates.map((t) => t._id));
            })
            .catch((error) => {
                console.error(error);
                snackbar.error('Unable to get assessments, Try again later.');
            }).finally(() => setTemplateLoading(false))
    }, [domain]);

    useEffect(() => {
        setStudentLoading(true);
        getFollowUpStudents(selectedAssessments)
            .then((students) => {
                const _students = students.map((s) => {
                    s['average'] = getAverageScore(
                        s.studentDetails?.average?.communication ?? 0,
                        s.studentDetails?.average?.content ?? 0);
                    return s;
                });
                setStudentRows(_students);
                setSelectedStudents(_students.map(({ _id }) => _id));
            })
            .catch((error) => {
                console.error(error);
                snackbar.error('Unable to get students, Try again later.');
            }).finally(() => setStudentLoading(false));

    }, [selectedAssessments]);

    useEffect(() => {
        setLoading(true);
        getMostUsedDomain(batchId)
            .then((d) => setDomain(d))
            .catch((error) => {
                console.error(error);
            }).finally(() => setLoading(false));

    }, [batchId]);

    const handleDomainChange = (e) => setDomain(e.target.value);

    const handleDateChange = React.useCallback(
        (name, date) => setFieldValue(name, date),
        [setFieldValue]
    );

    return (
        <form>
            <ShadowBox className={container}>
                <Box>
                    <Box className={header}>
                        <Typography variant='h4' sx={{ fontWeight: "600" }}>
                            Follow up assessment
                        </Typography>
                        <Select
                            label="Select Batch"
                            options={batches}
                            value={batchId}
                            disabled={true}
                        />
                    </Box>

                    <Typography variant="subtitle" color="#5B5C5C">{title}</Typography>
                </Box>
                <Box className={dropdown}>
                    <span>Domain:</span>
                    <DomainField options={domains} value={domain} onChange={handleDomainChange} />
                </Box>
                <Box className={contentContainer}>
                    <Box className={contentHeader}>
                        <Typography variant="h5" color="initial" sx={{ fontWeight: "600" }}>
                            Assessments
                        </Typography>
                        <DebouncedTextField
                            placeholder="Search by Assessment name"
                            startIcon
                            onChange={setAssessmentSearch}
                            width={"300px"}
                        />
                    </Box>
                    <Typography variant='body1' className={contentHeaderSubTitle}>
                        Choose all assessments you want to consider to generate the follow up assessment
                    </Typography>

                    <StaticTable
                        height={350}
                        columns={columns}
                        loading={templateLoading}
                        rows={rows}
                        noRowsMessage="No Assessments found in the selected domain!!"
                        showExports={false}
                        showColumnSelection={false}
                        rowSelectionModel={selectedAssessments}
                        setRowSelectionModel={setSelectedAssesment}
                        showRowSelectCheckbox
                        outerSearchString={assessmentSearch}
                        selectAll={true}
                    />

                </Box>
                <Typography className={contentFooter}>
                    Based on your assessment selection, the following users
                    are eligible for the follow up assessment:
                </Typography>
                <Box className={contentContainer}>
                    <Box className={contentHeader}>
                        <Typography variant="h5" color="initial" sx={{ fontWeight: "600" }}>
                            Students
                        </Typography>
                        <DebouncedTextField
                            placeholder="Search by Student name"
                            startIcon
                            onChange={setStudentSearch}
                            width={"300px"}
                        />
                    </Box>
                    <Typography variant='body1' className={contentHeaderSubTitle}>
                        Choose all users you for whom you want to generate the follow up assessment<br />
                        *Please note that all parameters in the below table is based on the assessments selection
                    </Typography>
                    <StaticTable
                        height={350}
                        columns={studentColumn}
                        rows={studentRows}
                        loading={studentLoading}
                        noRowsMessage="No students found in the selected assessments!!"
                        showExports={false}
                        showColumnSelection={false}
                        rowSelectionModel={selectedStudents}
                        setRowSelectionModel={setSelectedStudents}
                        showRowSelectCheckbox
                        outerSearchString={studentSearch}
                        selectAll={true}
                    />
                </Box>
                {/* <Box>
                    *14 students have attempted less than a total of 10 questions. 
                    Hence, followup assessment can’t be generated for them.
                </Box> */}
                <Grid container mt={2}>
                    <DatePickerField
                        description={
                            <>
                                You can choose to publish the assessment <b>Now</b> or schedule
                                it for a later date.
                            </>
                        }
                        name="publishOn"
                        label="Publish On*"
                        value={values.publishOn}
                        onChange={handleDateChange}
                        defaultValue={dayjs().toISOString()}
                    />
                    <AllowedAttempts width={500}
                        onChange={handleChange}
                        value={values?.maxAllowedAttempts} />
                </Grid>

                <Grid container>
                    <DatePickerField
                        description={
                            <>
                                Set an expiry date for the assessment, or choose <b>Never</b> to
                                keep it available indefinitely.
                            </>
                        }
                        name="expiresOn"
                        label="Expires On*"
                        value={values.expiresOn}
                        onChange={handleDateChange}
                        minDate={values?.publishOn ? values.publishOn : undefined}
                        defaultValue={dayjs().set("year", 9999).endOf("year").toISOString()}
                    />
                </Grid>

                <ProctorSettings
                    values={values?.proctorSettings || {}}
                    onChange={handleChange}
                    defaultExpanded={false}
                />
            </ShadowBox>
            <Box p={2} ml="auto" width="fit-content">
                <CustomLoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    style={ActionStyle}
                    endIcon={<RightArrowIcon />}
                    loading={loading}
                    loadingPosition="end"
                >
                    Save & Publish Assessment
                </CustomLoadingButton>
            </Box>
        </form>
    )
}

export default FollowUpEditor