import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import ContentFeedback from './ContentFeedback';
import SpeechFeedback from './SpeechFeedback';

import { defaults } from './store';
import classNames from 'classnames';
import { Categories } from './constants';
import { LinearBoxesProgressBar } from './ParameterScore';
import { round } from 'lodash';

const useStyles = makeStyles({
    analysisCard: {
        cursor: "pointer",
        transition: 'background-color 200ms',
        "&:hover": { backgroundColor: "#E3F4FF55" },
    },
    selected: {
        backgroundColor: "rgba(77, 109, 255, 0.14)",
        transition: 'background-color 200ms',
        color: "#02569D",
        border: "2px solid #02569D",
        "&:hover": { backgroundColor: "rgba(77, 109, 255, 0.14)" },
    }
});

const sx = {
    card: {
        boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: 170,
        height: 90,
        p: 2,
        position: "relative",
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
    },
    subTitle: {
        fontSize: 12,
        fontWeight: 400,
    },
    container: {
        display: "flex",
        gap: "8px",
        flexWrap: "wrap",
    },
    progress: {
        borderRadius: "0 0 6px 6px",
        height: 6,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
    },
    detailedParameter: {
        backgroundColor: "rgba(77, 109, 255, 0.03)",
        display:"flex",
        borderRadius: "7px",
        padding: "10px"
    },
    analysisHead: {
        color: "#02569D",
        fontWeight: "700",
        fontSize: "18px"
    },
    analysisDefaultCard: {
        width: "100%",
        boxShadow: "0px 4px 4px 0px rgba(77, 109, 255, 0.25)",
        backgroundColor: "rgba(77, 109, 255, 0.01)",
        borderRadius:"5px",
        py: 2 ,
        px: 1,
        display:"flex",
        justifyContent: 'space-between',
        alignItems: "center",
        color: "#424242"
    }
}

function AnalysisCard({
    selected, category = "NA", rating = 0, onClick
}) {
    const classes = useStyles();

    const data = React.useMemo(() => Math.round(rating / 10), [rating]);
    return (
        <Box
            className={classNames({
                [classes.analysisCard]: onClick,
                [classes.selected]: selected
            })}
            onClick= {onClick}
            sx={sx.analysisDefaultCard}
        >
            <Box>
                <Typography
                    fontWeight={550}
                    fontSize={15}
                    textTransform="capitalize"
                >
                    {Categories?.[category]}
                </Typography>
            </Box>
            <Box display={"flex"} gap={2} alignItems={"flex-end"} mr="15px">
                <LinearBoxesProgressBar data={data}/>
                <Typography
                    fontWeight={600} fontSize={15}
                >
                    {data}/10
                </Typography>
            </Box>
        </Box>
    );
}

export default function AnalysisContainer({ question, content, speech }) {
    const [category, setCategory] = React.useState(0);
    const [contCategory, setContCategory] = React.useState(0);

    const { commRatings, contRatings, commTags } = React.useMemo(
        () => {
            const {
                communication: { ratings: commRatings, tags: commTags },
                content: { ratings: contRatings },
            } = question.isError ? defaults.questions[0] : question || { communication: {}, content: {} };

            if( question.type === "situational" ){
                delete contRatings.SIMILARITY;
            }

            return {
                commRatings: Object.entries(commRatings),
                contRatings: Object.entries(contRatings),
                commTags
            }
        },
        [question]
    );

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="5px"
            width="100%"
            mt= "20px"
            justifyContent="space-between"
            id="detailed_feedback"
        >
            <Box>
                <Typography 
                    fontSize='16px'
                    component='div'
                    fontWeight='600
                '>
                    Detailed parameter feedback and Tips
                </Typography>
            </Box>
            <Box sx={sx.detailedParameter} mb={1} id="speech-analysis-container">
                <Box width="420px">
                    <Box>
                        <Typography sx={sx.analysisHead} id="speech-analysis">
                            SPEECH {round((question?.communication?.ratings?.OVERALL))}/100
                        </Typography>
                    </Box>
                    <Box sx={sx.container}>
                        {commRatings.map((subCategory, index) => {
                            if (subCategory[0] !== 'OVERALL') {
                                return (
                                    <AnalysisCard
                                        selected={category === index}
                                        category={subCategory[0]}
                                        rating={subCategory[1]}
                                        key={index}
                                        onClick={() => setCategory(index)}
                                    />
                                )
                            }
                            else return null
                        })}
                    </Box>
                </Box>
                <Box width={'calc(100% - 420px)'}>
                    <SpeechFeedback
                        error={question.isError}
                        tags={commTags}
                        category={commRatings[category][0]}
                        score={commRatings[category][1]}
                    />
                </Box>
            </Box>
            
            <Box sx={sx.detailedParameter} id="content-analysis-container">
                <Box width="420px">
                    <Box>
                        <Typography sx={sx.analysisHead} id="content-analysis">
                            CONTENT {round((question?.content?.ratings?.OVERALL))}/100
                        </Typography>
                    </Box>
                    <Box sx={sx.container}>
                        {contRatings.map((subCategory, index) => {
                            if (subCategory[0] !== 'OVERALL') {
                                return (
                                    <AnalysisCard
                                        selected={contCategory === index}
                                        category={subCategory[0]}
                                        rating={subCategory[1]}
                                        key={index}
                                        onClick={() => setContCategory(index)}
                                    />
                                )
                            }
                            else return null
                        })}
                    </Box>
                </Box>
                <Box width={'calc(100% - 420px)'}>
                    <ContentFeedback
                        error={question.isError}
                        feedback={question?.content?.feedback}
                        category={contRatings[contCategory][0]}
                        score={contRatings[contCategory][1]}
                    />
                </Box>
            </Box>
        </Box>
    );
}

