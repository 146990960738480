import React from "react";
import ReactDOM from "react-dom";
import Screen from "./screens/index";
import './index.css';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

if (process.env.REACT_APP_STAGE === "prod") {
  require("./analytics");
}

ReactDOM.render(
  <React.StrictMode>
    <Screen />
  </ React.StrictMode>,
  document.getElementById("root")
);

