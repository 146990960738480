import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DefaultOptions = {
  maintainAspectRatio: true,
  responsive: true,
  aspectRatio: 5,
  interaction: { mode: 'index', intersect: false },
  stacked: false,
  elements: { line: { tension: 0.2 } },
  plugins: {
    title: { display: false },
    legend: { display: false },
    tooltip: { events: ['click', 'mousemove'] }
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      min: 0,
      max: 100,
      title: {
        display: true,
        text: 'Score',
      },
      ticks: {
        stepSize: 25
      }
    },
  },
  layout: { padding: 5, }
};

const sx = {
  root: {
    my: 0,
    background: "#FFFFFF",
  },
  chartCotainer: {
    width: "100%",
    position: "relative",
    pl: "0%",
    pr: "0%"
  },
  performanceText: {
    flexGrow: 1,
    flexShrink: 0,
    pl: 4,
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "18px",
  },
  message: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "16px",
    color: "#858585",
  },
  tagContainer: {
    height: "100%",
    width: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  compliment: {
    ml: 4,
    mr: 10,
    variant: "fontFamily",
    fontSize: 15,
    fontWeight: 500,
  }
};

function PerformanceGraphHeader({
  assessmentType, setAssessmentType, parameter, setParameter,
}) {
  return (
    <Box display="flex" pl={2} alignItems='center' justifyContent="space-between">
      <Typography fontWeight={600} fontSize={20} id="recent-attempts-analysis">
        Recent Attempts Analytics
      </Typography>
      <Box display="flex" alignItems='center'>
        <FormControl sx={{ m: 1, width: 200 }} size="small" fullWidth>
          <InputLabel id="assessment-type-label">
            Choose assessment type
          </InputLabel>
          <Select
            input={<OutlinedInput label="Choose assessment type" size="small" />}
            id="assessment-type"
            labelId="assessment-type-label"
            value={assessmentType}
            onChange={e => setAssessmentType(e.target.value)}
          >
            {['All Assessments', 'Practice', 'Assignment'].map((value, i) => (
              <MenuItem
                key={i}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 200 }} size="small" fullWidth>
          <InputLabel id="parameter-label">
            Choose parameter
          </InputLabel>
          <Select
            input={<OutlinedInput label="Choose parameter" />}
            id="parameter"
            labelId="parameter-label"
            value={parameter}
            onChange={e => setParameter(e.target.value)}
          >
            {['Overall', 'Speech', 'Content'].map((value, i) => (
              <MenuItem
                key={i}
                value={(value === 'Speech' ? 'Communication' : value)}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export function PerformanceGraph(props) {
  const [options, setOptions] = React.useState(DefaultOptions);

  options.onClick = (e) => {
    const dataIndex = e?.chart?.tooltip?.dataPoints?.[0]?.dataIndex;
    if (typeof dataIndex === "number") props.onClick(dataIndex)
  };

  const data = React.useMemo(() => {
    if (props.data) {
      const para = props.parameter.toLowerCase();

      return props.data.map(d => d[para]);
    }
  }, [props.parameter, props.data]);

  const labels = React.useMemo(() => {
    const _labels = props?.data?.map((item, index) => {
      return (props?.labelPrefix || '').concat(index + 1);
    });

    if (_labels.length < 10) {
      for (let i = _labels.length; i < 10; i++) {
        _labels.push('')
      }
    }

    return _labels;

  }, [props.data, props.labelPrefix]);

  const colorScale = data.map(mark => {
    if (mark >= 65) {
      return '#19C10A';
    } else if (mark >= 35) {
      return '#FFD600';
    } else {
      return '#F80505';
    }
  });

  React.useEffect(() => {
    setOptions((options) => {
      const newOptions = { ...options };

      newOptions.plugins.tooltip.callbacks = props.tooltipCallbacks;

      newOptions.aspectRatio = props.aspectRatio;

      return newOptions;
    });
  }, [props.aspectRatio, props.tooltipCallbacks]);

  return (
    <Box sx={sx.root}>
      {!props.hideHeader && (
        <PerformanceGraphHeader
          assessmentType={props.assessmentType}
          setAssessmentType={props.setAssessmentType}
          parameter={props.parameter}
          setParameter={props.setParameter}
        />
      )}
      <Box sx={sx.chartCotainer} >
        <Line
          style={{ cursor: "pointer" }}
          options={options}
          data={{
            labels,
            datasets: [
              {
                label: (props.parameter === 'Communication') ?
                  'Speech' :
                  props.parameter,
                data: data,
                borderWidth: 2,
                borderColor: '#02569D',
                backgroundColor: 'white',
                pointBorderColor: colorScale,
                pointBorderWidth: 2,
                yAxisID: 'y',
                pointStyle: 'circle',
                pointRadius: 4
              }
            ],
          }}
        />
        <Box textAlign="center" fontSize={10} color='#666666' mb={1}>
          {props.xLabel || "Assessments"}
        </Box>
      </Box>
    </Box>
  );
}
