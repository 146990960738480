import React from "react";

import * as Yup from 'yup';

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import ActionButtons from 'components/ActionButtons';
import InfoIconWithTooltip from "components/InfoIconWithTooltip";

import { TextField } from "components/TextField";
import { useSnackbar } from "contexts";
import { useFormik } from "formik";
import { overwriteClientById } from "services";
import { Session } from "utils";
import InprepLogoEditor from "./InprepLogoEditor";

const useStyle = makeStyles({
    controlContainer: {
        display: 'flex', width: '50%', justifyContent: 'space-between',
    },
    buttonContainer: {
        display: 'flex', width: '50%', justifyContent: 'flex-start'
    }, textField: {
        width: '50%', minWidth: 380
    }
});

export function Label({ value, tooltip }) {
    return (
        <Box display='flex' gap={1} alignItems='center'>
            <Typography fontFamily='Inter' fontSize={15} fontWeight={600}>
                {value}
            </Typography>
            {tooltip && <InfoIconWithTooltip title={tooltip} placement="right" />}
        </Box>
    );
}

const Tooltips = {
    redirectUrl: 'Upon clicking logo, user will be redirected to the following URL',
    customizeUrl: 'Onboarding Url for User Portal',
    headerLogo: "Logo which will be displayed to students in the Header",
};

export default function General() {
    const classes = useStyle();
    const snackbar = useSnackbar();

    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);

    const {
        values,
        errors,
        handleSubmit,
        handleChange
    } = useFormik({
        initialValues: {
            redirectUrl: Session?.user?.metadata?.redirectUrl || '',
            customizeUrl: process.env.REACT_APP_INPREP_URL + '/' + (Session?.user?.name || '').toLowerCase().split(' ').join('-'),
        },
        validationSchema: new Yup.ObjectSchema({
            redirectUrl: Yup.string().url('Must be a valid url').trim().required('Required'),
            customizeUrl: Yup.string().trim(),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);

                const client = { metadata: { redirectUrl: values.redirectUrl } };

                const newClient = await overwriteClientById(Session.userId, client);

                Session._setUser(newClient);
                setEdit(false);

                snackbar.showSnackbar('Successfully saved', 'success');
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error');
            } finally {
                setLoading(false);
            }
        },
    });

    const handleChangeWrapper = (e) => {
        setEdit(true);
        handleChange(e);
    }

    return (
        <Box>
            <form>
                <Label value='Redirect Url' tooltip={Tooltips.redirectUrl} />
                <TextField
                    id='redirectUrl'
                    name='redirectUrl'
                    value={values.redirectUrl}
                    onChange={handleChangeWrapper}
                    helperText={errors.redirectUrl}
                    error={errors.redirectUrl}
                    sx={{ width: '50%', minWidth: 380 }}
                /> <br /> <br />

                <Label value='Onboarding Url' tooltip={Tooltips.customizeUrl} />
                <TextField
                    sx={{ width: '50%', minWidth: 380 }}
                    disabled
                    id='customizeUrl'
                    name='customizeUrl'
                    value={values.customizeUrl}
                    onChange={handleChangeWrapper}
                />

                {(edit) && <Box className={classes.buttonContainer}>
                    <ActionButtons
                        loading={loading}
                        primaryFirst
                        primaryAction="Save"
                        secondaryAction="Cancel"
                        primaryActionVariant="primary"
                        secondaryActionVariant="error"
                        onSecondaryClick={() => setEdit(false)}
                        onPrimaryClick={handleSubmit}
                    />
                </Box>}
            </form>

            <br /> <Divider light /> <br />

            <Label value='Customize Header logo' tooltip='' />
            <InprepLogoEditor />

        </Box>
    );
}