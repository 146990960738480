import React from 'react';

import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ShadowBox from 'components/ShadowBox';
import { formatDate } from 'utils';
import { getBatchById } from 'services';

const styles = {
  detail: { marginTop: 1, fontWeight: 600, fontSize: 14 },
  label: { fontStyle: "normal", color: "#636363", fontWeight: 400 },
  capitalizeText: { textTransform: 'capitalize' }
};

function Detail({ label, children, capitalize }) {
  return (
    <Typography
      sx={styles.detail}
      style={capitalize ? styles.capitalizeText : {}}
    >
      <span style={styles.label}>{label}:&nbsp;</span>{children}
    </Typography>
  );
}

function BatchInfo({ batch }) {
  
  return (
    <ShadowBox display="flex" alignItems="stretch" gap={2} m={0}>
      <Box flexGrow={1} flexShrink={0}>
        <Typography variant="h6">
          {batch?.name}&nbsp;
        </Typography>
        <Detail label="No. of students" capitalize>
          {batch?.studentCount?.toString()}
        </Detail>
        <Detail label="Assessment Uploaded">
          {batch?.templateCount?.toString()}
        </Detail>
        <Detail label="Created On" >
          {formatDate(batch?.createdAt, 'Do MMM, YYYY (dddd)')}
        </Detail>
      </Box>
    </ShadowBox>
  );
}

export default BatchInfo;