import React from 'react';

import { Link, useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CustomButton from 'components/CustomButton';

import { useSnackbar } from 'contexts';
import { getTemplates } from 'services';
import { formatDate, getAverageScore } from 'utils';
import dayjs from 'dayjs';
import StaticTable from 'components/Tables/StaticTable';

const columns = [
  {
    id: 'name', label: 'Name', field: 'name', maxWidth: 90
  },
  {
    id: 'publishOn', type: 'date',
    label: 'Published On', field: 'publishOn', align: 'center',
    renderCell: ({ row }) => formatDate(row.publishOn, 'DD-MMM-YYYY, hh:mma')
  },
  {
    id: 'expiresOn', type: 'date',
    label: 'Expires On', field: 'expiresOn', align: 'center',
    renderCell: ({ row }) => {
      if (dayjs(row.expiresOn).year() === 9999) return 'Never';
      return formatDate(row.expiresOn, 'DD-MMM-YYYY, hh:mma');
    }
  },
  {
    id: 'overallScore', type: 'number', maxWidth: 60,
    label: 'Average Score', field: 'overallScore', align: 'center',
    renderCell: ({ row }) =>
      row.finishedAttempts === 0 ? "-" : Math.round(row.overallScore) || 0,
  },
];

const initialFilters = [
  "name", "overallScore", "publishOn", "expiresOn"
]

function TemplateList({ onTemplateCountChange, setActiveAssessment }) {
  const { id } = useParams();
  const snackbar = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  
  const _rows = React.useMemo(() => {
    const allRows = rows?.map((rowData) => {
      return {
        ...rowData,
        overallScore: getAverageScore(rowData.communicationAverage, rowData.contentAverage)
      }
    })
    
    return allRows
  }, [rows]);

  React.useEffect(() => {
    if (id !== "create") {
      setLoading(true);
      getTemplates({ batch: id, orderBy: "createdAt", order: "desc", archive: false })
        .then(({ interviewTemplates }) =>{
          setRows(interviewTemplates)
          setActiveAssessment(interviewTemplates?.length)
        })
        .catch((error) => snackbar.error("Unable to load templates"))
        .finally(() => setLoading(false));
    }
  }, [id]);

  React.useEffect(() => {
    if (typeof onTemplateCountChange === 'function')
      onTemplateCountChange(rows.length);
  }, [rows]);

  return (
    <div>
      <Box
        style={{
          padding: '5px 16px',
          marginTop: '20px'
        }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h6">Assessments</Typography>
          <Typography variant="subtitle2" color="#747575">
            Check overview/add assessments for this batch
          </Typography>
        </Box>
        <Box>
          <Link
            to={id !== "create" ? `/assessments?fb=${id}` : "/assessments"}
            style={{ textDecoration: "none" }}
          >
            <CustomButton
              startIcon={<AddIcon />}
              variant="contained"
            >
              Add Assessments
            </CustomButton>
          </Link>
        </Box>
      </Box>
      <Box px='16px'>
        <StaticTable
          loading={loading}
          height="300px"
          noRowsMessage="No Templates added in this batch!!"
          rows={_rows}
          initialFilters={initialFilters}
          columns={columns}
          setRowSelectionModel={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          showColumnSelection={false}
          showExports={false}
        />
      </Box>
    </div>
  );
}

export default TemplateList;