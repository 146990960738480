import Client from "./_client";

export const getBatches = async (batchFilters = {}) => {
    let url = "/batch?";

    Object.keys(batchFilters).forEach((key) => {
        if (batchFilters[key] !== undefined) url += `${key}=${batchFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to fetch batch!");

    return result.data || [];
}

export const getBatchById = async (batchId) => {
    const url = `/batch/${batchId}`;

    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get batch. Please try again."
        );
    }

    return result.data;
}

export const saveBatch = async (batchId, batch) => {
    let result;
    if (batchId) {
        result = await new Client({
            path: `/batch/${batchId}`,
            payload: batch,
        }).put();
    } else {
        result = await new Client({
            path: `/batch`,
            payload: batch,
        }).post();
    }
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save batch. Please try again."
        );
    }

    return result.data;
}

export const deleteBatchById = async (batchId) => {

    let url = `/batch/${batchId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete batch. Please try again."
        );

    return result.data;
}

export const generateJoiningLink = async (batchId) => {
    let url = `/batch/${batchId}/generate-joining-link`;

    let result = await new Client({ path: url }).put();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to generate link. Please try again."
        );

    return result.data;
}

export const removeBatchAndDeleteTemplates = async (
    batchId, templateIds = []
) => {
    let url = `/batch/${batchId}/templates`;

    let result = await new Client({
        path: url,
        payload: { templateIds }
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete assessments. Please try again."
        );

    return result.data;
}

export const deleteStudentsFromBatch = async (
    batchId, batchUserMappingIds = []
) => {
    let url = `/batch/${batchId}/students`;

    let result = await new Client({
        path: url,
        payload: { batchUserMappingIds }
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete students. Please try again."
        );

    return result.data;
}

export const removeStudentsFromBatch = async (
    batchId, batchUserMappingIds = []
) => {
    let url = `/batch/${batchId}/students/remove`;

    let result = await new Client({
        path: url,
        payload: { batchUserMappingIds }
    }).put();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to remove students. Please try again."
        );

    return result.data;
}

export const updateStudentsFromBatch = async (
    batchId, batchUserMappingIds = []
) => {
    let url = `/batch/${batchId}/students`;

    let result = await new Client({
        path: url,
        payload: { batchUserMappingIds }
    }).put();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete students. Please try again."
        );

    return result.data;
}

export const addStudentsInBatch = async (batchId, students = [], amount = 0) => {
    let url = `/batch/${batchId}/students`;

    let result = await new Client({
        path: url,
        payload: { students, amount }
    }).post();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to add students. Please try again."
        );

    return result.data;
}

export const resendInviteLink = async (clientId, students = []) => {
    let url = `/client/interview/${clientId}/bulk-invite-mails`;

    let result = await new Client({
        path: url,
        payload: { students }
    }).post();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to resend the links. Please try again."
        );

    return result.data;
}

export const updateStudentSubscription = async (
    batchId, students = [], amount = 0, upgrade, reduce
) => {
    let url = `/batch/${batchId}/students`;

    let result = await new Client({
        path: url,
        payload: { students, amount, upgrade, reduce }
    }).put();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to add students. Please try again."
        );

    return result.data;
}

export const calculateAccessInBatch = async (batchId, students = [], upgrade, reduce) => {
    let url = `/batch/${batchId}/calculate-access`;

    let result = await new Client({
        path: url,
        payload: { students, upgrade, reduce }
    }).post();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to calculate Access. Please try again."
        );

    return result.data;
}

export const getAttendanceAndAverageScoreStats = async (
    batchId, excludeMostRecentTemplate = false
) => {
    let url = `/batch/${batchId}/attendence-and-average-score?`;

    if (excludeMostRecentTemplate)
        url += `excludeMostRecentTemplate=${excludeMostRecentTemplate}`;

    let result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get statistics. Please try again."
        );
    }

    return result.data;
}

export const getBestPerformersOfBatch = async (batchId, filters) => {
    let url = `/batch/${batchId}/best-performers?`;

    if (filters?.templateId) url += `template=${filters.templateId}`;
    if (filters?.personalizedTemplateDraftId) 
        url += `personalizedTemplateDraftId=${filters.personalizedTemplateDraftId}`;

    let result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get best performers. Please try again."
        );
    }

    return result.data;
}

export const getRecentTemplatesAnalytics = async (batchId, type = 'all') => {
    let result = await new Client({
        path: `/batch/${batchId}/recent-template-analytics?type=${type}`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to fetch analytics. Please try again!!"
        );
    }

    return result.data;
}

export const getUsersAnalytics = async (batchId, filter) => {
    let url = `/batch/${batchId}/users-analytics?`;

    if (filter?.templateId) url += `template=${filter.templateId}`
    if (filter?.personalizedTemplateDraftId) url += `personalizedTemplateDraftId=${filter.personalizedTemplateDraftId}`
    if (filter?.userId) url += `user=${filter.userId}`

    let result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to fetch analytics. Please try again!!"
        );
    }

    return result.data;
}

export const getUsersPerformanceAnalytics = async (batchId, filter) => {
    let url = `/batch/${batchId}/users-analytics-export-performance?`;

    if (filter?.templateId) url += `template=${filter.templateId}`
    if (filter?.userId) url += `user=${filter.userId}`

    let result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to fetch performance analytics. Please try again!!"
        );
    }

    return result.data;
}