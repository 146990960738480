import React from 'react';

import UploadIcon from '@mui/icons-material/Upload';
import CustomButton from "components/CustomButton";
import ImportQuestionsDialog from "dialogs/ImportQuestions";

function ImportQuestions(props) {
  const [openImport, setOpenImport] = React.useState(false);

  const toggleDialog = () => setOpenImport(oi => !oi);
  return (
    <>
      <CustomButton
        startIcon={<UploadIcon />}
        variant="outlined"
        style={{ position: "absolute", right: 32, zIndex: 3 }}
        onClick={toggleDialog}
      >
        Import Questions
      </CustomButton>
      <ImportQuestionsDialog open={openImport} onClose={toggleDialog} />
    </>
  );
}

export default ImportQuestions;