import { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import DoughnutGraph from 'components/Home/DoughnutGraph';
import Toggle from 'components/Toggle';
import * as Constants from "./constants";
import { getAttendanceAndAverageScoreStats } from 'services';
import { getAverageScore } from 'utils';
import { Typography } from '@mui/material';
import VerticalProgressBar from 'components/BatchAnalytics/VerticalProgressBar';

const useStyles = makeStyles({
    allContainer: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        alignSelf: "stretch", height: '50px',
    },
    shadowContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        border: "1px solid lightgrey",
        borderRadius: "8px", padding: '1em 2em', margin: 0
    }
});

const sx = {
  rangeBox: {
    display: "flex",
    mt:'10px', 
    justifyContent:'center',
    flexDirection:'column',
    gap:'10px'
  },
  avgBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px 12px',
    border: '1px solid #02569D',
    backgroundColor: '#E8F0FD'
  }
}

const AverageScoreLegends = [
  { range: "0-30", color: "#FF2D55" },
  { range: "31-60", color: "#FFCC00" },
  { range: "61-100", color: "#41D7A1" },
];

export default function RecentPerformance({ batch }) {
    const classes = useStyles();

    const [active, setActive] = useState(false);

    const [attandanceStats, setAttandanceStats] = useState({
        "0-30": 0, "31-60": 0, "61-100": 0
    });
    const [averageScoreStats, setAverageScoreStats] = useState({
        "0-30": 0, "31-60": 0, "61-100": 0
    });
    const [averageScore, setAverageScore] = useState(0);
    const [averageAttandance, setAverageAttandance] = useState(0);
    const [increment, setIncrement] = useState({
        'true': 0,
        'false': 0,
    });

    const totalStudents = useMemo(()=>{
      let total = 0;
      AverageScoreLegends.map(data=>
        total += averageScoreStats[data.range]
      )
  
      return total;
    },[averageScoreStats, AverageScoreLegends])

    useEffect(() => {
        if (batch && batch !== 'all') {
            getAttendanceAndAverageScoreStats(batch, true)
                .then(({ attendanceStats, averageScoreStats }) => {
                    const previousAttendance = attendanceStats.averageAttendance;
                    const previousAverage = averageScoreStats.averageScore;

                    getAttendanceAndAverageScoreStats(batch)
                        .then(({ attendanceStats, averageScoreStats }) => {
                            const currAttendance = attendanceStats.averageAttendance;
                            const currAverage = getAverageScore(
                                averageScoreStats.score.communication,
                                averageScoreStats.score.content
                            );

                            setAttandanceStats(attendanceStats);
                            setAverageScoreStats(averageScoreStats);
                            console.log({ currAverage, previousAverage })
                            console.log({ currAttendance, previousAttendance })
                            setIncrement({
                                'true': currAverage - previousAverage,
                                'false': currAttendance - previousAttendance,
                            });
                            setAverageScore(currAverage);
                            setAverageAttandance(currAttendance);
                        })
                        .catch(console.errror)

                })
                .catch(console.errror)
        }
    }, [batch]);

    const handleToggleChange = () => setActive(!active);

    return (
        <Box className={classes.shadowContainer}>
            <Toggle
                br={30} options={Constants.toggleOptions}
                width={260} height={32} my={2}
                onChange={handleToggleChange}
            />
            {(active) ?
              <Box display='flex' justifyContent='center' mt={4}>
                  <Box>
                    <VerticalProgressBar
                      averageScoreStats={averageScoreStats || {}}
                      averageScoreLegends={AverageScoreLegends || []}
                      xAsix={'Score Range'}
                      yAsix={'No. of Students'}
                      totalStudents={totalStudents}
                    />
                    <Box display='flex' justifyContent='center' mt='15px'>
                      <Box sx={sx.avgBox}>
                        <Typography color='#02569D' fontWeight={600} fontSize='16px'>
                          {averageScore}
                        </Typography>
                        <Typography fontSize='15px' fontWeight={600}>
                          Batch Average
                        </Typography>
                        <Typography fontSize='12px' fontWeight={500} mt={0}>
                          Score consisting of {totalStudents} students
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                :
                <Box display='flex' justifyContent='center' flexDirection='column'>
                  <DoughnutGraph
                    rootStyle={{ padding: 0 }}
                    color="#0263FF"
                    data={[
                      attandanceStats["0-30"] || 0,
                      attandanceStats["31-60"] || 0,
                      attandanceStats["61-100"] || 0,
                    ]}
                    bgColors={Constants.AverageScoresBgColors}
                    borderColors={Constants.AverageScoresBorderColors}
                    labels={["No. of Students", "No. of Students", "No. of Students"]}
                    title="Attendance"
                    description="Avg attempt rate of all students in batch"
                    score={averageAttandance || 0}
                    isPercentage
                  />
                  <Box sx={sx.rangeBox}>
                    <Typography fontSize={14} fontWeight={600} textAlign='center'>
                      No. of students in the score range
                    </Typography>
                    {AverageScoreLegends.map((x,index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent='space-between'
                      >
                          <Box display="flex" alignItems="center" gap='10px'>
                            <Box bgcolor={x.color} p={0.5} borderRadius={8} />
                            <Typography fontSize={12} fontWeight={600}>
                              {x.range}
                            </Typography>
                          </Box>
                        <Typography fontSize={12}>
                          {attandanceStats[x.range]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
            }
        </Box>
    );
}