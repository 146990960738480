import CellTowerIcon from "@mui/icons-material/CellTower";
import ScoreIcon from "@mui/icons-material/PinOutlined";
import StudentsIcon from "@mui/icons-material/PeopleAltOutlined";
import GroupIcon from "@mui/icons-material/GroupsOutlined";
import AttemptRateIcon from "@mui/icons-material/AssessmentOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import CenterFlexBox from "components/CenterFlexBox";
import { getAverageScore, round } from "utils";

const useStyles = makeStyles((theme) => ({
  card: {
    borderColor: "#02569D",
    borderRightColor: "#2DABFF",
    borderStyle: "solid",
    borderWidth: "1px 8px 1px 1px",
    backgroundColor: '#FAFDFF',
    width: '30%',
    minWidth: 310,
    height: 180,
    borderRadius: 12,
    margin: 8,
    padding: "8px 16px",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "flex-end",
    position: "relative",
    transition: "all 300ms",
    textDecoration: "none",
    color: "unset",
    "&:hover": {
      boxShadow: "4px 4px 8px 4px lightgray",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    fontFamily: "Inter",
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "260px",
  },
}));

function TemplateAnalyticsCard(props) {
  const classes = useStyles();

  const {
    name,
    expiresOn,
    _id,
    attemptedBy = [],
    assignedTo = [],
    type,
    communicationAverage,
    contentAverage,
  } = props;

  return (
    <Box
      className={classes.card}
      component="a"
      href={`/assessments/${_id}/performance`}
      title="Click to view detailed analytics"
    >
      {expiresOn && dayjs(expiresOn).isAfter(new Date()) && (
        <Chip
          icon={<CellTowerIcon />}
          label="Live"
          size="small"
          color="error"
          variant="outlined"
          sx={{ px: 1.5, position: "absolute", top: 8, left: 8 }}
        />
      )}
      <Chip
        label={type.toUpperCase()}
        size="small"
        color="primary"
        variant="outlined"
        sx={{
          px: 1.5,
          position: "absolute",
          top: 8,
          right: 8,
          letterSpacing: 1,
        }}
      />
      <div className={classes.title}>{name}</div>

      <CenterFlexBox mt={2}>
        <StudentsIcon fontSize="small" />
        <Typography
          fontSize={12}
          display="inline-flex"
          alignItems="center"
          mx={1}
          color="GrayText"
        >
          {attemptedBy?.length || 0} students
        </Typography>{" "}
        &nbsp;&nbsp;
        <GroupIcon fontSize="small" />
        <Typography
          fontSize={12}
          display="inline-block"
          alignItems="center"
          mx={1}
          color="GrayText"
          overflow={"hidden"}
          textOverflow="ellipsis"
          whiteSpace={"nowrap"}
          width="110px"
        >
          {props?.batch?.name || "NA"}
        </Typography>
      </CenterFlexBox>

      <CenterFlexBox mt={0}>
        <ScoreIcon fontSize="small" />
        <Typography
          fontSize={12}
          display="inline-block"
          alignItems="center"
          mx={1}
          color="GrayText"
        >
          Avg Score&nbsp;
          {getAverageScore(communicationAverage || 0, contentAverage || 0)}
        </Typography>
        &nbsp;&nbsp;
        <AttemptRateIcon fontSize="small" />
        <Typography
          fontSize={12}
          display="inline-block"
          alignItems="center"
          mx={1}
          color="GrayText"
        >
          Attempt Rate&nbsp;
          {round(((attemptedBy?.length || 0) / assignedTo?.length) * 100)}
          %
        </Typography>
      </CenterFlexBox>
    </Box>
  );
}

export default TemplateAnalyticsCard;
