import * as React from 'react';
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AvailableProctorSettings } from './constants';
import { styled } from '@mui/material/styles';
import theme from 'theme';

const sx = {
  title: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 18,
  },
  description: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: 12,
    color: "#5B5C5C"
  }
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function Setting({ id, name, label, values, onChange, description, Icon }) {
  const handleChange = (event) => {
    onChange({ target: { value: event.target.checked, name } });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
      <Box>
        <Typography
          variant="h6"
          sx={sx.title}
          style={{ fontSize: 16 }}
          display="flex"
          alignItems="center"
          columnGap={2}
        >
          <Icon fontSize="small" />
          {label}
        </Typography>
        <Typography variant="caption" sx={sx.description}>
          {description}
        </Typography>
      </Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Off</Typography>
        <Switch
          checked={values?.[id]}
          onChange={handleChange}
          color="secondary"
        />
        <Typography>On</Typography>
      </Stack>
    </Box>
  );
}

function ProctorSettings(props) {

  return (
    <Accordion sx={{
      mb: 2, border: 'none', borderRadius: '8px !important',
      boxShadow: props?.boxShadow || '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
    }} defaultExpanded={props.defaultExpanded ?? true} >
    {!props.boxShadow &&   
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={sx.title} >Proctoring Settings</Typography>
      </AccordionSummary>}
      <AccordionDetails sx={{padding:theme.spacing(0)}}>
        {AvailableProctorSettings.map((setting, i) => (
          <Setting key={i} {...setting} {...props} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default ProctorSettings;