import { styled } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: 10,
    maxWidth: 200,
    fontSize: '0.9rem'
  },
}))

function InfoIconWithTooltip({ title, placement = 'bottom', ...props }) {
  return (
    <BootstrapTooltip title={title} placement={placement} {...props}>
      <InfoOutlinedIcon
        fontSize="small"
        sx={{
          color: '#858C90',
          cursor: 'pointer'
        }}
      />
    </BootstrapTooltip>
  );
}

export default InfoIconWithTooltip;