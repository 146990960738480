import React from 'react';
import { Typography } from '@mui/material';

function ZeroQuestions(props) {
    return (
        <Typography
            sx={{
                textAlign: "center",

            }}
            variant="body1"
        >
            Uh Oh! Looks like you haven't submitted any questions in this attempt.
            <br />
            Let's Practice again to view analysis!
        </Typography>
    );
}

export default ZeroQuestions;