import React from "react";

import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CircleIcon from "@mui/icons-material/CircleOutlined";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import "./styles.css";

const IconStyle = { fontSize: 16, marginRight: 4, pointerEvents: "none" };

function OptionChips({
    name, defaultValue, value, onChange, options, small, index, disabled, ...props
}) {
    const handleClick = (e) => {
        e.currentTarget.name = name;
        e.currentTarget.value = e.currentTarget.getAttribute("value");
        e.target = e.currentTarget;
        onChange(e);
    };

    const iconStyle = React.useMemo(() => {
        if (small) IconStyle.fontSize = 12;
        else IconStyle.fontSize = 16;

        return { ...IconStyle };
    }, [small]);

    return options.length ? (
        <div className="option-chips-container" style={{ alignItems: "center" }} {...props}>
            {options.map((option, index) => {
                const isOptionString = typeof option === "string";
                const optionValue = isOptionString ? option : option.value;
                const isSelected = (value || defaultValue) === optionValue;
                return (
                    <>
                        <div
                            key={index}
                            name={name}
                            value={optionValue}
                            onClick={disabled ? null : handleClick}
                            disabled={disabled}
                            style={{ cursor: disabled ? "not-allowed" : "pointer", }}
                            className={classNames({
                                "option-chip": true,
                                "selected-chip": isSelected,
                                "small-chip": small,
                                "large-chip": !small
                            })}
                        >
                            {isSelected ? (
                                <CheckIcon style={iconStyle} />
                            ) : (
                                <CircleIcon style={iconStyle} />
                            )}
                            {option.label || option}
                        </div>
                        {option.info && (
                            <Tooltip title={option.info} arrow>
                                <InfoIcon fontSize="small" style={{ marginRight: 4 }} />
                            </Tooltip>
                        )}
                    </>
                );
            })}
        </div>
    ) : null;
}

export default OptionChips;