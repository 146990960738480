import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "contexts";

export function useList({
  itemName,
  getItems,
  deleteItemById,
  archiveItemById,
  initialFilters,
  initialSortModel,
  initialPageSize,
  initialPageNo,
}) {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [items, setItems] = React.useState([]);
  const [_filters, _setFilters] = React.useState(initialFilters || {});
  const [_sortModel, _setSortModel] = React.useState(initialSortModel || {});
  const [pageNo, setPageNo] = React.useState(initialPageNo || 1);
  const [pageSize, setPageSize] = React.useState(initialPageSize || 10);

  const loadListItems = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await getItems({
        ...initialFilters,
        ..._filters,
        ..._sortModel,
        pageNumber: pageNo,
        pageSize: pageSize,
      });

      const newItems = result[itemName] || [];

      setItems(newItems);
      setTotal(result.total || newItems.length);
    } catch (error) {
      console.error("DEBUG::useList:getItems->", error);
      snackbar.showSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, [pageNo, pageSize, _filters, _sortModel, itemName]);


  const deleteItem = async (itemId) => {
    setLoading(true);
    try {
      await deleteItemById(itemId);

      await loadListItems();
      snackbar.showSnackbar("Fetched list items successfully!!", "success");
    } catch (error) {
      console.error("DEBUG::useList_.deleteItem", error);
      snackbar.showSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const archiveItem = async (itemId) => {
    setLoading(true);
    try {
      await archiveItemById(itemId);

      await loadListItems();
      snackbar.showSnackbar("Fetched list items successfully!!", "success");
    } catch (error) {
      console.error("DEBUG::useList_.archiveItem", error);
      snackbar.showSnackbar(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const editItem = (e, itemId = 'create') => navigate(itemId);

  React.useEffect(() => {
    loadListItems();
  }, [_filters, _sortModel, pageNo, pageSize]);

  React.useEffect(() => {
    _setFilters(initialFilters)
  }, [initialFilters]);

  return {
    loading,
    total,
    pageNo,
    pageSize,
    items,
    setItems,
    filters: _filters,
    setFilters: _setFilters,
    setSortModel: _setSortModel,
    setPageNo,
    setPageSize,
    deleteItem,
    archiveItem,
    editItem,
    refresh: loadListItems
  };
}