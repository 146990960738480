import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AccountIcon from '@mui/icons-material/AccountCircle';

const useStyles = makeStyles({
  header: {
    position: 'sticky',
    width: '100%',
    height: "56px",
    left: '0px',
    top: '0px',
    backgroundColor: '#F1F2F6',
    padding: '0 2em',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
  },
  textDecorationNone: {
    textDecoration: "none",
    textTransform: "none",
    "& >*": {
      textTransform: "none",
      textDecoration: "none"
    }
  },
  link: {
    textTransform: "none",
    textDecoration: "none",
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
  },
  topItems: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "16px",
    color: "#000000",
    margin: "8px 0px",
    width: "fit-content",
    padding: "5px 0px",
  },
  selected: {
    borderBottom: "2px solid black",
    fontWeight: 600
  },
});

const menuItems = ['Home', 'Performance', 'Template Library', 'Create Template'];

function InPrepHeaderPreview({ imageSrc, translate, rotation, scale }) {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ height: '56px', width: 'auto', overflow: 'hidden' }}>
          <img
            src={imageSrc}
            alt={"prview-logo"}
            style={{
              height: '60px',
              width: 'auto',
              transform: `translate(${translate.x}px, ${translate.y}px) rotate(${rotation}deg) scale(${scale})`
            }}
          />
        </div>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexGrow={1}
          alignItems="`center"
          gap={3}
        >
          {menuItems.map((menuItem, index) => (
            <p key={index} className={classes.topItems}>{menuItem}</p>
          ))}
          <IconButton size="small" disabled >
            <Avatar sx={{ width: 32, height: 32 }} src={''}>
              <AccountIcon />
            </Avatar>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default InPrepHeaderPreview;