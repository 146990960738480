import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import CustomFaqs from "components/CustomFaqs";
import TextField from "components/TextField";
import React from "react";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "contexts";
import { addFeedback, getPreviousMonthUsersCount } from "services";
import { Session } from "utils";
import { Label } from "./General";
import dayjs from 'dayjs';
import Password from './Password';

const useStyle = makeStyles({
    container: {
        display: 'flex', justifyContent: 'space-between',
    },
    leftContainer: { width: '46%' },
    rightContainer: { width: '46%' },
    title: {
        fontFamily: 'inter', fontWeight: 600, fontSize: 20,
    }, content: {
        fontFamily: 'inter', fontWeight: 600, fontSize: 15,
    }, divider: {
        border: '0.5px solid #D9D9D9',
    }, suggestionContainer: {
        boxShadow: '4px 4px 8px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px', padding: 20, display: 'flex',
        background: '#FEFEFF', flexDirection: 'column', alignItems: 'flex-end'
    }, send: {
        height: 30, textTransform: 'none', marginTop: 5
    },
    textarea: {
        '&::-webkit-input-placeholder': {
            color: 'rgba(0, 0, 0, 0.3)',
        }
    }
});

const Faqs = [
    {
        question: "How can I get more licences?",
        answers: ["To get more licences, reach out to us at growth@languify.in or contact us at +91-9589952975"],
    },
    {
        question: "Can I change my registered email id?",
        answers: ["Currently the email id is not changeable however in subsequent versions in new product features this will be considered"],
    },
    {
        question: "Can I change my Payment plan anytime?",
        answers: ["Yes, you have the option to change your payment plan at any time. To do so, simply get in touch with Languify POC we'll be happy to assist you with the process."]
    },
];

const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export default function Profile() {
    const classes = useStyle();
    const snackbar = useSnackbar();

    const [edit, setEdit] = React.useState(false);
    const [suggestion, setSuggestion] = React.useState('');
    const [previousMonthUsers, setPreviousMonthUsers] = React.useState(0);

    const previousMonthName = React.useMemo(() => {
        const currentMonth = dayjs().month();
        return (currentMonth) ? Months[currentMonth - 1] : Months[0];
    }, []);

    React.useEffect(() => {
        (async () => {
            try {
                const count = await getPreviousMonthUsersCount();

                setPreviousMonthUsers(count);
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error');
            }
        })();
    }, []);

    const handleSend = async () => {
        try {
            await addFeedback(suggestion);

            setEdit(false);
            snackbar.showSnackbar("Thankyou for your valuable feedback!");
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error');
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.leftContainer}>

                <Label value='Email' tooltip='' />
                <TextField
                    id='email'
                    name='email'
                    value={Session?.user?.email}
                    disabled
                    sx={{ width: '50%', minWidth: 380 }}
                />
                <Typography fontFamily='Inter' fontSize={22} fontWeight={600} my={3}>
                  {"Change your Admin Password"}
                </Typography>
                <Password/>

            </Box>

            <Box className={classes.divider}></Box>

            <Box className={classes.rightContainer} >
                <Typography className={classes.title} mb={2} ml={2}>
                    FAQs
                </Typography>
                <CustomFaqs
                    faqs={Faqs}
                    style={{
                        border: '1px solid white',
                        padding: '0px 0px',
                        color: 'white.main'
                    }}
                    backgroundColor='#ffffff !important'
                    color='white.main'
                /> <br /> <br />

                <Box className={classes.suggestionContainer}>
                    <CenterFlexBox gap={1} justifyContent='flex-start' width='100%'>
                        <ChatBubbleOutlineOutlinedIcon fontSize="small" />
                        <Typography className={classes.content} >
                            Suggestion Form
                        </Typography>
                    </CenterFlexBox>
                    <Typography
                        className={classes.content} textAlign='center' my={1}
                        fontWeight={'400 !important'} fontSize={'12px !important'}
                    >
                        We are constantly creating the best service for you!
                        Please share your few seconds to help us improve more
                    </Typography>
                    <textarea
                        style={{ width: '100%', }} rows={6}
                        className={classes.textarea}
                        placeholder='We would love to hear your suggestions on how we can improve our service. Please take a moment to share your thoughts with us.
                        Is there anything that you think could be improved?
                        What features would you like to see added in the future?
                        How can we make our service more user-friendly?'

                        value={suggestion}
                        onChange={(e) => {
                            setEdit(true);
                            setSuggestion(e.target.value);
                        }}
                    >
                    </textarea>
                    {(edit) && <Button
                        variant="contained"
                        className={classes.send}
                        onClick={handleSend}
                    >
                        Send
                    </Button>}
                </Box>
            </Box>
        </Box>
    );
}