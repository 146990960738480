import Client from "./_client";
import { Session } from "utils";

export const sendBulkInviteMails = async (students = []) => {
    let url = `/client/interview/${Session.userId}/bulk-invite-mails`;

    const result = await new Client({
        path: url,
        payload: { students },
    }).post();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to send mails!");

    return result.data || [];
}