import React from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

function CustomButton(props, ref) {
  return (
    <Button {...props} ref={ref} sx={{
      width: `${props.width}`,
      ...(props.variant === 'contained' ? { color: "#FFFFFF" } : {}),
      cursor: 'pointer',
      fontFamily: 'Inter',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: 14,
      textTransform: 'none',
      borderRadius: 2,
      margin: '4px',
      ...props?.sx,
    }}
    />
  );
}

export function CustomLoadingButton(props) {
  return (
    <LoadingButton {...props} sx={{
      width: `${props.width}`,
      ...(props.variant === 'contained' ? { color: "#FFFFFF" } : {}),
      cursor: 'pointer',
      fontFamily: 'Inter',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: 14,
      textTransform: 'none',
      borderRadius: 2,
      margin: '4px',
      ...props?.sx,
    }}
    />
  );
}

export default React.forwardRef(CustomButton);