import React from 'react';

import shallow from 'zustand/shallow';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useStore from './store';
import { Link } from '@mui/material';

const sx = {
    root:{
        backgroundColor: "#F4F5F5",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        padding: "10px"

    },
    sampleLink: {
        color: '#02569D',
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
        cursor: "pointer"
    },
    hint: {
        fontWeight: 600,
        fontSize: 14,
        color: "#535353",
    },
    idealAnswerHeading: {
        fontWeight: 600,
        fontSize: 15,
        mt: 2,
        color: "#02569D"
    },
    idealAnswer: {
        fontWeight: 500,
        fontSize: 14,
        mb: 1,
        borderRadius: "4px",
    }
};

function Question(props) {
    const currentQuestion = useStore(state => state.currentQuestion, shallow);
    const question = useStore(state => state.questions[state.currentQuestion], shallow);

    const scrollToSampleAnswer = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return question ? (
        <Box display="iniline-flex" mb={2} sx={sx.root}>
            <Box flexGrow={1} >
                <Box display="inline-flex" justifyContent="space-between" width="100%" gap={1}>
                    <Box id="bookmark-container" display="flex" color='#5B5C5C'>
                        <Typography
                            variant="h5"
                            fontWeight={500}
                            component="div"
                            id="q-and-a"
                        >
                            Q{currentQuestion + 1}.&nbsp;
                            {question.question}
                        </Typography>
                    </Box>
                    <Link 
                        id="sample-answer-button-container"
                        onClick={e=>scrollToSampleAnswer("sample-answer-container")} 
                        mt={"4px"}
                    >
                    {
                    question.type.toLowerCase() === "technical" ?
                        <Typography id="sample-answer-button" sx={sx.sampleLink}>
                            Ideal&nbsp;Answer
                        </Typography>
                        :
                        <Typography id="sample-answer-button" sx={sx.sampleLink}>
                            Sample&nbsp;Answer
                        </Typography>
                    }
                    </Link>
                </Box>
                {(question.hint) && (
                    <>
                        <Typography sx={sx.idealAnswerHeading}>Hint:</Typography>
                        <Typography sx={sx.idealAnswer}>{question.hint}</Typography>
                    </>
                )}
                <Typography sx={sx.idealAnswerHeading}>Your Answer:</Typography>
                <Typography sx={sx.idealAnswer}>{!question?.isError? question.transcript : "Not answered"}</Typography>
            </Box>
        </Box>
    ) : null;
}

export default Question;