import shallow from 'zustand/shallow';

import Box from '@mui/material/Box';
import AnalysisContainer from './AnalysisContainer';
import Question from './Question';
import SampleAnswerForm from './SampleAnswerForm';
import TopNavigation from './TopNavigation';
import ZeroQuestions from './ZeroQuestions';

import useStore from './store';
import PersonalizedFeedback from './PersonalizedFeedback';

const sx = {
    root: {
        background: "#FFFFFF",
        boxShadow: "0px 0px 7px rgba(0,0,0,0.25)",
        borderRadius: 3,
        position: "relative",
        width: "100%",
        p: 4,
    },
    divider: {
        marginTop: 2,
        marginBottom: 2,
        borderColor: "transparent"
    }
}

function Analysis(props) {
    const [questions] = useStore(state => [state.questions], shallow);
    const currentQuestion = useStore(state => state.currentQuestion, shallow);
    const content = useStore(state => state.content, shallow);
    const speech = useStore(state => state.speech, shallow);

    return questions.length ? (
        <Box sx={sx.root}>
            <div id="q-and-a-container">
                <TopNavigation />
                <Question />
            </div>
            <PersonalizedFeedback question={questions[currentQuestion]}/>
            <AnalysisContainer question={questions[currentQuestion]} content={content} speech={speech} />
            <SampleAnswerForm />
        </Box>
    ) : <ZeroQuestions />;
}

export default Analysis;