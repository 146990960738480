import React from 'react';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LoadMoreIcon from '@mui/icons-material/Refresh';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import TimeIcon from '@mui/icons-material/AccessTime';

import DebouncedTextField from 'components/DebouncedTextField';
import CustomButton from 'components/CustomButton';
import Select from 'components/Select';
import useEditorStore from './editorStore';
import { getQuestions, getTemplates } from 'services';
import { Session } from 'utils';

function RemoveButton({ children, ...props }) {
    return (
        <CustomButton
            size="small"
            variant="outlined"
            color="error"
            startIcon={<RemoveIcon />}
            {...props}
        >
            {children}
        </CustomButton>
    );
}

export function Tag(props) {
    return (
        <Chip
            size="small"
            variant="outlined"
            color="info"
            style={{
                fontSize: 10,
                backgroundColor: "#C5D8E8",
                height: 18,
                fontWeight: 500,
            }}
            {...props}
        />
    );
}

function Detail({ children }) {
    return (
        <Typography variant="body2" color="#626060">{children}</Typography>
    );
}

function TemplateSuggestion({ template = {}, added, onAdd, onRemove }) {
    const { name, metadata } = template || {};

    return (
        <Box
            display="flex"
            alignItems="center"
            p={2}
            border="1px solid #C9C9C9"
            borderRadius={2}
            mt={2}
        >
            <Box flexGrow={1} >
                <Typography fontSize="1.3vw" pb={1}>{name || "Assessment Name"} </Typography>
                <Box display="flex" alignItems="center">
                    {metadata?.numberOfQuestions && (<>
                        <Detail>{metadata?.numberOfQuestions} questions</Detail>
                        &nbsp;&nbsp;
                    </>)}
                    {!!metadata?.testTime && (<>
                        <TimeIcon fontSize="10px" color="#626060" />
                        &nbsp;
                        <Detail>{Math.round(metadata.testTime / 60)} min</Detail>
                        &nbsp;&nbsp;
                    </>)}
                    <Tag label="Most Used" />
                </Box>
            </Box>
            {added ? (
                <RemoveButton onClick={() => onRemove(template._id)}>
                    Remove this template
                </RemoveButton>
            ) : (
                <CustomButton
                    size="small"
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => onAdd(template._id)}
                >
                    Use this template
                </CustomButton>
            )}
        </Box>
    );
}

function QuestionSuggestion({ question = {}, added }) {
    const addQuestion = useEditorStore(state => state.addQuestionFromLibrary, shallow);
    const removeQuestion = useEditorStore(state => state.removeQuestion, shallow);

    const { allottedTime, question: ques, type } = question;

    return (
        <Box
            display="flex"
            alignItems="center"
            p={2}
            border="1px solid #C9C9C9"
            borderRadius={2}
            mt={2}
        >
            <Box flexGrow={1} >
                <Typography fontSize="1.3vw" pb={1}>{ques}</Typography>
                <Box display="flex" alignItems="center">
                    <TimeIcon fontSize="10px" color="#626060" />
                    &nbsp;
                    <Typography
                        variant="body2"
                        color="#626060"
                    >
                        {allottedTime > 10 ? Math.round(question.allottedTime / 60) : allottedTime} min
                    </Typography>
                    &nbsp;&nbsp;
                    {question?.type && <Tag label={type} />}
                </Box>
            </Box>
            {added ? (
                <RemoveButton onClick={() => removeQuestion(true, question._id)}>
                    Remove
                </RemoveButton>) : (
                <CustomButton
                    size="small"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => addQuestion(question)}
                >
                    Add
                </CustomButton>)}
        </Box>
    );
}

function Suggestions({ isLanguify, ...props }) {
    const filters = useEditorStore(state => state.filters);
    const templatesAdded = useEditorStore(state => state.templatesAdded, shallow);
    const addTemplate = useEditorStore(state => state.addTemplate, shallow);
    const addedQuestions = useEditorStore(state => state.questionsAdded, shallow);
    const addQuestions = useEditorStore(state => state.addQuestions, shallow);
    const removeQuestions = useEditorStore(state => state.removeQuestions, shallow);

    const [pageSize, setPageSize] = React.useState(5);
    const [sortBy, setSortBy] = React.useState(1);
    const [templates, setTemplates] = React.useState([]);
    const [questions, setQuestions] = React.useState([]);

    const loadDefaults = async (isLanguify) => {
        try {
            const { interviewTemplates } = await getTemplates({
                user: isLanguify ?
                    process.env.REACT_APP_LANGUIFY_CLIENT_ID :
                    Session.userId,
                domain: filters.domain,
                include: "template",
                pageSize: 5,
                order: "desc",
                orderBy: "metdata.usedBy",
            });

            setTemplates(interviewTemplates);

        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => { loadDefaults(isLanguify) }, [filters, isLanguify]);

    React.useEffect(() => {
        if (templates.length) {
            (async () => {
                try {
                    const { interviewQuestions } = await getQuestions({
                        interviewTemplate: templates.map(t => t?._id)
                    });
                    setQuestions(interviewQuestions);
                    setPageSize(5);
                } catch (error) {
                    console.error(error);
                }
            })();
        } else {
            setQuestions([]);
        }
    }, [templates]);

    const handleUseTemplate = React.useCallback((id) => {
        addQuestions(questions.filter(q =>
            q.interviewTemplate === id && !addedQuestions.includes(q._id)
        ));
        addTemplate(id);
    }, [addQuestions, addTemplate, questions, addedQuestions])

    const handleSearch = async (keyword) => {
        try {
            if (keyword.trim().length) {
                const { interviewTemplates } = await getTemplates({
                    user: isLanguify ?
                        process.env.REACT_APP_LANGUIFY_CLIENT_ID :
                        Session.userId,
                    keywords: keyword.trim(),
                    subTopics: keyword.trim(),
                    domain: filters.domain,
                    include: "template",
                    pageSize: 5,
                    order: "desc",
                    orderBy: "metdata.usedBy",
                });
                setTemplates(interviewTemplates);
            } else loadDefaults();
        } catch (error) {
            console.error(error);
        }
    };

    const TemplateSection = React.useCallback(() => (
        <Box my={1}>
            <Divider style={{ color: "#646464" }}>Templates</Divider>
            {templates.map((template, index) => (
                <TemplateSuggestion
                    key={index}
                    template={template}
                    added={templatesAdded.includes(template?._id || template)}
                    onAdd={handleUseTemplate}
                    onRemove={removeQuestions}
                />
            ))}
            {templates.length === 0 && (
                <Typography variant="body2" textAlign='center' pt={4}>
                    No templates found, try searching!!
                </Typography>
            )}
        </Box>
    ), [templates, handleUseTemplate, removeQuestions, templatesAdded]);

    return (
        <Box display={props.show ? "block" : "none"}>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
                <DebouncedTextField
                    startIcon
                    style={{ maxWidth: 300 }}
                    placeholder="Search by keyword, sub-topic..."
                    onChange={handleSearch}
                />
                <Box display="flex" flexShrink={0} alignItems="center">
                    <Typography width={"10ch"}>Sort by:</Typography>
                    <Select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        options={[{ _id: 1, name: "Templates" }, { _id: 2, name: "Questions" }]}
                        style={{ width: 120 }}
                    />
                </Box>
            </Box>

            {sortBy === 1 && <TemplateSection />}

            <Box mt={8}>
                <Divider style={{ color: "#646464" }}>Questions</Divider>
                {questions.slice(0, pageSize).map((q, index) => (
                    <QuestionSuggestion
                        key={index}
                        question={q}
                        added={addedQuestions.includes(q._id)}
                    />
                ))}
                {questions.length === 0 && (
                    <Typography variant="body2" textAlign='center' pt={4}>
                        No questions available with your selections, try searching!!
                    </Typography>
                )}
                <br />
                <br />
                {(pageSize < questions?.length) && (<Box width="100%" display="flex" justifyContent="center">
                    <CustomButton
                        startIcon={<LoadMoreIcon />}
                        onClick={() => setPageSize(Math.min(pageSize + 5, questions.length))}
                    >
                        Load More
                    </CustomButton>
                </Box>
                )}
            </Box>
            {sortBy === 2 && <TemplateSection />}
        </Box>
    );
}

export default Suggestions;