import * as React from 'react';
import {
  Box, IconButton, LinearProgress, Menu, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';

import Row from './Row';
import { searchStringInString, sortRowsByColumn } from 'utils';
import DebouncedTextField from 'components/DebouncedTextField';
import { ExportList, FilterList } from './FilterList';
import TableNoRowsOverlay from 'components/TableNoRowsOverlay';
import { getListActions } from 'components/DynamicTable';
import CustomButton from 'components/CustomButton';

const sx = {
  headerRow: {
    height: '40px',
    '& td': {
      whiteSpace: 'nowrap',
      padding: '10px 10px',
    }
  },
  tableCell: {
    whiteSpace: 'nowrap',
    backgroundColor: '#F1F5FE',
    color: '#40729D',
    borderBottom: '2px solid #C1C2C2',
    borderTop: '2px solid #C1C2C2',
  },
  tableHeader: {
    whiteSpace: 'nowrap',
  },
  stickyColumn: {
    position: "sticky",
    zIndex: 10,
    padding: 0,
    paddingLeft: '5px'
  },
  actionColumn: {
    whiteSpace: 'nowrap',
    position: "sticky",
    right: '-1px',
    zIndex: 10,
    display: 'flex-end',
    width: '40px',
    borderRight: '2px solid #C1C2C2'
  },
  selectButton: {
    fontSize: '12px',
    textTransform: 'capitalize',
    color: "#000",
    whiteSpace: 'nowrap',
    paddingLeft: '5px'
  },
  sortIcon: {
    fontSize: '15px',
    cursor: 'pointer',
    display: 'flex',
    color: '#40729D'
  },
  iconBtn: {
    color: '#fff',
    '&: hover': {
      backgroundColor: '#000'
    }
  },
  searchIcon: {
    position: 'absolute',
    left: 0,
    color: '#464545',
    padding: '2px'
  },
  sortByButton: {
    fontSize: '12px',
    textTransform: 'capitalize',
    border: "2px solid #02569D",
    whiteSpace: 'nowrap',
    color: "#02569D",
  },
  emptyDialogBox: {
    width: "100%",
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', height: '300px'
  }
};

const useStyles = makeStyles((theme) => ({
  iconButtonRoot: {
    color: '#fff',
    '&:hover': {
      backgroundColor: "#a8a3a347"
    },
  },
}));

// sortBy options component
const SortBy = ({ columns, rows, setRowData }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSorting = (data) => {
    if (!rows) return
    const sorted = sortRowsByColumn(rows, data, data?.sortBy)
    setRowData([...sorted])
    handleClose();
  }

  return (
    <>
      <Box position='relative'>
        <CustomButton
          startIcon={<AddIcon />}
          id="basic-button"
          sx={sx.sortByButton}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Sort by
        </CustomButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Box display='flex' flexDirection='column' gap='4px' px='10px'>
            {
              columns.map((col, index) => {
                return (
                  <Typography key={index} onClick={() => handleSorting(col)}
                    fontSize='12px'
                    style={{ cursor: "pointer" }}
                  >
                    {col.sortByTitle}
                  </Typography>
                )
              })
            }
          </Box>
        </Menu>
      </Box>
    </>
  )
}

// sort arrow component
const SortList = ({ column, rows, setRowData }) => {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = React.useState(false);

  const handleSorting = () => {
    if (!rows) return
    setSortOrder(!sortOrder);
    const sorted = sortRowsByColumn(rows, column, sortOrder ? "desc" : 'asc')
    setRowData([...sorted])
  }

  React.useEffect(() => {
    if (column?.sort && rows.length > 1) {
      handleSorting()
    }
    else {
      setSortOrder([...rows])
    }
  }, [column, rows])

  return (
    <>
      {
        sortOrder
          ?
          <IconButton size='small' classes={{ root: classes.iconButtonRoot }}>
            <ArrowDownwardIcon onClick={handleSorting} sx={sx.sortIcon} />
          </IconButton>
          :
          <IconButton size='small' classes={{ root: classes.iconButtonRoot }}>
            <ArrowUpwardIcon onClick={handleSorting} sx={sx.sortIcon} />
          </IconButton>
      }
    </>
  )
}

const StaticTable = ({
  width, height, columns, actions, rows, exportRows, forTemplateAnalytics , initialFilters = [], noRowsMessage, sortByColumns = false,
  exportByAttempts = false, showRowSelectCheckbox = false,
  setRowSelectionModel = () => { }, rowSelectionModel = [], loading,
  showColumnSelection = true, showExports = true,
  useItemProps,
  tableHeaderClasses,
  tableBodyClasses,
  ...props
}) => {
  const [filters, setFilters] = React.useState(initialFilters || []);
  const [exportFilters, setExportFilters] = React.useState(initialFilters || []);
  const [rowData, setRowData] = React.useState(rows || []);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    const updatedRows = rows.map(row => 
      row.type === 'personalized' ? { ...row, type: 'mock interview' } : row
    );
    setRowData(updatedRows);
  }, [rows]);
  

  const handleRowSelection = (data) => {
    if (rowSelectionModel?.includes(data._id)) {
      setRowSelectionModel(rowSelectionModel.filter(r => r !== data._id));
    }
    else {
      setRowSelectionModel([...rowSelectionModel, data._id]);
    }
  }

  const handleSelectAll = () => {
    if (rowSelectionModel.length === rowData.length) {
      setRowSelectionModel([]);
    } else {
      const allRowIds = rowData.map((row) => row._id);
      setRowSelectionModel(allRowIds);
    }
  };

  React.useEffect(() => {
    setFilters(initialFilters);
    setExportFilters(initialFilters);
  }, [initialFilters.length])

  const filteredColumns = React.useMemo(() => {
    let allColumns = columns.filter((col) => {
      if (filters.length) {
        return filters.includes(col.field)
      }
      else {
        return col;
      }
    })

    if (showRowSelectCheckbox) {
      allColumns.unshift({
        field: 'select',
        label: (
          <input type='checkbox' name='selectAll'
            checked={rowData.length && rowSelectionModel.length === rowData.length}
            onChange={handleSelectAll}
          />
        ),
        type: 'select',
        align: 'start',
        disableSorting: true,
        minWidth: '20px',
        maxWidth: '20px',
        getActions: (params) => getListActions({
          params, rowSelection: true, rowSelectionModel
        })
      })
    }

    if (actions) {
      allColumns.push({
        field: 'actions',
        label: 'Actions',
        type: "actions",
        align: 'center',
        disableSorting: true,
        minWidth: 100,
        getActions: (params) => getListActions({
          params, customActions: actions,
        }),
      });
    }

    return allColumns;
  }, [filters, columns, actions, rowSelectionModel]);

  React.useEffect(() => {
    let searchString = props?.outerSearchString ? props?.outerSearchString : search;
    let searchedData = rows.filter(data => {
      return searchString?.length
        ? searchStringInString(searchString, data.name)
        : rows
    });
    setRowData([...searchedData])
  }, [search, props?.outerSearchString])

  const handleDeselectFilter = (field) => {
    if (filters.includes(field)) {
      setFilters(filters.filter((data) => data !== field));
    }
  }

  return (
    <Box style={{ width: width || '100%' }}>
      {/* search */}
      {
        showExports ?
          <Box display='flex' justifyContent='space-between' mb='5px' alignItems='center'>
            <DebouncedTextField
              placeholder="Search..."
              startIcon
              onChange={(value) => setSearch(value)}
              width={350}
            />
            <Box display='flex' gap='10px'>
              <ExportList
                columns={columns}
                rows={rows}
                exportRows={exportRows}
                filters={exportFilters}
                setFilters={setExportFilters}
                exportByAttempts={exportByAttempts}
                useItemProps={useItemProps}
                {...props}
                forTemplateAnalytics={forTemplateAnalytics}
              />
              {
                sortByColumns &&
                <SortBy columns={sortByColumns} rows={rows} setRowData={setRowData} />
              }
            </Box>
          </Box>
          : null
      }
      {/* filters */}
      {
        showColumnSelection ?
          <FilterList
            columns={columns}
            filters={filters}
            setFilters={setFilters}
            handleDeselectFilter={handleDeselectFilter}
          />
          : null
      }

      {/* table */}
      {
        <Box style={{ visibility: loading ? 'visible' : 'hidden', padding: '0px 0.5px' }}>
          <LinearProgress />
        </Box>
      }
      <TableContainer sx={{ border: '1px solid #E1E2E3', height: height || 'fit-content' }}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow sx={sx.headerRow} style={{ borderBottom: '2px solid red' }}>
              {
                filteredColumns?.map((col, index) => {
                  return (
                    <>
                      <TableCell
                        align={col?.align || 'center'}
                        key={index}
                        sx={
                          col?.type === 'actions'
                            ? sx.actionColumn
                            : index === 0
                              ? { ...(sx.stickyColumn), left: 0, borderLeft: '2px solid #C1C2C2' }
                              : (index === 1 && showRowSelectCheckbox)
                                ? { ...(sx.stickyColumn), left: '25px' } : null
                        }
                        style={{
                          minWidth: col?.minWidth,
                          maxWidth: col?.maxWidth,
                          ...(sx.tableCell)
                        }}
                      >
                        <Box
                          display='flex' gap='5px'
                          alignItems='center'
                          justifyContent={col?.align || 'start'}
                        >
                          <Typography fontWeight="600" fontSize="16px">{col?.label}</Typography>
                          <span>
                            {!col?.disableSorting &&
                              <SortList column={col} rows={rows} setRowData={setRowData} />
                            }
                          </span>
                        </Box>
                      </TableCell>
                    </>
                  )
                })
              }
            </TableRow>
          </TableHead>
          {
            rowData.length ?
              <TableBody sx={sx.tableBody} className={tableBodyClasses}>
                {
                  rowData.map((item, index) => (
                    <Row
                      key={item.id}
                      rowData={item}
                      rowNo={index}
                      columns={filteredColumns}
                      getActions={actions}
                      handleRowSelection={handleRowSelection}
                      showRowSelectCheckbox={showRowSelectCheckbox}
                      rowSelectionModel={rowSelectionModel}
                    />
                  ))
                }
              </TableBody>
              :
              null
          }
        </Table>
        {
          rowData.length ? null :
            <Box sx={sx.emptyDialogBox} >
              <TableNoRowsOverlay message={noRowsMessage} />
            </Box>
        }
      </TableContainer>
    </Box>
  )
}

export default StaticTable;