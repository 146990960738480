import React from 'react';
import shallow from 'zustand/shallow';
import styled from '@mui/styles/styled';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { steps } from "./constants"
import useEditorStore from './editorStore';
import { Button } from '@mui/material';

const DashedConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderWidth: '0',
      height: '4px',
      backgroundImage: 'linear-gradient(to right, black 0%, black 50%, transparent 50%)',
      backgroundSize: '32px 2px',
      backgroundRepeat: 'repeat-x'
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderWidth: '0',
      height: '4px',
      backgroundImage: 'linear-gradient(to right, black 0%, black 50%, transparent 50%)',
      backgroundSize: '32px 2px',
      backgroundRepeat: 'repeat-x'
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: '0',
    height: '4px',
    backgroundImage: 'linear-gradient(to right, #0000004e 0%, #0000004e 50%, transparent 50%)',
    backgroundSize: '32px 2px',
    backgroundRepeat: 'repeat-x'
  },
}));

function Steps({ onChange }) {
  const active = useEditorStore(state => state.activeStep, shallow);
  const completed = useEditorStore(state => state.stepsCompleted, shallow);

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={active} alternativeLabel connector={<DashedConnector />} >
        {steps.map(({ label }, index) => (
          <Step
            key={label}
            active={index === active}
            completed={!!completed[index]}
            last={index === 2}
          >
            <StepLabel>
              <Button
                variant='outlined'
                disabled={(!(completed[index] && index) && active !== index)}
                style={{
                  textTransform: 'none', border: 'none',
                  borderBottom: (active === index) ? '1px solid #6ebffd' : "none",
                  borderRadius: '0', padding: '0px 6px',
                }}
                onClick={
                  (e) => (completed[index] && index) && onChange(index)
                }
              >
                {label}
              </Button>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default Steps;