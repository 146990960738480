import React from "react";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import Select from "components/Select";
import ShadowBox from "components/ShadowBox";
import StaticTable from "components/Tables/StaticTable";
import { BlueSwitch, PurpleSwitch } from "components/Switch";
import Toggle from "components/Toggle";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningIcon from "@mui/icons-material/Warning";
import MUITooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { getInterviewAttempts, getUserAssessmentAnalytics } from "services";

import { styled } from "@mui/material/styles";
import { calculatePrevAverage, formatDate, getAverageScore, getPercentageChange } from "utils";
import { Button } from "@mui/material";
import CenterFlexBox from "components/CenterFlexBox";
import ImageProctorDialog from "dialogs/ImageProctorDialog";
import { InterviewTemplateTypes } from "editors/TemplateEditor/constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement
);

const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    boxShadow: "5px 3px 13px 0px #4747491A",
    font: "Montserrat",
    padding: "0px 16px ",
    transform: "all 200ms",
    pointerEvents: "none",
    width: 300,
  },
  tooltipHeading: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#586268",
    textTransform: "capitalize",
  },
  tooltipData: {
    padding: "4px 20px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tooltipDataValue: {
    fontWeight: 600,
    fontSize: 20,
  },
  tooltipDataLabel: {
    fontWeight: 500,
    fontSize: 16,
  },
  changeChip: {
    width: 60,
    borderRadius: 12,
    padding: "2px",
    fontWeight: 400,
    fontSize: "12px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  increment: {
    backgroundColor: "#D9F7EC",
    color: "#41D7A1",
  },
  decrement: {
    backgroundColor: "#FFBEBA",
    color: "#DE5454",
  },
  performanceLink: {
    color: "#FFFFFF",
    background: "#02569D",
    borderRadius: 12,
    fontWeight: 400,
    fontSize: "10px",
    padding: "4px 8px",
  },
  emptyState: {
    marginBottom: '10px'
  }
}));

const sx={
  viewBtn: {
    fontSize: '10px',
    padding: '2px 3px',
    color: '#000',
    border: '1px solid #E1E2E3',
    textTransform: 'capitalize',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#02569D',
      color: '#fff'
    }
  }
}

const CustomTooltip = styled(({ className, ...props }) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 8,
    boxShadow: "-4px 4px 12px rgba(0, 0, 0, 0.1)",
    padding: 20,
  },
}));

const ShowProctoringResult = ({
  proctoringRecords = [],
  onViewCapturedImage,
}) => {
  let hasViolation = false;
  let moveAwayCount = 0;
  let tabSwitchCount = 0;
  let hasImageProctor = false;
  let cameraDisabled = 0;

  if (proctoringRecords.length) {
    proctoringRecords.forEach((pr) => {
      if (pr.type === "CAMERA_DISABLED") {
        cameraDisabled++;
      }
      if (pr.type === "IMAGE_PROCTOR") {
        hasImageProctor = true;
      }
      if (pr.type === "GENERAL_PROCTOR") {
        if (pr.moveAwayCount > 0 || pr.tabSwitchCount > 0) {
          hasViolation = true;
          moveAwayCount = pr.moveAwayCount;
          tabSwitchCount = pr.tabSwitchCount;
        }
      }
    });
  }

  const handleViewCapturedImage = () => {
    onViewCapturedImage(proctoringRecords);
  };

  return hasViolation ? (
    <CustomTooltip
      placement="right"
      title={
        <React.Fragment>
          <CenterFlexBox justifyContent="flex-start">
            <WarningIcon color="error" /> &nbsp;&nbsp;
            <Typography variant="body1"> Violation Found </Typography>
          </CenterFlexBox>
          <br />

          <ul style={{ padding: 0, margin: 0, marginLeft: 8 }}>
            {moveAwayCount !== 0 && (
              <li style={{ marginBottom: 4 }}>
                {moveAwayCount} times move away from the test screen.
              </li>
            )}
            {tabSwitchCount !== 0 && (
              <li>{tabSwitchCount} times switch between the tabs.</li>
            )}
            {cameraDisabled !== 0 && (
              <li>{cameraDisabled} times disabled the camera.</li>
            )}
          </ul>

          {hasImageProctor && (
            <>
              <br />
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={handleViewCapturedImage}
                style={{ textTransform: "none" }}
              >
                View Captured image
              </Button>
            </>
          )}
        </React.Fragment>
      }
    >
      <WarningIcon color="error" />
    </CustomTooltip>
  ) : (
    <CustomTooltip
      placement="right"
      title={
        <React.Fragment>
          <CenterFlexBox justifyContent="flex-start">
            <VerifiedIcon color="success" /> &nbsp;&nbsp;
            <Typography variant="body1">No Violation Found </Typography>
          </CenterFlexBox>
          <br />
          <Typography variant="body2">
            User was not found with any violations in the assessment as per the
            guidelines.
          </Typography>

          {hasImageProctor && (
            <>
              <br />
              <Button
                fullWidth
                size="small"
                variant="contained"
                onClick={handleViewCapturedImage}
                style={{ textTransform: "none" }}
              >
                View Captured image
              </Button>
            </>
          )}
        </React.Fragment>
      }
    >
      <VerifiedIcon color="success" />
    </CustomTooltip>
  );
};

const commonProperties = {
  renderCell: ({ row, field }) =>
    row.finishedAttempts === 0 ? "-" : Math.round(row[field]) || 0,
  align: "center",
};

const AssessmentsColumns = [
  { field: "name", label: "Name", type: "string", minWidth: 50, fixed: 1 },
  { field: "type", label: "Type", align: "center", minWidth: 100 },
  { field: "domain", label: "Domain", align: "center", minWidth: 130 },
  {
    field: "publishOn", label: "Published On", minWidth: 150, align: 'center',
    renderCell: ({ row, field }) => {
      return formatDate(row[field], "DD-MM-YYYY")
    }
  },
  {
    field: "overallScore",
    label: "Overall Score",
    type: "number",
    minWidth: 130,
    ...commonProperties,
    sort: true,
  },
  {
    field: "communicationOverall",
    label: "Speech",
    type: "number",
    minWidth: 80,
    ...commonProperties,
  },
  {
    field: "contentOverall",
    label: "Content",
    type: "number",
    minWidth: 80,
    ...commonProperties,
  },
  {
    field: "highestScore",
    label: "Highest score",
    type: "number",
    minWidth: 120,
    align: "center",
    renderCell: ({ row, field }) =>
      row.finishedAttempts === 0
        ? "-"
        : getAverageScore(row.highestCommunication, row.highestContent),
  },
  {
    field: "lowestScore",
    label: "Lowest score",
    type: "number",
    align: "center",
    minWidth: 120,
    renderCell: ({ row, field }) =>
      row.finishedAttempts === 0
        ? "-"
        : getAverageScore(row.lowestCommunication, row.lowestContent),
  },
  {
    field: "finishedAttempts",
    label: "Finished Attempts",
    type: "number",
    minWidth: 160,
    ...commonProperties,
  },
  {
    field: "unfinishedAttempts",
    label: "Unfinished Attempts",
    type: "number",
    align: "center",
    minWidth: 160,
    renderCell: ({ row, field }) => Math.round(row[field]) || "-",
  },
  {
    field: "communicationConfidence",
    label: "Confidence",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
  {
    field: "communicationDelivery",
    label: "Delivery",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
  {
    field: "communicationFluency",
    label: "Fluency",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
  {
    field: "communicationWord_Choice",
    label: "Word choice",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
  {
    field: "contentSimilarity",
    label: "Similarity",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
  {
    field: "contentRelevance",
    label: "Relevance",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
  {
    field: "contentLogic_Flow",
    label: "Logic flow",
    type: "number",
    minWidth: 100,
    ...commonProperties,
  },
];

const ToggleOptions = [
  { id: "assessment", label: "Assessment" },
  { id: "attempt", label: "Attempt" },
];

const AssessmentOptions = [
  { _id: "all", name: "All" },
  { _id: "assignment", name: "Assignment" },
  { _id: "practice", name: "Practice" },
];

function AssessmentAnalytics({ student, selectedBatch }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const { id } = useParams();

  const [filter, setFilter] = React.useState("all");
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [section, setSection] = React.useState("assessment");
  const [templatesAnalytics, setTemplatesAnalytics] = React.useState([]);
  const [templateAttempts, setTemplateAttempts] = React.useState([]);
  const [forceToggle, setForceToggle] = React.useState();
  const [proctoringRecords, setProctoringRecords] = React.useState([]);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const handleViewCapturedImage = (pr) => {
    setProctoringRecords(pr);
    setShowImageModal(true);
  };

  const AttemptsColumns = React.useMemo(
    () => [
      {
        field: "sNo",
        label: "S.No.",
        type: "number",
        minWidth: 50,
        align: "center",
        fixed: 1,
        renderCell: ({ row, field, index }) =>
          index + 1
      },
      {
        field: "proctor",
        label: "Proctor",
        disableExport: true,
        renderCell: ({ row }) => (
          <ShowProctoringResult
            proctoringRecords={row.proctoringRecords}
            onViewCapturedImage={handleViewCapturedImage}
          />
        ),
      },
      {
        field: "date", label: "Date", type: 'date', minWidth: 60,
        renderCell: ({ row, field }) => {
          return formatDate(row[field], "DD-MM-YYYY")
        }
      },
      {
        field: "questionsAttempted",
        label: "Questions attempted",
        minWidth: 150,
        ...commonProperties
      },
      {
        field: "overallScore",
        label: "Overall Score",
        type: "number",
        minWidth: 130,
        ...commonProperties
      },
      {
        field: "communicationOverall",
        label: "Speech",
        type: "number",
        minWidth: 80,
        ...commonProperties
      },
      {
        field: "contentOverall",
        label: "Content",
        type: "number",
        minWidth: 80,
        ...commonProperties
      },
    ],
    []
  );
  const [legendStates, setLegendStates] = React.useState({
    speech: true,
    content: true,
  });

  const assessmentInitialFilters = [
    "name", "type", "domain", "publishOn", "overallScore", "contentOverall",
    "communicationOverall", "finishedAttempts", "unfinishedAttempts"
  ]
  const attemptInitialFilters = [
    "sNo", "proctor", "domain", "date", "questionsAttempted", "overallScore",
    "communicationOverall", "contentOverall"
  ]

  React.useEffect(() => {
    setLoading(true);
    getUserAssessmentAnalytics(id, selectedBatch)
      .then((ta) => {
        setTemplatesAnalytics(ta);
        setRows(ta);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [id, selectedBatch]);

  React.useEffect(() => {
    if (section === "attempt") {
      if (templateAttempts[filter]) {
        setRows(templateAttempts[filter]);
      } else {
        getInterviewAttempts({
          user: id,
          interviewTemplate: filter,
          order: "desc",
          orderBy: "startedAt",
          finishedAt: null
        })
          .then(({ interviewAttempts }) => {
            setTemplateAttempts((ta) => {
              const _templateAttempts = { ...ta };

              _templateAttempts[filter] = interviewAttempts.map((ia, i) => {
                const content = Math.round(ia?.analysis?.content?.ratings?.OVERALL) || 0;
                const comm = Math.round(ia?.analysis.communication.ratings.OVERALL) || 0;

                return {
                  _id: ia._id,
                  name: `Attempt ${i + 1}`,
                  type: "",
                  sNo: i + 1,
                  date: formatDate(ia.startedAt, "DD MMM YYYY"),
                  questionsAttempted: ia.attemptedQuestions.length,
                  overallScore: Math.round((content + comm) / 2),
                  contentOverall: content,
                  communicationOverall: comm,
                  disabledClick: true,
                  proctoringRecords: ia.proctoringRecords || [],
                };
              });

              setRows(_templateAttempts[filter]);

              return _templateAttempts;
            });
          })
          .catch(console.error);
      }
    } else {
      if (filter === "practice" || filter === "assignment") {
        setRows(
          templatesAnalytics.filter((row) => row.type === filter).slice(0, 10)
        );
      } else {
        setRows(templatesAnalytics);
      }
    }
  }, [filter]);

  React.useEffect(() => {
    if (section === "assessment") setFilter("all");
    else {
      if (forceToggle === undefined) {
        setFilter(templatesAnalytics?.[0]?._id || "");
      } else {
        setForceToggle(undefined);
      }
    }
  }, [section]);

  const [datasets, labels] = React.useMemo(() => {
    let _labels = [];
    let dataToConsider = [];
    const commData = [];
    const contentData = [];

    if (section === "assessment") {
      dataToConsider = rows.filter(row=>row.attemptedBy?.includes(id));
      dataToConsider = dataToConsider.slice(
        dataToConsider?.length > 0 ? dataToConsider.length-10 : 0 , dataToConsider.length);

      for (const data of dataToConsider) {
        if (legendStates.speech) commData.push(data.communicationOverall || 0);
        if (legendStates.content) contentData.push(data.contentOverall || 0);

        let label = data.name.substr(0, 8);

        if (data.name.length > 8) label = label.concat("...");

        _labels.push(label);
      }
    } else {
      dataToConsider = rows.slice(0, 10);

      let no = 1;
      for (const data of dataToConsider) {
        if (legendStates.speech) commData.push(data.communicationOverall || 0);
        if (legendStates.content) contentData.push(data.contentOverall || 0);
        _labels.push(no++);
      }
    }

    if (_labels.length < 10) {
      for (let x = _labels.length; x < 10; x++) _labels.push("");
    }

    const _datasets = [];

    if (legendStates.speech) {
      _datasets.push({
        label: "Communication",
        data: commData,
        backgroundColor: "#5856D6",
      });
    }

    if (legendStates.content) {
      _datasets.push({
        label: "Content",
        data: contentData,
        backgroundColor: "#007AFF",
      });
    }

    return [_datasets, _labels];
  }, [rows, section, legendStates]);

  const getTooltip = React.useCallback(
    (context) => {
      const { chart } = context;
      // Tooltip Element
      let tooltipEl = document.getElementById("chartjs-tooltip");

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.classList.add(classes.tooltip);
        chart.canvas.style.position = "relative";
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      tooltipEl.innerHTML = "";

      // Hide if no tooltip
      const tooltipModel = context.tooltip;
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add("no-transform");
      }

      let dataToConsiderA = rows.filter(row=>row.attemptedBy?.includes(id));
      let dataToConsider = dataToConsiderA.slice(
        dataToConsiderA?.length > 0 ? dataToConsiderA.length-10 : 0 , dataToConsiderA.length)
      
      const dataIndex = tooltipModel.dataPoints?.[0].dataIndex;
      const data = dataToConsider[dataIndex];
      const avgUpto = dataToConsiderA.length > 10 
                      ? dataIndex+(dataToConsiderA.length)-10 : dataIndex;

      const dataToConsiderForAvg = dataToConsiderA.filter(
        (row,i) => (row.type !== InterviewTemplateTypes.FOLLOW_UP && i < avgUpto))
      
      const prevData = calculatePrevAverage(
        dataToConsiderForAvg, 
        avgUpto
      );

      let contentChange, commChange, contPC, commPC;

      if (prevData) {
        contentChange = data.contentOverall - prevData.contentOverall;
        commChange = data.communicationOverall - prevData.communicationOverall;
  
        contPC = prevData.contentOverall === 0 ? 0 :
          getPercentageChange(data.contentOverall, prevData.contentOverall);
        commPC = prevData.communicationOverall === 0 ? 0 :
          getPercentageChange(data.communicationOverall, prevData.communicationOverall);
      }

      const heading = document.createElement("p");
      heading.className = classes.tooltipHeading;
      heading.innerHTML =
        section === "assessment"
          ? `${data?.name} (${data?.type?.toUpperCase()})`
          : `${data?.name}`;

      tooltipEl.appendChild(heading);

      const dataContainer = document.createElement("div");
      dataContainer.style.width = "100%";

      let innerHTML = `
      ${dataToConsider[dataIndex]?.attemptedBy?.includes(id) ? 
        `
    <div class='${classes.tooltipData}'>
    <div class='${classes.tooltipDataLabel}'>Speech</div>
    <div class='${classes.tooltipDataValue}'>${
        data.communicationOverall || 0
      }</div>
    <div>
    ${dataToConsider[dataIndex].type !== InterviewTemplateTypes.FOLLOW_UP ?
      `
      <div class='${classes.changeChip} ${
        commChange < 0 ? classes.decrement : classes.increment
      }'>
        ${!dataToConsider[dataIndex]?.attemptedBy?.includes(id) || (Math.abs(commPC) || 0) === 0 || 
          dataToConsider[dataIndex].type === InterviewTemplateTypes.FOLLOW_UP
          ? "" : Math.abs(commPC) + '%'
        }&nbsp;&nbsp;<sup>
        ${(!dataToConsider[dataIndex]?.attemptedBy?.includes(id) || 
          (dataIndex === 0 && dataToConsiderA.length<10 && 
            rows.filter(row=>row.attemptedBy?.includes(id).length > 0)) || 
          dataToConsider[dataIndex]?.type === InterviewTemplateTypes.FOLLOW_UP 
          || !dataToConsiderForAvg.length)
          ? "-" : commChange < 0 ? "&darr;" : (commChange > 0 ? "&uarr;" : "&dash;")
        }
        </sup>
      </div>`
      : '<div></div>'
      }
    </div>
    </div>
    <div class='${classes.tooltipData}'>
    <div class='${classes.tooltipDataLabel}'>Content</div>
    <div class='${classes.tooltipDataValue}'>${data.contentOverall || 0}</div>
    <div>
    ${dataToConsider[dataIndex].type !== InterviewTemplateTypes.FOLLOW_UP ?
      `
      <div class='${classes.changeChip} ${
        contentChange < 0 ? classes.decrement : classes.increment
      }'>
        ${!dataToConsider[dataIndex]?.attemptedBy?.includes(id) || (Math.abs(contPC) || 0) === 0 ||
          dataToConsider[dataIndex]?.type === InterviewTemplateTypes.FOLLOW_UP
          ? "" : Math.abs(contPC) + '%'
        }&nbsp;&nbsp;<sup>
        ${(!dataToConsider[dataIndex]?.attemptedBy?.includes(id) || 
          (dataIndex === 0 && dataToConsiderA.length<10) || 
          dataToConsider[dataIndex]?.type === InterviewTemplateTypes.FOLLOW_UP 
          || !dataToConsiderForAvg.length)
          ? "-" : contentChange < 0 ? "&darr;" : (contentChange > 0 ? "&uarr;" : "&dash;")
        }
        </sup>
      </div>
      </div>`
      : `<div></div>`
    }
    </div>
    </div>`
    : 
    `<div class='${classes.emptyState}'>
      <div>
        <img src='https://assets.languify.in/images/noAttempts.svg' alt='no attempts yet'/>
      </div>
      <div> No Attempts yet</div>
    </div>`
    }
    `;

      dataContainer.innerHTML = innerHTML;

      tooltipEl.appendChild(dataContainer);

      const { offsetLeft: positionX, offsetTop: positionY } =
        context.chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.zIndex = 10000000000;
      tooltipEl.style.left = positionX + tooltipModel.caretX - 250 + "px";
      tooltipEl.style.top = positionY + tooltipModel.caretY - 140 + "px";
    },
    [rows, classes, section]
  );

  const GraphOptions = {
    responsive: true,
    aspectRatio: 5,
    maintainAspectRatio: true,
    interaction: { mode: "index", intersect: false },
    // onClick: handleClick,
    plugins: {
      legend: { display: false },
      title: { display: false, text: "Performance" },

      tooltip: { enabled: false, position: "nearest", external: getTooltip },
    },
    scales: { yAxis: { min: 0, max: 100 } },
  };

  const handleToggleChange = (option) => setSection(option.id);

  const handleFilterChange = (e) => setFilter(e.target.value);

  const handleLegendChange = (event) => {
    setLegendStates((ls) => ({
      ...ls,
      [event.target.name]: event.target.checked,
    }));
  };

  const _rows = React.useMemo(() => {
    const allRows = rows?.map((rowData) => {
      return {
        ...rowData,
        overallScore: getAverageScore(rowData.communicationOverall, rowData.contentOverall),
        disable: !rowData.attemptedBy?.includes(id)
      }
    })

    return allRows
  }, [rows, section])

  const getActions = [
    {
      title: "View Performance",
      renderIcon: (param)=> <Button sx={sx.viewBtn} disabled={ param?.row?.disable || false}>
      <RemoveRedEyeIcon style={{padding:'1px', marginRight: '5px', fontSize: '20px' }}/>
        View
      </Button>,
      label: "View Performance",
      onClick: (param) => navigate(`/students/performance?uid=${id}&tid=${param.id}`)
    }
  ]

  return (
    <>
      <Box display="flex" gap={2} alignItems="center" my={2}>
        <Typography
          variant="h6"
          fontWeight={700}
          fontSize={14}
          color="#000000CC"
        >
          View By
        </Typography>
        <Toggle
          forceChange={forceToggle}
          onChange={handleToggleChange}
          br={1}
          width={250}
          height={32}
          TypographyProps={{
            textTransform: "uppercase",
            fontWeight: 600,
          }}
          options={ToggleOptions}
        />
      </Box>

      <ShadowBox sx={{ m: 0, width: '100%' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          py={1}
          alignItems="center"
        >
          <Box>
            <Typography fontSize={30} fontWeight={600}>
              Users Performance
            </Typography>
            <Typography fontSize='14px'>
            {
              section === "assessment" 
              ? "The graph tooltip shows changes in values for each assessment, comparing them with the cumulative average, considering all prior practice assessments and assignments"
              : "Select any assessment from the drop-down menu on the right to compare your performance attempt-wise against the average of your previous attempts."
            }
            </Typography>
          </Box>
          {section === "assessment" ? (
            <Select
              label="Assessment Type"
              options={AssessmentOptions}
              value={filter}
              onChange={handleFilterChange}
            />
          ) : (
            <Select
              label="Select Assessment"
              options={templatesAnalytics}
              value={filter}
              onChange={handleFilterChange}
            />
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            background: "#FFFFFF",
            border: "1px solid lightgrey",
            borderRadius: 2,
            padding: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box textAlign="right">
            <FormControlLabel
              control={
                <PurpleSwitch
                  name="speech"
                  checked={legendStates.speech}
                  onChange={handleLegendChange}
                  sx={{ "& .track": { background: "#007AFF" } }}
                />
              }
              label="Speech"
            />{" "}
            &nbsp;&nbsp;
            <FormControlLabel
              control={
                <BlueSwitch
                  name="content"
                  checked={legendStates.content}
                  onChange={handleLegendChange}
                />
              }
              label="Content"
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              fontSize={15}
              color={"#666666"}
              component="div"
              style={{ transform: "rotate(-90deg)" }}
            >
              Score
            </Typography>
            <div style={{ flexGrow: 1 }}>
              <Bar options={GraphOptions} data={{ labels, datasets }} />
            </div>
          </Box>
          <div>
            <Typography fontSize={15} mt={1} color={"#666666"}>
              {section === "assessment" ? "Assessments" : "Attempts"}
            </Typography>
          </div>
        </Box>
        <br />

        <StaticTable
          columns={section === "assessment" ? AssessmentsColumns : AttemptsColumns}
          loading={loading}
          rows={_rows}
          actions={section === "assessment" ? getActions : null}
          initialFilters={section === "assessment" ? assessmentInitialFilters : attemptInitialFilters}
          noRowsMessage="No assessments taken by the user!!"
          sortByColumns={
            section === 'assessment' ?
              [
                { ...AssessmentsColumns[4], sortByTitle: "Scores: High to Low", sortBy: 'desc' },
                { ...AssessmentsColumns[4], sortByTitle: "Scores: Low to High", sortBy: 'asc' },
                { ...AssessmentsColumns[9], sortByTitle: "Engagement: High to Low", sortBy: 'desc' },
                { ...AssessmentsColumns[9], sortByTitle: "Engagement: Low to High", sortBy: 'asc' }
              ]
              :
              [
                { ...AttemptsColumns[4], sortByTitle: "Scores: High to Low", sortBy: 'desc' },
                { ...AttemptsColumns[4], sortByTitle: "Scores: Low to High", sortBy: 'asc' },
              ]
          }
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          useItemProps={{
            name: student?.name,
            exportService: null,
            payload: null
          }}
        />
      </ShadowBox>
      <ImageProctorDialog
        open={showImageModal}
        proctoringRecords={proctoringRecords}
        onClose={() => setShowImageModal(false)}
      />
    </>
  );
}

export default AssessmentAnalytics;
