import React from 'react';

import { useNavigate } from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';

import { useSnackbar } from 'contexts';
import { ConfirmationDialog } from 'dialogs';
import { deleteDraftById, getDrafts } from 'services';
import { Session, formatDate } from 'utils';
import StaticTable from 'components/Tables/StaticTable';
import { Typography } from '@mui/material';

const columns = [
  { field: 'name', label: 'Name', minWidth: 100 },
  {
    field: 'domain', align: 'center',
    label: 'Difficulty',
    renderCell: ({ row }) => {
      return <Typography style={{ textTransform: 'capitalize' }}>
        {row?.data?.TEMPLATE_FILTERS?.difficulty}
      </Typography>
        || '-'
    }
  },
  {
    id: 'updatedAt', align: 'center',
    field: 'updatedAt',
    label: 'Last Edited',
    minWidth: 150,
    renderCell: ({ row }) => formatDate(row.updatedAt, "HH:mm A, DD-MM-YYYY")
  },
];

function TemplateDrafts(props) {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [activeItemId, setActiveItemId] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const getActions = [
    {
      title: 'Edit',
      renderIcon:()=> <EditIcon />,
      label: "Edit",
      onClick: (param) => navigate(`draft/${param.id}`)
    },
    {
      title: 'Delete',
      renderIcon:()=> <DeleteIcon color='error' />,
      label: "Delete",
      onClick: (param) => setActiveItemId(param.id),
    }
  ];

  const deleteDraft = React.useCallback(async () => {
    const itemId = activeItemId;

    try {
      await deleteDraftById(itemId);

      snackbar.success("Successfully deleted the draft!!");
    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    }

    setRows(currRows => currRows.filter((v) => itemId !== v._id));

    setActiveItemId(false);
  }, [activeItemId]);

  React.useEffect(() => {
    setLoading(true);
    getDrafts({type:"general",orderBy: "updatedAt", order: "desc", user: Session.userId})
      .then(({ drafts }) => setRows(drafts))
      .catch((error) => snackbar.error("Unable to load drafts!"))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Paper elevation={0} style={{ flexGrow: 1, padding: '10px 15px' }}>
        <StaticTable
          height='70vh'
          columns={columns}
          loading={loading}
          rows={rows}
          actions={getActions}
          noRowsMessage="No Drafts available"
          showColumnSelection={false}
          showExports={false}
        />
      </Paper>
      <ConfirmationDialog
        open={!!activeItemId}
        onPrimaryClick={deleteDraft}
        onSecondaryClick={() => setActiveItemId(false)}
        primaryActionVariant="error"
        primaryAction='Yes, Delete!'
        secondaryAction='No'
        message={<>Are your sure, you want to delete this draft?</>}
        title='Delete Draft'
      />
    </>
  );
}

export default TemplateDrafts;