import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import ResetIcon from '@mui/icons-material/Replay';

const useStyles = makeStyles(theme => ({
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    minWidth: "45%",
    marginLeft: 16,
    marginRight: 16,
  },
  sliderLabel: {
    minWidth: 65,
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}));

function SlideControl({ label, onChange, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.sliderContainer}>
      <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
        {label}
      </Typography>
      <Slider
        aria-labelledby={label}
        classes={{ root: classes.slider }}
        onChange={(e, value) => onChange(value)}
        {...props}
      />
      <IconButton
        size="small"
        title="Reset"
        onClick={() => onChange(props.defaultValue)}
      >
        <ResetIcon color="primary" />
      </IconButton>
    </div>
  );
}

export default SlideControl;