import * as React from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import AutoComplete from 'components/Autocomplete';
import { getBatches } from 'services';
import { Session } from 'utils';
import FieldWrapper from './FieldWrapper';
import theme from "theme";

function BatchSelection({ value, onChange, error, helperText, readOnly }) {
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  const [options, setOptions] = React.useState([])

  React.useEffect(() => {
    getBatches({ client: Session.userId })
      .then(({ batches }) => setOptions(batches))
      .catch(console.error);
  }, []);

  const handleChange = (e, option) => onChange({
    target: { name: "batch", value: option.id }
  });

  React.useEffect(() => {
    const batchId = searchParams.get('batchId')
    if (batchId && id === 'create') {
      handleChange(null, { id: batchId });
    }
  }, [searchParams, id]);

  return (
    <FieldWrapper
      title="Select batch"
      description=""
      width="unset"
      px={theme.spacing(0)}
      pb={theme.spacing(0)}
    >
      <AutoComplete
        readOnly={readOnly}
        options={options.map(o => ({ id: o._id, option: o.name }))}
        value={value}
        onChange={handleChange}
        defaultValue={value}
        size={580}
        error={error}
        helperText={helperText}
      />
    </FieldWrapper >
  );
}

export default BatchSelection;