import Client from "./_client";

export const getTemplateTags = async (tagFilters = {}) => {
    let url = "/templateTag?";

    Object.keys(tagFilters).forEach((key) => {
        if (tagFilters[key]) url += `${key}=${tagFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get tags. Please try again."
        );
    }

    return result?.data;
};
