import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Autocomplete from 'components/Autocomplete';
import ButtonMenu from 'components/ButtonMenu';
import CustomButton from 'components/CustomButton';
import DebouncedTextField from './DebouncedTextField';
import CenterFlexBox from './CenterFlexBox';
import { isArray } from 'lodash';
import theme from 'theme';

const useStyles = makeStyles({
    filterTagsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        width: "fit-content",
        gap: "6px",
        marginRight: 16,
        marginLeft: 16,
    },
    tag: {
        height: "28px",
        borderRadius: "6px",
        fontFamily: "inter",
        fontWeight: "500",
    }
});

const FilterTags = ({ tags = [], onRemove = () => { } }) => {
    const classes = useStyles();

    const handleDelete = (tag) => (e) => {
        tag.delete = true;
        onRemove(tag);
    };

    return (
        <Box className={classes.filterTagsContainer}>
            {tags.map((tag) => !tag.delete ? (
                <Chip
                    size="small"
                    color="primary"
                    variant="outlined"
                    key={tag.id}
                    className={classes.tag}
                    label={tag.label}
                    onDelete={handleDelete(tag)}
                />
            ) : null)}
        </Box>)
}

export default function Filter({
    filters = [], onChange = () => { }, filterOptions = {}, appliedFilters = {}, personalizedList,
    children
}) {

    const [anchorEl, setAnchorEl] = useState(null);

    const [tags, setTags] = useState([]);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFilterChange = (filter) => (v, option) => {
        const isSelect = filter.type === 'select';

        if (isSelect && !option) return;

        const value = isSelect ? option.id : v;

        onChange({ ...filter, value });
    };

    const handleFilterRemove = (tag) => {
        onChange({ field: tag.id, value: undefined });
    };

    const handleClearAllFilters = () => {
        filters.forEach(filter => {
            onChange({ field: filter.field, value: undefined });
        });
        handleClose();
    };

    useEffect(() => {
        const filterEntries = Object.entries(appliedFilters);

        if (filterEntries.length === 0) setTags([]);
        else {
            setTags(
                filters.filter(
                  f => (
                    !!appliedFilters[f.field] && 
                    !(isArray(appliedFilters[f.field]) && appliedFilters[f.field].length > 1)
                  )
              ).map(({ field, label, type, ...f }) => {
                    let v = appliedFilters[field];

                    if (type === "select") {
                        v = filterOptions[field]?.find(fo => fo.id === v)?.label;
                    }

                    return { ...f, id: field, label: `${label}: ${v}` };
                }));
        }
    }, [appliedFilters, filterOptions]);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            mx={0}
            mt={2}
            mb={0}
            backgroundColor="#e8f0fe42"
            border="1px solid lightgrey"
            borderRadius={1}
        >
            {children}
            { filters.length > 0 && (
                <CenterFlexBox justifyContent="flex-end">
                    <FilterTags tags={tags} onRemove={handleFilterRemove} />
                    <CustomButton
                        disableElevation
                        id="scheduled-button"
                        variant="outlined"
                        aria-controls={open ? "scheduled-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        startIcon={<AddRoundedIcon />}
                        sx={{
                        }}
                    >
                        Add&nbsp;Filter
                    </CustomButton>
                    <ButtonMenu
                        id="scheduled-menu"
                        MenuListProps={{ "aria-labelledby": "scheduled-button" }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                       <Box sx={{padding:theme.spacing(2,0) , display:'flex' , flexDirection:'column' , 
                            gap:'12px'}}>
                            <Box sx={{display:'flex' , justifyContent:'flex-end' , 
                                padding:theme.spacing(0,2.5)}}>
                                <Typography  
                                    onClick={handleClearAllFilters}
                                    sx={{fontSize:'16px' , fontWeight:700 , textDecoration:'underline',
                                        cursor:'pointer'}} 
                                    color='#1961BF'>Clear all filter</Typography>
                            </Box>
                       {filters.map((filter, index) => (
                            <MenuItem disableRipple key={index} dense sx={{ px: 2, py: 0.5 }}>
                                <div style={{ minWidth: 100 }}>
                                    {filter.label}
                                </div>&nbsp;&nbsp;
                                {filter.type === 'select' ? (
                                    <Autocomplete
                                        name={filter.field}
                                        size={200}
                                        options={filterOptions?.[filter.field] || []}
                                        openOnFocus={true}
                                        onChange={handleFilterChange(filter)}
                                        value={appliedFilters[filter.field] || 'na'}
                                    />
                                ) : (
                                    <DebouncedTextField
                                        name={filter.field}
                                        type={filter.type}
                                        placeholder={filter.placeholder || 'Search...'}
                                        onChange={handleFilterChange(filter)}
                                    />
                                )}

                            </MenuItem>
                        ))}
                       </Box>
                    </ButtonMenu>
                </CenterFlexBox>
            )}
        </Box>
    );
}
