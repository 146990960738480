import * as React from 'react';
import { styled } from '@mui/styles';
import MUIAutocomplete from '@mui/material/Autocomplete';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';

import TextField from 'components/TextField';

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: "8px",
        marginTop: 10,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: "#F1F2F6",
        padding: "0px 10px",

        [`& .${autocompleteClasses.groupUl}`]: {},
        [`& .${autocompleteClasses.groupLabel}`]: {
            backgroundColor: "#F1F2F6",
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            color: '#999999',
        },

        [`& .${autocompleteClasses.option}`]: {
            fontSize: 12,
            fontFamily: 'inter',
            fontWeight: 500,
            margin: "10px 0px",
            "&:hover": {
                backgroundColor: '#FFFFFF',
                borderRadius: '4px',
            },
            '&[aria-selected="true"]': {},
            [`&.${autocompleteClasses.focused},
       &.${autocompleteClasses.focused}[aria-selected="true"]`]: {},
        }
    },
});

const defaultOption = { id: 'na', option: 'Select' };

export default function AutoComplete({
    defaultValue = 'na',
    options = [],
    value,
    onChange = () => { },
    size = 250,
    label = '',
    helperText,
    readOnly,
    ...props
}) {
    const [_value, setValue] = React.useState(defaultValue);

    const handleChange = (e, newValue) => {
        onChange(e, newValue);
        setValue(newValue?.id);
    };

    return (
        <>
            <MUIAutocomplete
                readOnly={readOnly}
                disableClearable
                options={options}
                value={options.find((option) => (
                    value || _value) !== undefined &&
                    option?.id === (value || _value)) || defaultOption
                }
                PopperComponent={StyledPopper}
                groupBy={(option) => option.group}
                getOptionLabel={(option) => option.option || option.label}
                sx={{ width: size }}
                size='small'
                onChange={handleChange}
                renderInput={(params) =>
                    <TextField {...params}
                        label={label}
                        onKeyDown={(e) => e.stopPropagation()}
                        style={{ background: '#F1F2F6', borderColor: 'red' }}
                    />
                }
                {...props}
            />
            {(helperText) && <span style={{
                color: '#e7574a', marginLeft: 15, fontSize: 12,
            }}>
                {helperText}
            </span>}
        </>
    );
}