const { CommentAndColor } = require("./constants");

export const getCommentAndColor = (score) => {
    if (score >= 81) return CommentAndColor.VERY_GOOD;
    if (score >= 61) return CommentAndColor.GOOD;
    if (score >= 41) return CommentAndColor.FAIR;
    if (score >= 21) return CommentAndColor.NOT_OKAY;

    return CommentAndColor.POOR;
};
