import React from 'react';

import { useNavigate, useParams } from "react-router-dom";

import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import ActionButtons from 'components/ActionButtons';
import ScreenHeading from "components/ScreenHeading";
import TextField from 'components/TextField';
import { BeforeLeave } from 'dialogs';
import StudentList from './StudentList';
import TemplateList from './TemplateList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useLoading, useSnackbar } from 'contexts';
import { useNavigatingAway } from 'hooks';
import { getBatchById, saveBatch } from 'services';
import { Session, copyToClipboard } from 'utils';
import { IconButton } from '@mui/material';

function Batch(props) {
  const navigate = useNavigate()
  const { id } = useParams();
  const snackbar = useSnackbar();
  const loading = useLoading();
  const [batch, setBatch] = React.useState({});
  const [activeStudents, setActiveStudents] = React.useState(0);
  const [activeAssessment, setActiveAssessment] = React.useState(0);
  const [canShowBeforeLeave, setCanShowBeforeLeave] = React.useState(false);
  const [
    showDialogLeavingPage, confirmNavigation, cancelNavigation
  ] = useNavigatingAway(canShowBeforeLeave);


  React.useEffect(() => {
    if (id && id !== "create") {
      getBatchById(id).then((batch) => setBatch(batch))
        .catch(error => snackbar.showSnackbar(error.message, "error"))
        .then(console.error)
    }
  }, []);

  const handleSave = async () => {
    try {
      setCanShowBeforeLeave(false);
      loading.show();

      const savedBatch = await saveBatch(id !== "create" && id, {
        name: batch.name,
        client: Session.getUser()._id,
      });

      setBatch(savedBatch);
      navigate(`/batches/${savedBatch._id}`);

      snackbar.showSnackbar("Saved batch!!");
    } catch (error) {
      snackbar.showSnackbar(error.message, "error");
    } finally {
      loading.hide();
    }
  };

  const handleDiscard = async () => {
    setCanShowBeforeLeave(false);
    navigate(-1);
  };

  const onStudentCountChange = (newCount) => {
    setBatch(b => ({ ...b, studentCount: newCount }));
  };

  const onTemplateCountChange = (newCount) => {
    setBatch(b => ({ ...b, templateCount: newCount }));
  };

  return (
    <Box display="flex" flexDirection="column" minHeight='calc(100vh - 76px)' pb={6}>
      <BeforeLeave
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowBeforeLeave}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <ScreenHeading title={id === "create" ? "Create Batch" : "Batch Editor"} />

      <Paper elevation={0} style={{ flexCollapse: 1 }}>
        {batch?._id && <Box display='flex' ml={2} mt={2} alignItems='center'>
          <Typography fontWeight={600} fontSize={15}>
            Batch Id:
          </Typography> &nbsp;

          <Typography fontWeight={500} fontSize={15} color='primary'>
            {batch?._id}
          </Typography>
          <IconButton onClick={() => {
            copyToClipboard(batch?._id);
            snackbar.success('Copied!');
          }}>
            <ContentCopyIcon fontSize='small' />
          </IconButton>
        </Box>}

        <Box display="flex" alignItems="center" p={2} width="100%">
          <TextField
            placeholder="Name your batch..."
            style={{ maxWidth: 300, marginRight: 16 }}
            value={batch?.name || ''}
            onChange={e => setBatch((b) => ({ ...b, name: e.target.value }))}
          />
          <ActionButtons
            primaryFirst
            onPrimaryClick={handleSave}
            onSecondaryClick={handleDiscard}
            primaryAction="Save"
            secondaryAction="Discard"
          />
          {id !== "create" && (
            <Box ml="auto" display="flex" gap={1} alignItems="center">
              <PersonIcon fontSize="small" style={{ color: "#747575" }} />
              <Typography fontWeight={600} fontSize={16} color="#747575">
                {activeStudents} Active Students
              </Typography>
              <Divider variant="middle" orientation='vertical' flexItem sx={{ my: '2px' }} />
              <AssessmentIcon fontSize="small" style={{ color: "#747575" }} />
              <Typography fontWeight={600} fontSize={16} color="#747575">
                {activeAssessment} Assessments
              </Typography>
            </Box>
          )}
        </Box>
        {/* <Collapse in={id !== "create" && !!batch?.joiningLink}>
          {InviteLink({ id, joiningLink: batch?.joiningLink })}
        </Collapse> */}
        <Collapse in={id !== "create"}>
          {StudentList({ onStudentCountChange, setActiveStudents })}
        </Collapse>
        <Collapse in={id !== "create"}>
          {TemplateList({ onTemplateCountChange, setActiveAssessment })}
        </Collapse>
        <br />
      </Paper>
    </Box>
  );
}

export default Batch;