import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 320,
        height: 'fit-content',
        border: "2px solid #49B6FF",
        borderRadius: 4,
        padding: 10,
    },
    tip: {
        padding: "5px 3px",
        fontSize: 12,
        fontWeight: 600,
        color: "var(--secondary-900, #17A2FF)",
        display: "flex",
        gap: 8,
        borderRadius: "0px 0px 12px 12px",
        marginTop: "0px",
    },
    title: {
        fontStyle: "normal",
        fontWeight: 600,
        textAlign: "center",
        fontSize: 15,
        display: "flex",
        alignItems: "center",
        color: "#003366",
    },
    subtitle: {
        fontSize: 13,
        lineHeight: '15px',
        fontWeight: 500,
        margin: "8px 0px",
    },
    progressBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: "70px",
        height: "10px",
        gap: "3px"
    },
    progressBox: {
        height: "11px",
        width: "11px",
        borderRadius: '4px'
    },
});

const sx = {
    subheadings: {
        color: "#02569D",
        fontSize: "13px",
        fontWeight: "600",
        mb: "2px"
    },
}

export function ValueLabelComponent(props) {
    const { children, value } = props;

    return (
        <Tooltip enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

export const CustomSlider = styled(Slider)({
    color: '#02569D',
    height: 8,
    '& .MuiSlider-rail': {
        background: "linear-gradient(90deg, #FF2D55 0%, #FFCC00 50%, #41D7A1 100%)",
        opacity: 1
    },
    '& .MuiSlider-track': {
        border: 'none',
        background: "transparent",
        opacity: 1,
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#02569D',
        border: '4px solid white',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
});

export function ArrayParameter({ data = [] }) {
    return (Array.isArray(data) && data.length > 0) ? (
        <Box
            display="inline-flex"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
            my={1}
        >
            <Box>
                <Typography sx={sx.subheadings}>
                    Your Repeated Mistakes
                </Typography>
                {data?.map((value, i) => (
                    <Box
                        key={i}
                        display="inline-block"
                        fontSize={14}
                        fontWeight={500}
                        color="#02569D"
                        border="1px solid #02569D"
                        borderRadius={1}
                        p={"2px 8px"}
                        mr="5px"
                    >
                        {
                            value?.word ?
                                <>
                                    {value.word}
                                    <span style={{ color: "#FF2D55" }}>{" x" + value.count}</span>
                                </>
                                :
                                <>
                                    {value}
                                </>
                        }
                    </Box>
                ))}
            </Box>
        </Box>
    ) : null;
}

export function LinearBoxesProgressBar({ data, tags = true }) {
    const classes = useStyles();
    const value = data || 0;
    const level = value >= 7 ? "High" : value >= 4 ? "Average" : "Low";
    const color = value >= 7 ? "#19C10A" : value >= 4 ? "#FFD600" : "#F80505";

    return (
        <Box mt="-4px">
            {
                tags ?
                    <Box
                        py="4px"
                        fontSize={10}
                        fontWeight={600}
                    >
                        {level}
                    </Box>
                    : null
            }
            <Box className={classes.progressBar}>
                {[1, 2, 3, 4, 5].map((boxLevel, index) => (
                    <Box
                        className={classes.progressBox}
                        backgroundColor={boxLevel > value / 2 ? "#A3A3A3" : color}
                        borderLeft={
                            ((boxLevel === parseInt((value / 2)) + 1) && (value % 2) > 0)
                                ? `6px solid ${color}` : ""
                        }
                    >
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
