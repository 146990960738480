import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from 'components/TextField';
import { useState } from 'react';
import * as Yup from 'yup';

import { Button } from '@mui/material';
import { useSnackbar } from 'contexts';
import { useFormik } from 'formik';
import { generateResetPasswordLink } from 'services';

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

export default function ResetLink() {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const snackbar = useSnackbar();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);

                await generateResetPasswordLink(formik.values.email);

                snackbar.showSnackbar('Successfully sent the reset link', 'success', false);
                setSent(true);
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error', false);
            } finally {
                setLoading(false);
            }
        }
    });

    return (
        <Box width='60%'>
            <Typography
                color='black' fontFamily='inter'
                fontWeight={600} fontSize={32} mb={4}
            >
                Forgot password?
            </Typography>
            <form onSubmit={formik.handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    value={formik.values.email}
                    spellCheck="false"
                    onChange={formik.handleChange}
                    error={formik.touched.email && formik.errors.email}
                    helperText={(formik.touched.email && formik.errors.email)}
                />

                <Typography
                    fontFamily='inter' alignSelf='flex-end' style={{ cursor: 'pointer' }}
                    fontSize={14} fontWeight={500} color='primary' onClick={() => formik.submitForm()}
                >
                    Didn't recieve? resend

                </Typography>

                <Box mt={4}>
                    <Button
                        variant='outlined' LinkComponent='a' href='/auth/login'
                        style={{ width: 100, fontSize: 12, textTransform: 'none' }}>
                        Go back
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <LoadingButton
                        sx={{ textTransform: "none", width: 100 }}
                        loading={loading}
                        variant="contained"
                        type='submit'
                        size="small"
                        disabled={sent}
                    >
                        Send
                    </LoadingButton>
                </Box>
            </form>
        </Box>
    );
}