import Client from "./_client";
import { getDomains } from "./domains";
import { getUsers } from "./users";

export const getTemplates = async (templateFilters = {}) => {
    let url = "/interviewTemplate?";

    Object.keys(templateFilters).forEach((key) => {
        if (templateFilters[key] !== undefined) url += `${key}=${templateFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "Unable to fetch templates!");

    const filteredTemplates = result.data.interviewTemplates.filter(
        (template) => template.type !== 'pathway'
    );

    return { ...result.data, interviewTemplates: filteredTemplates } || [];
}

export const getTemplateById = async (templateId, query = {}) => {
    let url = `/interviewTemplate/${templateId}?`;

    Object.keys(query).forEach(key => {
        if (query[key] !== undefined) url += `${key}=${query[key]}&`
    });

    let result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get template. Please try again."
        );
    }

    return result.data;
}

export const createTemplate = async (template) => {

    let url = `/interviewTemplate`;

    let result = await new Client({
        path: url,
        payload: template,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save template. Please try again."
        );
    }

    return result.data;
}

export const updateTemplateById = async (templateId, template) => {

    let url = `/interviewTemplate/${templateId}`;

    let result = await new Client({
        path: url,
        payload: template,
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to udpate template. Please try again."
        );
    }

    return result.data;
}

export const deleteTemplateById = async (templateId) => {

    let url = `/interviewTemplate/${templateId}`;

    let result = await new Client({
        path: url
    }).delete();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to delete template. Please try again."
        );

    return result.data;
}

export const archiveTemplateById = async (templateId) => {

    let url = `/interviewTemplate/archive/${templateId}`;

    let result = await new Client({
        path: url
    }).patch();

    if (!(result.ok || result.code === 200))
        throw new Error(
            result.message || "Uh Oh! Unable to archive template. Please try again."
        );

    return result.data;
}

export const getTemplateWithQuestionsById = async (templateId) => {
    let url = `/interviewTemplate/bulk/${templateId}`;
    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get template with questions. Please try again."
        );
    }
    if (result.name === "DuplicateSession") {
        return result;
    } else {
        return result.data;
    }
};

export const saveTemplateWithQuestions = async (templateId, interviewTemplate, questions) => {
    let result;
    if (templateId) {
        result = await new Client({
            path: `/interviewTemplate/bulk/${templateId}`,
            payload: {
                interviewTemplate: {
                    ...interviewTemplate,
                },
                questions
            },
        }).patch();
    } else {
        result = await new Client({
            path: `/interviewTemplate/bulk`,
            payload: {
                interviewTemplate,
                questions
            },
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save template with questions. Please try again."
        );
    } else {
        return result.data;
    }
};

export const getTemplateFormOptions = async () => {
    const { interviewDomains } = await getDomains();
    const { users } = await getUsers({
        roleNames: ["CLIENT"],
    })

    return {
        domains: interviewDomains,
        clients: users,
    };
};

export const exportTemplates = async (templateIds, clientId, sep) => {
    let result = await new Client({
        path: `/${sep ? "sepTemplate" : "interviewTemplate"}/bulk/export`,
        payload: {
            templateIds,
            clientId
        },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to export template with questions. Please try again."
        );
    } else {
        return result.data;
    }
}

export const duplicateTemplate = async (templateId, interviewTemplate) => {
    const result = await new Client({
        path: `/interviewTemplate/${templateId}/duplicate`,
        payload: interviewTemplate,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to duplicate template. Please try again!!"
        );
    } else {
        return result.data;
    }
};

export const getFollowUpStudents = async (templateIds = []) => {
    let url = `/interviewTemplate/follow-up/eligible-students`;

    let templateString = templateIds.reduce((prev, el) => {
        return prev + el + ',';
    }, '');
    if (templateString) {
        templateString = templateString.substring(0, templateString.length - 1);
        url += `?templates=${templateString}`;
    }
    const result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to duplicate template. Please try again!!"
        );
    }
    return result?.data;
}

export const createFollowUp = async (data) => {

    const url = '/interviewTemplate/follow-up';

    if (!data) {
        return;
    }
    const result = await new Client({
        path: url,
        payload: data,
    }).post();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to duplicate template. Please try again!!"
        );
    }
    return result?.data;
}