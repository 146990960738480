import React from "react";

import { useParams, useSearchParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";

import ScreenHeading from "components/ScreenHeading";
import DetailsForm from "./DetailsForm";
import FiltersForm from "./FiltersForm";
import MethodSelection from "./MethodSelection";
import QuestionSection from "./QuestionSection";
import Steps from "./Steps";

import useEditorStore from "./editorStore";
import { getCompletedSteps } from "services";

function TemplateEditor(props) {
  const { id, draftId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(true);

  const active = useEditorStore((state) => state.activeStep);
  const draftSaved = useEditorStore((state) => state.draftSaved);
  const setActive = useEditorStore((state) => state.setActiveStep);
  const resetStepsCompleted = useEditorStore(
    (state) => state.resetStepsCompleted
  );
  const setStepCompleteStatus = useEditorStore(
    (state) => state.setStepCompleteStatus
  );
  const setTemplateId = useEditorStore((state) => state.setTemplateId);
  const setDraftId = useEditorStore((state) => state.setDraftId);
  const reset = useEditorStore((state) => state.reset);

  const handleSectionChange = React.useCallback(
    (index) => {
      setActive(index);
    },
    [setActive]
  );

  React.useEffect(() => {
    setActive(0);
    resetStepsCompleted();

    return () => reset();
  }, []);

  React.useEffect(() => {
    if (draftId) {
      setDraftId(draftId);
      getCompletedSteps(draftId)
        .then((completed = []) => {
          setStepCompleteStatus(0, true);
          setActive(completed.length || 1);
          if (completed.length > 0) {
            completed.forEach((element, i) => {
              setStepCompleteStatus(i + 1, true);
            });
          }
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, [draftId]);

  React.useEffect(() => {
    if (id && id !== "create") {
      setTemplateId(id);
      setStepCompleteStatus(0, true);
      setStepCompleteStatus(1, true);
      setStepCompleteStatus(2, true);
      setStepCompleteStatus(3, true);

      const section = searchParams.get('section');
      if (section) setActive(parseInt(section))
      else setActive(2);
    }
  }, [id]);

  return (
    <Box backgroundColor="white" minHeight={"100%"}>
      <ScreenHeading
        title={
          id === "create"
            ? "Create assessment for students"
            : "Edit assessment for students"
        }
        subtitle="Your assessment, the way you like it"
      >
        <Grow in={draftSaved}>
          {(() => (
            <Alert
              icon={false}
              severity="error"
              sx={{ m: 0, height: 36, fontSize: 12, py: 0 }}
            >
              Saved as draft!
            </Alert>
          ))()}
        </Grow>
      </ScreenHeading>

      <Steps onChange={handleSectionChange} />
      {!loading && (
        <Box p={2}>
          <MethodSelection style={{ height: active === 0 ? "100%" : 0 }} />

          <FiltersForm show={active === 1} />

          <QuestionSection style={{ height: active === 2 ? "100%" : 0 }} editMode={id !== "create"}/>
          <DetailsForm style={{ height: active === 3 ? "100%" : 0 }} />
        </Box>
      )}
    </Box>
  );
}

export default TemplateEditor;
