import React from 'react';
import { useParams } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

import CustomButton from 'components/CustomButton';
import InviteUsers from 'dialogs/InviteUsers';
import { ResendMail } from 'dialogs/Resendmail';

import { useSnackbar } from 'contexts';
import { ConfirmationDialog } from 'dialogs';
import { getBatchUserMappings, removeStudentsFromBatch } from 'services';
import { Session, formatDate, getMonths } from 'utils';
import StaticTable from 'components/Tables/StaticTable';
import { Button, Collapse } from '@mui/material';
import dayjs from 'dayjs';
import { AccessControl, SubscriptionModel } from './constants';


const sx = {
  editBtn: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  deleteBtn: {
    fontSize: '12px',
    padding: '3px 5px',
    color: '#FF2D55',
    border: '1px solid #FF2D55',
    textTransform: 'capitalize',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  addBackBtn: {
    fontSize: '12px',
    padding: '5px',
    color: '#fff',
    textTransform: 'capitalize',
    backgroundColor: '#07C782',
    '&:hover': {
      backgroundColor: '#07C782'
    }
  },
  viewLess: { border: '1px solid #02569D', padding: '3px 10px' },
  viewAll: { backgroundColor: '#02569D', color: '#fff', '&:hover': { backgroundColor: '#02569D' } }
}

const columns = [
  { id: 'name', label: 'Name', field: 'name', minWidth: 100 },
  {
    id: 'onboardedAt', field: 'onboardedAt', type: 'date',
    label: 'Onboarded Date', minWidth: 100, align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid() ? formatDate(row[field], 'DD-MMM-YYYY') : '-'
  },
  {
    id: 'remainingAccess', field: 'remainingAccess', label: 'Remaining Access', align: 'center',
    renderCell: ({ row, field }) => {
      return (
        <Box display='flex' justifyContent='center' gap='2px' alignItems='center'>
          {
            dayjs(row.accessExpiresOn).isBefore(dayjs(), 'day') < 0 ?
              <Typography fontSize='14px' color='rgb(255, 45, 85)' fontWeight='500'>
                Expired
              </Typography>
              :
              <Typography fontSize='14px' color='#02569D' fontWeight='500'>
                {row[field]} Days
              </Typography>
          }
        </Box>
      )
    }
  },
  {
    id: 'accessExpiresOn', field: 'accessExpiresOn', type: 'date',
    label: 'Access Ending', align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid() ? formatDate(row[field], 'DD-MMM-YYYY') : '-'
  },
];

const pastUserColumns = [
  { id: 'name', label: 'Name', field: 'name', minWidth: 100 },
  {
    id: 'onboardedAt', field: 'onboardedAt', type: 'date',
    label: 'Onboarded Date', minWidth: 100, align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid() ? formatDate(row[field], 'DD-MMM-YYYY') : '-'
  },
  {
    id: 'accessExpiresOn', field: 'accessExpiresOn', type: 'date',
    label: 'Access Ending', align: 'center',
    renderCell: ({ row, field }) =>
      dayjs(row[field]).isValid()
        ? dayjs(row[field]).isBefore(dayjs())
          ? <Typography style={{ color: 'red', fontSize: '14px' }}>Expired</Typography>
          : formatDate(row[field], 'DD-MMM-YYYY')
        : '-'
  },
];

function StudentList({ onStudentCountChange, setActiveStudents }) {
  const { id } = useParams();
  const snackbar = useSnackbar();
  const user = Session.user;

  const [loading, setLoading] = React.useState(false);
  const [inviteUsers, setInviteUsers] = React.useState(false);
  const [resendMail, setResendMail] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [removedRowSelectionModel, setRemovedRowSelectionModel] = React.useState([]);
  const [confirmRemove, setConfirmRemove] = React.useState(false);
  const [activeItemId, setActiveItemId] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [pastUserRows, setPastUserRows] = React.useState([]);
  const [pastUserCollapse, setPastUserCollapse] = React.useState(true);
  const [inviteUsersData, setInviteUsersData] = React.useState([]);
  const [failedEmailData, setFailedEmailData] = React.useState([]);
  const [userAction, setUserAction] = React.useState();
  const isPayPerUser = (user?.metadata?.subscriptionModel === SubscriptionModel.PAY_PER_USER);

  const loadUsers = async () => {
    let activeUsers = [];
    let pastUsers = [];
    try {
      setLoading(true);

      if (id && id !== "create") {
        const { batchUserMappings } = await getBatchUserMappings({
          batch: id, include: "user"
        });

        batchUserMappings?.map(bum => {
          const user = {
            _id: bum._id,
            name: bum?.user?.name || '-',
            email: bum?.user?.email || '',
            accessPeriod: getMonths(bum?.onboardedAt, bum?.accessExpiresOn) || 0,
            credits: getMonths(bum?.onboardedAt, bum?.accessExpiresOn) || 0,
            remainingAccess: dayjs(bum?.accessExpiresOn).diff(dayjs(), 'day') + 1 || 0,
            createdAt: bum.createdAt,
            onboardedAt: bum?.onboardedAt || '-',
            accessExpiresOn: bum?.accessExpiresOn || '-'
          }
          bum.removed || dayjs(bum.accessExpiresOn).isBefore(dayjs())
            ? pastUsers.push(user)
            : activeUsers.push(user)
        })
      }
    } catch (error) {
      console.log(error);
      snackbar.showSnackbar("Uh Oh! Unable to load users", "error");
    } finally {
      setLoading(false);
      setRows(activeUsers);
      setActiveStudents(activeUsers.length);
      setPastUserRows(pastUsers);
    }
  };

  const removeSelectedUsers = React.useCallback(async () => {
    const itemIds = rowSelectionModel;

    setRows(currRows => currRows.filter((v) => !itemIds.includes(v._id)));
    setConfirmRemove(false);

    try {
      await removeStudentsFromBatch(id, itemIds);
      snackbar.success("Successfully removed the selected students!!");
    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    } finally {
      loadUsers();
      setRowSelectionModel([]);
      setRemovedRowSelectionModel([]);
    }

  }, [rowSelectionModel]);

  const removeUser = React.useCallback(async () => {
    const itemId = activeItemId;
    setLoading(true);

    try {
      await removeStudentsFromBatch(id, [itemId]);
      snackbar.success("Successfully removed the student!!");

    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    } finally {
      loadUsers();
      setActiveItemId(false);
      setRowSelectionModel([]);
      setRemovedRowSelectionModel([]);
      setLoading(false);
      setRows(currRows => currRows.filter((v) => itemId !== v._id));
    }
  }, [activeItemId]);

  const handleAddBack = ({ addAll = false }) => {
    let users = []
    pastUserRows.forEach((user) => {
      if (addAll) {
        users.push({ ...user, previousExpiry: user.accessExpiresOn, credits: 0 })
      }
      else if (removedRowSelectionModel.includes(user._id)) {
        users.push({ ...user, previousExpiry: user.accessExpiresOn, credits: 0 })
      }
    })
    setInviteUsersData(users);
    setUserAction(AccessControl.RESUME);
    setInviteUsers(true);
  }

  const handleUpgrade = () => {
    let users = []
    rows.forEach((user) => {
      if (rowSelectionModel.includes(user._id)) {
        users.push({ ...user, previousExpiry: user.accessExpiresOn, credits: 0 })
      }
    })
    setInviteUsersData(users);
    setUserAction(AccessControl.UPGRADE);
    setInviteUsers(true);
  }

  const LiveUserActions = [
    {
      title: "Remove",
      renderIcon: () => <Button sx={sx.deleteBtn}>
        <PersonRemoveAlt1OutlinedIcon style={{ padding: '1px', marginRight: '2px', fontSize: '18px' }} />
        Remove
      </Button>,
      label: "Remove Student",
      onClick: (param) => {
        setActiveItemId(param.id);
      }
    }
  ]

  const pastUserActions = [
    {
      title: "Add Back",
      renderIcon: () => <Button sx={sx.addBackBtn}>
        <PersonAddAlt1OutlinedIcon style={{ padding: '1px', marginRight: '2px', fontSize: '18px' }} />
        Add Back
      </Button>,
      label: "Add Back",
      onClick: (param) => {
        let pastData = pastUserRows.filter((user) => user._id === param.id)
        setInviteUsersData([{ ...pastData[0], previousExpiry: pastData[0].accessExpiresOn, credits: 0 }]);
        setUserAction(AccessControl.RESUME);
        setInviteUsers(true);
      }
    }
  ]

  const handleInviteUsersClose = (result) => {
    setInviteUsers(false);
    if (result) loadUsers();
  };

  React.useEffect(() => (id !== "create") && loadUsers(), [id]);

  React.useEffect(() => {
    if (typeof onStudentCountChange === 'function')
      onStudentCountChange(rows.length);
  }, [rows]);

  React.useEffect(() => {
    if (typeof getItems === 'function') {
      loadUsers()
        .then((result) => setRows(result))
        .catch((error) => snackbar.error(error.message))
    }
  }, []);

  React.useEffect(() => {
    if (failedEmailData.length) {
      setResendMail(true);
    }
  }, [failedEmailData]);

  const handleCollapse = () => {
    setPastUserCollapse(!pastUserCollapse)
  }

  return (
    <div>
      <Box>
        <Box
          style={{
            padding: '5px 16px',
          }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h6">Live Students</Typography>
            <Typography mb={1} variant="subtitle2" color="#747575">
              Add, remove & view students of this batch
            </Typography>
          </Box>
          <Box>
            {(rowSelectionModel.length > 0) && (
              <CustomButton
                variant="contained"
                onClick={() => handleUpgrade()}
              >
                {
                  isPayPerUser ? <>Update Subscription</> : <>Update Access</>
                }
              </CustomButton>
            )}
            {rowSelectionModel.length ?
              <CustomButton
                onClick={() => {
                  setConfirmRemove(true);
                }}
                sx={{ border: '1px solid #02569D' }}
              >
                Remove Selected
              </CustomButton>
              :
              <CustomButton
                startIcon={<AddIcon />}
                onClick={() => {
                  setUserAction(AccessControl.GRANT);
                  setInviteUsersData([]);
                  setInviteUsers(true);
                }}
                variant={'contained'}
              >
                Invite Students
              </CustomButton>
            }
          </Box>
        </Box>
        <Box px='16px'>
          <StaticTable
            loading={loading}
            height="300px"
            noRowsMessage="No active students in this batch!!"
            rows={rows}
            actions={LiveUserActions}
            columns={columns}
            showRowSelectCheckbox={true}
            setRowSelectionModel={setRowSelectionModel}
            rowSelectionModel={rowSelectionModel}
            showColumnSelection={false}
            showExports={false}
          />
        </Box>
      </Box>

      {/* past Users */}
      <Box
        border='1.6px solid #013B6B' borderRadius='5px'
        mx={'16px'} mt='30px' p='7px' backgroundColor={pastUserCollapse ? '#fff' : '#E3F4FF'}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h6">Past Users</Typography>
            <Typography mb={1} variant="subtitle2" color="#747575">
              Click to view and activate the students back in the Batch.
            </Typography>
          </Box>
          <Box>
            <CustomButton
              sx={pastUserCollapse ? sx.viewAll : sx.viewLess}
              onClick={handleCollapse}
            >
              {
                pastUserCollapse ?
                  'View' : 'View Less'
              }
            </CustomButton>
          </Box>
        </Box>
        <Collapse in={pastUserCollapse !== true}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt='10px'
          >
            <Box>
              <Typography variant="h6" fontSize='17px'>Discontinued Enrolees</Typography>
              <Typography mb={1} variant="subtitle2" color="#747575" fontSize='12px'>
                View Past users & add them back.
              </Typography>
            </Box>
            <Box>
              {removedRowSelectionModel.length > 0 && (
                <CustomButton
                  startIcon={<PersonAddAlt1OutlinedIcon />}
                  onClick={() => handleAddBack({ addAll: false })}
                  variant="contained"
                  sx={{ backgroundColor: '#07C782', fontSize: '12px' }}
                >
                  Add Back Selected
                </CustomButton>
              )}
              <CustomButton
                startIcon={<AddIcon />}
                onClick={() => handleAddBack({ addAll: true })}
                variant="contained"
                sx={{ fontSize: '12px' }}
                disabled={!pastUserRows.length}
              >
                Add Back All
              </CustomButton>
            </Box>
          </Box>
          <Box backgroundColor='#fff'>
            <StaticTable
              height="300px"
              noRowsMessage="No Past Users in this batch!!"
              rows={pastUserRows}
              actions={pastUserActions}
              columns={pastUserColumns}
              showRowSelectCheckbox={true}
              setRowSelectionModel={setRemovedRowSelectionModel}
              rowSelectionModel={removedRowSelectionModel}
              showColumnSelection={false}
              showExports={false}
            />
          </Box>
        </Collapse>
      </Box>
      <InviteUsers
        open={inviteUsers} onClose={handleInviteUsersClose} batchId={id}
        inviteUsersData={inviteUsersData} setInviteUsersData={setInviteUsersData}
        action={userAction}
        isPayPerUser={isPayPerUser}
        setFailedEmailData={setFailedEmailData}
      />
      <ResendMail
        resendMail={resendMail} setResendMail={setResendMail}
        onClose={() => setResendMail(false)}
        failedEmailData={failedEmailData}
        inviteUsersData={inviteUsersData}
      />
      <ConfirmationDialog
        open={!!activeItemId}
        onPrimaryClick={removeUser}
        onSecondaryClick={() => setActiveItemId(false)}
        primaryActionVariant="error"
        primaryAction='Confirm!'
        secondaryAction='No'
        multipleMessage={[
          <Typography fontWeight='550'>Are your sure you want to remove the student?</Typography>,
          'By removing the user, they would no longer be able to take any attempts in this Batch'
        ]}
        title='Confirm User Removal'
      />
      <ConfirmationDialog
        open={confirmRemove}
        onPrimaryClick={removeSelectedUsers}
        onSecondaryClick={() => setConfirmRemove(false)}
        primaryActionVariant="error"
        primaryAction='Confirm'
        secondaryAction='No'
        multipleMessage={[
          <Typography fontWeight='550'>
            Are your sure you want to remove {rowSelectionModel.length} student(s)?
          </Typography>,
          'By removing the user, they would no longer be able to access this Batch.'
        ]}
        title='Confirm User Removal'
      />
    </div>
  );
}

export default StudentList;