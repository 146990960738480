import React from 'react';
import { Outlet, Route, Routes } from "react-router-dom";
import AppShell from 'components/AppShell';
import PersonalizedInterviewScreen from 'screens/PersonalizedInterviewScreen';
import PersonalizedInterviewPerformance from 'components/PersonalizedInterview/PersonalizedInterviewPerformance';

function PersonalizedInterview(props) {
  return (
    <Routes>
      <Route path="/" element={<PersonalizedInterviewLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <PersonalizedInterviewScreen/>
            </React.Suspense>
          }
        />
         <Route
          path="/:id/performance"
          element={
            <React.Suspense fallback={<>...</>}>
              <PersonalizedInterviewPerformance />
            </React.Suspense>
          }
        />
      </Route>  
    </Routes>
  );
}

export default PersonalizedInterview;

function PersonalizedInterviewLayout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}