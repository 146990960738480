import Box from '@mui/material/Box';

const sx = {
    root: {
        background: "#FFFFFF",
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        borderRadius: "8px",
        py: "1em",
        px: "2em",
        my: "1em",
    },
};

function ShadowBox({ children, sx: sxIn, ...props }) {
    return (<Box sx={{ ...sx.root, ...sxIn }} {...props}>{children}</Box>);
}

export default ShadowBox;