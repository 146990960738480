import React from 'react';

import Box from '@mui/material/Box';
import { useLoading } from 'contexts/LoadingProvider';
import { useServices } from 'contexts/SingleSessionProvider';
import { useSnackbar } from 'contexts/SnackbarProvider';

import { getInterviewAttemptsForTemplate, getTemplateById } from 'services';
import { getInterviewFeedbackByAttemptId } from 'services/interviewFeedback';
import useStore from './store';
import Analysis from './Analysis';
import OverallPerformance from './OverallPerformance';
import { FeedbackTourSteps } from './constants';
import { getAverageScore } from 'utils';
import { TourProviderContext } from 'contexts';
import shallow from 'zustand/shallow';

function PerformanceReview(props) {
    const service = useServices();
    const snackbar = useSnackbar();
    const loading = useLoading();
    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get('uid');
    const tid = searchParams.get('tid');

    const { _setSteps, playTour } = React.useContext(TourProviderContext);

    const init = useStore(store => store.init);
    const template = useStore(store => store.template);
    const setTemplate = useStore(store => store.setTemplate);
    const setAttemptId = useStore(state => state.setAttemptId);
    const [attempt, setAttempt] = React.useState(0);
    const [parameter, setParameter] = React.useState('Overall');
    const [attempts, setAttempts] = React.useState({});
    const setAllAttempts = useStore(state => state.setAllAttempts, shallow);

    const attemptFeedbackMap = React.useRef({});

    React.useEffect(() => {
        if (tid) {
            loading.show();
            service.callService(getTemplateById, tid)
                .then(template => setTemplate(template))
                .catch(error => {
                    console.error(error);
                    snackbar.error("Uh Oh! Unable to get template");
                }).finally(() => {
                    loading.hide();
                });
        }
    }, [tid]);

    React.useEffect(() => {
        if (tid) {
            service.callService(
                getInterviewAttemptsForTemplate,
                {
                    interviewTemplate: tid,
                    user: userId,
                    finishedAt: null,
                    orderBy: "finishedAt",
                    order: "asc",
                    include: ['user']
                }
            ).then(({ interviewAttempts }) => {
                setAttempts(interviewAttempts);

                const _attempts = [];
                interviewAttempts.forEach(attempt => {
                    const content = attempt.analysis.content.ratings.OVERALL;
                    const communication = attempt.analysis.communication.ratings.OVERALL;
                    _attempts.push({
                        _id: attempt._id,
                        content,
                        communication,
                        type: attempt?.interviewTemplate?.type,
                        overall: getAverageScore(communication, content),
                        templateName: attempt?.interviewTemplate?.name,
                    });
                });
                setAllAttempts(_attempts);
            }).catch(error => {
                console.error(error);
                snackbar.error("Uh Oh! Unable to get attempts");
            }).finally(() => {
                loading.hide();
            });
        }
    }, [tid]);

    React.useEffect(() => {
        if (attempts.length) {
            if (attemptFeedbackMap.current[attempt]) {
                init(attemptFeedbackMap.current[attempt]);
            } else {
                service.callService(
                    getInterviewFeedbackByAttemptId, attempts[attempt]._id
                ).then(feedback => {
                    init(feedback);
                    attemptFeedbackMap.current[attempt] = feedback;
                }).catch(error => {
                    console.error(error);
                    snackbar.error("Uh Oh! Unable to get attempt performance analysis");
                }).finally(() => {
                    loading.hide();
                });
            }
        }
        if(tid){
            setAttemptId(attempts[attempt]?._id);
        }
    }, [attempts, attempt]);


    React.useEffect(() => {
        if (!localStorage.getItem('v2.0-feedback-tour')) {
            localStorage.setItem('v2.0-feedback-tour', true.toString());
            _setSteps(FeedbackTourSteps);
            playTour();
        }
    }, []);

    return (
        <Box py='20px'>
            <Box position="relative">
                <OverallPerformance
                    template={template}
                    attempt={attempt}
                    setAttempt={setAttempt}
                    parameter={parameter}
                    setParameter={setParameter}
                    title={template.name}
                    numberOfAttempts={attempts.length}
                />
                <Analysis />
            </Box>
        </Box>
    );
}

export default PerformanceReview;