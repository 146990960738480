import { createTheme } from "@mui/material/styles";

const breakpoints = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});


export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#02569D",
      dark: '#07385e',
      light: '#338dd6',
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#f55446",
    },
    divider: "rgba(133, 140, 144, 1)",
    success: {
      main: "#35e9a8",
    },
  },
  typography: {
    fontFamily: "'Inter'",
  },
};

const theme = createTheme(themeOptions);

export default theme;
