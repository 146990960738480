import React from 'react';
import { Outlet, Route, Routes } from "react-router-dom";
import AppShell from 'components/AppShell';
import StudentList from 'lists/Students';
import StudentPerformance from 'screens/StudentPerformance';
import PerformanceReview from 'components/PerformanceReview';

function Students(props) {
  return (
    <Routes>
      <Route path="/" element={<StudentsLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <StudentList />
            </React.Suspense>
          }
        />

        <Route
          path="/:id"
          element={
            <React.Suspense fallback={<>...</>}>
              <StudentPerformance />
            </React.Suspense>
          }
        />
        <Route
          path="/performance"
          element={
            <React.Suspense fallback={<>...</>}>
              <PerformanceReview />
            </React.Suspense>
          }
        />

      </Route>
    </Routes>
  );
}

export default Students;

function StudentsLayout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}