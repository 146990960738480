import React from 'react'
import { Box, Typography } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Bar } from 'react-chartjs-2';

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    } from 'chart.js';
import useStore from './store';
import { DemoApplications } from './constants';
import shallow from 'zustand/shallow';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    PointElement,
    LineElement,
    );

const AttemptGraph = ({recentAttempts, currentAttempt}) => {
    const attempts = recentAttempts.filter((value,index)=> index<4);
    const demoApplication = useStore(state => state.demoApplication, shallow);

    let chartOptions = {
        plugins:{
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                position: 'nearest',
            }
        },
        scales: { 
            yAxis: { 
                beginAtZero: true,
                max: 100,
                ticks: {
                    stepSize: 25,
                },
            } 
        },
    };

    React.useEffect(()=>{
        chartOptions.plugins.tooltip.callbacks = {
            title: ([item]) =>
                `Attempt ${recentAttempts[0].attemptNo  + item.dataIndex}`
        }
    })
    const labels = React.useMemo(() => {
        const _labels = attempts.map((x, i) => x.attemptNo);
        const labelsLength = (demoApplication === DemoApplications.teachAndTrain ? 3 : 4)
    
        if (_labels.length < labelsLength) {
            for (let x = _labels.length; x < labelsLength; x++) _labels.push('');
        }
    
        return _labels;
    }, [attempts]);

    const datasets = React.useMemo(()=>{
        const _datasets = [];
        _datasets.push({
            label: 'Speech',
            data: attempts.map(qs => Math.round(qs.communication)),
            backgroundColor: attempts.map((value, index) => (
                value._id === currentAttempt ? 
                'rgba(22, 89, 150, 1)' : 'rgba(22, 89, 150, 0.54)'
            )),
            borderRadius: 5
        });
        _datasets.push({
            label: 'Content',
            data: attempts.map(qs => Math.round(qs.content)),
            backgroundColor: attempts.map((value, index) => (
                value._id === currentAttempt ? 
                'rgba(159, 189, 214, 1)' : 'rgba(159, 189, 214, 0.38)' 
            )),
            borderRadius: 5
        });
        return _datasets;
    },[attempts]);

    return (
        <>
            <Box sx={{
                width: "100%",
                textAlign: 'center',
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <Box width={"100%"} textAlign="right">
                    <Box 
                        sx={{
                            marginRight:2, 
                            display:"flex", 
                            justifyContent:"flex-end", 
                            alignItems:"center"
                        }}
                    >
                        <FiberManualRecordIcon 
                            style={{ fontSize:"15px", color:'#165996', marginRight: "1px" }} 
                        />
                        <Typography fontSize={'10px'} fontWeight={600}> Speech </Typography>
                        &nbsp;&nbsp;
                        <FiberManualRecordIcon 
                            style={{ fontSize:"15px", color:'#9FBDD6', marginRight: "1px" }} 
                        />
                        <Typography fontSize={'10px'} fontWeight={600}> Content </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography
                        color={'#666666'}
                        style={{ transform: "rotate(-90deg)", fontSize:"10px", fontWeight:"600" }}
                    >
                        Score
                    </Typography>
                    <div style={{ width:"100%" }}>
                        <Bar
                            options={chartOptions}
                            data={{ labels, datasets }}
                        />
                    </div>
                </Box>
                <div>
                    <Typography 
                        style={{ fontWeight:"600", fontSize:"10px"}} 
                        color={'#666666'} 
                    >
                        Attempt No.
                    </Typography>
                </div>
            </Box>
        </>
    )
}

export default AttemptGraph