import React from 'react';

import Close from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicatorOutlined';
import MoreIcon from '@mui/icons-material/MoreVert';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { generateSituationalAnswer, generateTechnicalAnswer } from 'services/contentAPI';
import OptionChips from './OptionChips';
import { QuestionTypes } from './constants';
import useEditorStore from './editorStore';
import "./styles.css";


function Field({
    name, value, onChange, error, placeholder, label, ...props
}) {

    return (
        <div className="field-wrapper">
            <label className="question-label">{label}</label>
            <div className="question-field">
                <input
                    className="question-input"
                    onChange={onChange}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    {...props}
                />
                <span className="question-error">
                    {error}
                </span>
            </div>
        </div>
    );
}

function DraggableIndicator() {
    return (
        <div className='draggable-indicator'>
            <DragIndicatorIcon />
        </div>
    );
}

const Methods = { auto: 'auto', manual: 'manual' }

function Answer({
    name,
    domain,
    question,
    questionType,
    handleChange,
    value,
    error,
    difficulty,
}) {
    const [methodSelected, setMethodSelected] = React.useState('');
    const [generating, setGenerating] = React.useState(false);
    const [showExpectedAnswer, setShowExpectedAnswer] = React.useState(false);

    const canAutoGenerate = (question?.length > 10);

    const handleManually = () => {
        setMethodSelected(Methods.manual);
        setGenerating(false);
    };

    const calculateTime = (expectedAnswer) => {
      return Math.ceil(
        (expectedAnswer?.split(" ")?.length || WORDS_PER_MINUTE) / WORDS_PER_MINUTE
        );
    }

    const handleAutoGenerate = () => {
        setMethodSelected(Methods.auto);
        setGenerating(true);
        handleChange({ target: { name, value: '' } });
        if (questionType === 'situational') {
            generateSituationalAnswer({ domain, question, difficulty })
                .then(([text]) => {
                    handleChange({
                        target: { name, value: text.trim() }
                    });
                    handleChange({
                        target: { name: 'allottedTime', value: calculateTime(text) }
                    });
                })
                .catch(console.error)
                .finally(() => setGenerating(false));
        } else {
            generateTechnicalAnswer({ domain, question, difficulty })
                .then(([text]) => {
                    handleChange({
                        target: { name, value: text.trim() }
                    });
                    handleChange({
                      target: { name: 'allottedTime', value: calculateTime(text) }
                  });
                })
                .catch(console.error)
                .finally(() => setGenerating(false));
        }
    };

    const handleClose = () => {
        handleChange({ target: { name, value: '' } });
        setMethodSelected('');
        setShowExpectedAnswer(false);
    }

    React.useEffect(() => {
        if (methodSelected === Methods.auto && canAutoGenerate) handleClose();
    }, [questionType, question]);

    React.useEffect(() => {
        if (value) setShowExpectedAnswer(true);
    }, [value]);

    return (
        <div className="question-field">
            {(
                (methodSelected && !generating) || showExpectedAnswer
            ) && (
                    <IconButton
                        size="small"
                        style={{ position: 'absolute', right: -4, top: 2 }}
                        onClick={handleClose}
                    >
                        <Close color="primary" fontSize='small' />
                    </IconButton>
                )}
            {(methodSelected || showExpectedAnswer) ? (
                <textarea
                    className="question-input"
                    name={name}
                    value={value}
                    onChange={handleChange}
                    placeholder={generating ?
                        "Please wait, while we are generating the answer..." :
                        "Write your answer here..."
                    }
                    rows={6}
                    disabled={generating}
                />
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "stretch",
                        gap: 16,
                        padding: 8,
                    }}
                    className='question-input'
                >
                    <Button
                        fullWidth
                        variant="outlined" onClick={handleManually}
                        style={{ textTransform: "none" }}
                        size="small"
                    >
                        Write Answer Manually
                    </Button>
                    <Button
                        fullWidth
                        variant="contained" onClick={handleAutoGenerate}
                        style={{ textTransform: "none" }}
                        size="small"
                        disabled={!canAutoGenerate}
                    >
                        Auto-Generate Answer
                    </Button>
                </div>
            )}
            <span className="question-error">
                {error}
            </span>
        </div>
    );
}

const WORDS_PER_MINUTE = 90;

function QuestionForm({
    index,
    readOnly,
    errors,
    editMode
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuOpen = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);
    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);

    const domainName = useEditorStore(state => state.domain);
    const difficulty = useEditorStore(state => state.difficulty);
    const handleDelete = useEditorStore(state => state.removeQuestion);
    const question = useEditorStore(state => state.questions[index] || {});
    const importedQuestions = useEditorStore(state => state.importedQuestions);
    const autoGenerate = useEditorStore(state => state.autoGenerate);
    const updateQuestion = useEditorStore(state => state.updateQuestion);

    const error = React.useMemo(() => errors[index] || {}, [errors]);

    const handleRemoveQuestion = () => {
        handleDelete(false, +anchorEl.getAttribute("id"));
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        question[name] = value;

        updateQuestion(index, question);
    }

    React.useEffect(() => {
      if(!editMode && (autoGenerate || !importedQuestions)){
        const expectedAnswer = question.expectedAnswer || '';
        const projectedTime = Math.ceil(
          (expectedAnswer?.split(" ")?.length || WORDS_PER_MINUTE) / WORDS_PER_MINUTE
          );
          handleChange({ target: { name: 'allottedTime', value: projectedTime } });
        }
    }, [question?.expectedAnswer, importedQuestions, autoGenerate]);

    return (
        <div key={index} className="form-container" >
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={handleRemoveQuestion}>
                    Remove this question
                </MenuItem>
            </Menu>
            <DraggableIndicator />
            <div style={{ display: "flex", alignItems: "stretch" }} >
                <div className="flex-column left">
                    <Field
                        readOnly={readOnly}
                        label={`Q${index + 1}*`}
                        name={'question'}
                        value={question.question}
                        onChange={handleChange}
                        error={error['question']}
                    />
                    <Field
                        readOnly={readOnly}
                        label={'Res:'}
                        name={'resources'}
                        onChange={handleChange}
                        value={question.resources}
                    />

                    <div className='field-wrapper'>
                        <label className="question-label">Ans:*</label>
                        <Answer
                            domain={domainName}
                            name={'expectedAnswer'}
                            value={question.expectedAnswer}
                            question={question.question}
                            questionType={question.type}
                            handleChange={handleChange}
                            difficulty={difficulty}
                            error={error['expectedAnswer']}
                        />
                    </div>

                </div>
                <div className="flex-column right">
                    <label className="question-label">Type of question*</label>
                    <OptionChips
                        small
                        readOnly={readOnly}
                        options={QuestionTypes}
                        name={'type'}
                        value={question.type}
                        onChange={handleChange}
                        defaultValue={"technical"}
                    />

                    <label className="question-label">
                        Time for question (mins)*
                    </label>
                    <div className="question-field">
                        <input
                            readOnly={readOnly}
                            type="number"
                            className="question-input"
                            name={'allottedTime'}
                            value={question.allottedTime}
                            onChange={handleChange}
                            max={30}
                        />
                        <span className="question-error">
                            {
                                error['allottedTime']
                            }
                        </span>
                    </div>
                </div>
            </div>


            <IconButton
                id={index}
                aria-label="more"
                aria-controls={menuOpen ? 'long-menu' : undefined}
                aria-expanded={menuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuClick}
                style={{ position: "absolute", right: 8, top: 8 }}
            >
                <MoreIcon />
            </IconButton>
        </div>
    );
}

export default QuestionForm;