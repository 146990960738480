import { makeStyles } from "@mui/styles"
import Box from "@mui/material/Box";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

const useStyles = makeStyles({
    root: {
        position: "relative",
        display: "flex",
        padding: "15px 0px",
    },
    navigator: {
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        position: "absolute",
        top: 65,
        margin: "0px 16px",
        backgroundColor: ' rgba(255, 255, 255, 0.81)',
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.25) inset',
        cursor: "pointer",
    },
    show: {
        display: "flex"
    },
    contentContainer: {
        display: "flex",
        overflow: "auto",
        gap: 30,
        padding: 8,
        transition: "left 1s linear",
        "&::-webkit-scrollbar": { width: 0, height: 0 },
        "&::-webkit-scrollbar-thumb": { width: 0, height: 0, },
    },
});

function HorizontalScrollNavigator({ children, className }) {
    const classes = useStyles();
    const scrollContainerRef = useRef();

    const [show, setShow] = React.useState(false);
    const [isScrolled, setIsScroll] = React.useState(0);

    const navigatorClasses = classNames({
        [classes.navigator]: true,
        [classes.show]: show,
    });
    const handleNavigate = (direction) => () => {
        scrollContainerRef.current.scrollBy({
            top: 0,
            left: direction === "left" ? -200 : 200,
            behavior: 'smooth'
        });
        setIsScroll((prev) => (prev + (direction === "left" ? -200 : 200)));
    };

    const handleMouseEnter = () => setShow(true);

    const handleMouseLeave = () => setShow(false);

    return (
        <Box
            className={classes.root}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isScrolled > 0 &&
                <Box
                    className={navigatorClasses}
                    left={0}
                    onClick={handleNavigate("left")}
                >
                    <ChevronLeftIcon fontSize="large" />
                </Box>
            }

            <Box
                ref={scrollContainerRef}
                className={classes.contentContainer + " " + className}
            >
                {children}
            </Box>
            <Box
                className={navigatorClasses}
                right={0}
                onClick={handleNavigate("right")}
            >
                <ChevronRightIcon fontSize="large" />
            </Box>
        </Box>
    );
}

export default HorizontalScrollNavigator;