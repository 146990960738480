import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PasswordField from 'components/PasswordField';

import { useSnackbar } from 'contexts';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from 'services';
import { useNavigate, useSearchParams } from 'react-router-dom';

const sx = {
  label: {
    fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: 12,
    lineHeight: "29px", width: "40%", flexShrink: 0,
  },
  heading: {
    fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: 16,
    mr: 1,
  },
  buttonContainer: {
    display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2
  }
};

function ChangePassword(props) {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    values,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched
  } = useFormik({
    initialValues: {
      newPassword: "",
      confirmedPassword: "",
    },
    validationSchema: new Yup.ObjectSchema({
      newPassword: Yup.string()
        .min(8, "Min 8 characters required")
        .max(50, "Max 50 characters allowed")
        .notOneOf([Yup.ref('currentPassword'), null], "New Password has to be different than current Password")
        .required("Required"),
      confirmedPassword: Yup.string()
        .min(8, "Min 8 characters required")
        .max(50, "Max 50 characters allowed")
        .oneOf([Yup.ref('newPassword'), null], "Does not match with new password!")
        .required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const token = searchParams.get('at');

        await resetPassword(values.newPassword, token);

        snackbar.showSnackbar("Password reset successfully!");

        setEdit(false);
        navigate("/auth/login");
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => resetForm, [edit]);

  return (
    <Box width='60%'>
      <Typography
        color='black' fontFamily='inter'
        fontWeight={600} fontSize={32} mb={4}
      >
        Forgot password?
      </Typography>
      <form onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>

        <PasswordField
          id="newPassword"
          name="newPassword"
          label="New password"
          value={values.newPassword}
          onChange={handleChange}
          spellCheck="false"
          error={touched.newPassword && errors.newPassword}
          helperText={(touched.newPassword && errors.newPassword)}
          sx={{ width: '100%', }}
        />

        <PasswordField
          id="confirmedPassword"
          name="confirmedPassword"
          label="Confirmed password"
          value={values.confirmedPassword}
          onChange={handleChange}
          spellCheck="false"
          error={touched.confirmedPassword && errors.confirmedPassword}
          helperText={(touched.confirmedPassword && errors.confirmedPassword)}
          sx={{ width: '100%', }}
        />

        <LoadingButton
          sx={{ mt: 5, textTransform: "none", width: 100 }}
          loading={loading}
          variant="contained"
          type='submit'
          size="small"
        >
          Submit
        </LoadingButton>
      </form>
    </Box>
  );
}

export default ChangePassword;