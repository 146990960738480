import React from 'react';
import AppShell from 'components/AppShell';
import BatchEditor from 'editors/Batch';
import BatchList from 'lists/Batches';
import BatchAnalytics from 'screens/BatchAnalytics';
import FollowUpEditor from 'editors/TemplateEditor/FollowUpEditor';

import { Outlet, Route, Routes } from "react-router-dom";

function Batches(props) {
  return (
    <Routes>
      <Route path="/" element={<BatchesLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <BatchList />
            </React.Suspense>
          }
        />

        <Route
          path="/:id"
          element={
            <React.Suspense fallback={<>...</>}>
              <BatchEditor />
            </React.Suspense>
          }
        />

        <Route
          path="/:id/performance"
          element={
            <React.Suspense fallback={<>...</>}>
              <BatchAnalytics />
            </React.Suspense>
          }
        />
        <Route
          path="/:id/create-followup"
          element={
            <React.Suspense fallback={<>...</>}>
              <FollowUpEditor />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default Batches;

function BatchesLayout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}