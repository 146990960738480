import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import * as Papa from "papaparse";
import DownloadIcon from '@mui/icons-material/Download';

import CSVReaderInput from 'components/CSVReaderInput';
import CustomButton from 'components/CustomButton';
import GSheetImportButton from 'components/GSheetImportButton';
import { useLoading, useSnackbar } from "contexts";
import useEditorStore from 'editors/TemplateEditor/editorStore';
import { BootstrapDialogTitle } from './GoogleSheetImport';

const container = {
  display: 'flex', flexDirection: 'column', justifyContent: 'space-around',
  overflow: 'auto', minWidth: 450, width: 750,
};

function ImportQuestions({ open, onClose }) {
  const snackbar = useSnackbar();
  const loading = useLoading();
  const [error, setError] = React.useState(false);
  const [replaceAll, setReplaceAll] = React.useState(false);
  const setQuestions = useEditorStore(state => state.setQuestions);
  const setImportedQuestions = useEditorStore(state => state.setImportedQuestions);
  const addQuestions = useEditorStore(state => state.addQuestions);

  React.useEffect(() => {
    if (open) { setError(false); }
  }, [open]);

  const handleImportSuccess = async (rows) => {
    try {
      loading.show();

      setError(false);

      const questions = rows?.map((record) => ({
        question: record['Question']?.trim(),
        hint: record['Hint']?.trim(),
        resources: record['Resource']?.trim(),
        expectedAnswer: record['Answer'],
        type: record['Type(t/s)']?.trim() === 's' ? 'situational' : 'technical',
        allottedTime: parseInt(record['Time(min)']?.trim() || 0),
      }));

      if (questions.length) {
        setImportedQuestions(true);
        
        if (replaceAll) setQuestions(questions);
        else addQuestions(questions);
        onClose();
        snackbar.success("Imported questions successfully");
      } else {
        snackbar.warn("No questions found to import");
      }
    } catch (error) {
      console.log(error);
      snackbar.showSnackbar(
        "Uh Oh! Unable to import data from sheet", "error", true
      );
      setError(error.message);
    } finally {
      loading.hide();
    }
  };

  const downloadSample = () => {
    let csvContent = Papa.unparse({
      data: [],
      fields: [
        "Question", "Hint", "Resource", "Answer", "Type(t/s)", "Time(min)"
      ]
    }, {});

    csvContent = "data:text/csv;charset=utf-8,".concat(csvContent)

    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", `questions-sample.csv`);
    link.click();
  };

  const handleChange = (event) => setReplaceAll(event.target.checked);


  const handleImportError = (error) => setError(error.message);

  return (
    <Dialog onClose={onClose} open={open} maxWidth='lg'>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        Import Questions
      </BootstrapDialogTitle>
      <DialogContent sx={container} >
        <ul>
          <li>
            Please ensure that your file has the following columns: <b>Question, Hint, Resource, Answer, Type(s/t), Time(min)</b>
          </li>
          <li>
            Column names are case sensitive thus make sure column names are same
            as provided above.
          </li>
          <li>
            Please make sure that the file is properly formatted and saved before importing.
          </li>
          <li>
            If you need a sample file, you can download it by clicking the <b>Download Format</b> button.
          </li>
        </ul>
        <FormControlLabel
          control={(<Checkbox checked={replaceAll} onChange={handleChange} />)}
          label="Replace existing questions"
        />
        {!!error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", mx: 2 }}>
        <CustomButton
          variant="outlined"
          onClick={downloadSample}
          color="success"
          startIcon={<DownloadIcon />}
        >
          Download Format
        </CustomButton>

        <DialogActions disableSpacing>
          <CSVReaderInput
            onData={handleImportSuccess}
            onError={handleImportError}
            label="Import from CSV"
            size='small'
          />
          <GSheetImportButton
            sheetProps={{
              onSuccess: handleImportSuccess,
              onError: setError,
              title: "Import Questions",
              sampleSheetUrl: "https://docs.google.com/spreadsheets/d/1hcf4ClSCAcQWbxYWjGyREjkVY5hAtqDOjXQWxDgARRY/edit#gid=0"
            }}
          />
        </DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default ImportQuestions;