import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from "./TextField";

import { useField } from "formik";

export function PasswordField(props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        margin: 1,
        ...(props.sx ? { ...props.sx } : {})
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
    />
  );
};

export function FormPasswordField({ label, ...props }) {
  const [field, meta] = useField(props);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      size='small'
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        px: 1,
        ...(props.sx ? { ...props.sx } : {})
      }}
      InputProps={{
        endAdornment: (
          < InputAdornment position="end" >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...field,
        ...props.InputProps,
      }}
      label={label}
      error={!!(meta.touched && meta.error)}
      helperText={meta.error}
    />
  );
};

export default PasswordField;