import Client from "./_client";

export const getBatchUserMappings = async (filters) => {
  let url = `/batchUserMapping?`;

  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) url += `${key}=${filters[key]}&`;
  });

  let result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to get batch students. Please try again."
    );

  return result.data;
}

export const updateBatchStudents = async (id, data) => {
  if (!id) throw new Error("Save the batch first");

  let url = `/batchUserMapping/${id}/students`;

  let result = await new Client({ path: url, payload: data }).patch();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to save batch students. Please try again."
    );

  return result.data;
}

