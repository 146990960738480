export const CommentAndColor = {
    VERY_GOOD: {
        comment: 'Good Work!',
        color: '#07E997',
        backgroundColor: '#C1E9DB',
        overallComment: 'You are doing great ✨',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        feedback: "Overall score is very good, indicating that you have exceptional verbal communication skills that allow you to connect with others on a deeper level.",
    },
    GOOD: {
        comment: 'Good Work!',
        color: '#FFC500',
        backgroundColor: '#FFF0BA',
        overallComment: 'You are doing great ✨',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        feedback: 'Overall score is good! This means you have solid verbal communication skills that enable you to effectively express yourself and connect with others.'
    },
    FAIR: {
        comment: 'On Track!',
        color: '#FFC500',
        backgroundColor: '#FFF0BA',
        overallComment: 'You will be there soon',
        img: 'https://assets.languify.in/images/avg-emoji.svg',
        feedback: "Overall score is fair, indicating that you have some strengths in verbal communication but also areas for improvement. Consider using clear language and practicing empathy."
    },
    NOT_OKAY: {
        comment: 'Needs Work!',
        color: '#FF0031',
        backgroundColor: '#FFC7D2',
        overallComment: 'Lets do some hard work',
        img: 'https://assets.languify.in/images/bad-emoji.svg',
        feedback: "Overall score is not okay, which means you need to work on your communication. We have provided tips and resources to help you improve your verbal communication skills."
    },
    POOR: {
        comment: 'Needs Work!',
        color: '#FF0031',
        backgroundColor: '#FFC7D2',
        overallComment: 'Lets do some hard work',
        img: 'https://assets.languify.in/images/bad-emoji.svg',
        feedback: "Overall score is poor, indicating that you may struggle to communicate effectively with others. Consider seeking feedback and practicing active listening to improve your communication skills."
    },
};

export const Categories = {
    "FLUENCY": "Fluency",
    "WORD_CHOICE": "Vocabulary",
    "DELIVERY": "Vocal Delivery",
    "CONFIDENCE": "Confidence",
    "SIMILARITY": "Similarity",
    "RELEVANCE": "Relevance",
    "LOGIC_FLOW": "Logic flow",
};

export const DummySampleAnswers = [
    "I am an experienced software engineer with over 5 years of experience in developing software applications. My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients. In my free time, I enjoy reading books on technology and programming.",
    "My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients. In my free time, I enjoy reading books on technology and programming.I am an experienced software engineer with over 5 years of experience in developing software applications."
];

export const DummyPersonalizedAnswer = " In my free time, I enjoy reading books on technology and programming. I am an experienced software engineer with over 5 years of experience in developing software applications. My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients.";

export const DummyContentFeedback = "Aman, your introduction was so impressive it left us speechless! We were absolutely blown away by your impressive communication skills. Not only were you able to express yourself clearly and precisely, but you also have knocked out of the park with numerous designing projects. Furthermore, your mastery of the use of language is something nearly all professionals strive for. Clearly you've already got a step up on the competition! Keep up the amazing work!";

export const SUB_CATEGORIES_DESCRIPTION = {
    "FORMALITY": "Level of formality in language used",
    "CONFIDENCE": "Level of confidence conveyed through language",
    "PAUSES": "Frequency and duration of pauses in speech",
    "DISFLUENCY": "Frequency of disruptions and irregularities in speech",
    "PACE": "Speed of speech",
    "ENERGY": "Level of enthusiasm and energy conveyed through language",
    "GREETING": "Presence or absence of greeting at the beginning of communication",
    "CONCLUSION": "Presence or absence of clear conclusion at the end of communication",
    "COMPELLING_VOCAB": "Use of powerful and convincing vocabulary",
    "ACTION_VERBS": "Use of verbs that convey action and movement",
    "JARGON_WORDS": "Use of specialized language or jargon",
    "VOCAB_RANGE": "Range and variety of vocabulary used",
    "HINDI_SHARE": "Percentage of communication in Hindi language",
    "TALK_SHARE": "Percentage of time spent talking in conversation",
    "INFORMAL_WORDS": "Use of casual and informal language",
    "INCLUSIVE_WORDS": "Use of language that includes and acknowledges diversity",
    "OFFENSIVE_WORDS": "Use of language that is derogatory or insulting",
    "FILLER_WORDS": "Filler words are short pauses to fill up the gap but they usually don't have any meaning",
    "FILLER_PHRASES": "Frequent use of unnecessary phrases and filler words"
};

export const Tips = {
    FLUENCY: {
        low: [
            "Practice pronunciation and use shorter sentences.",
        ],
        okay: [
            "Expand vocabulary and maintain a consistent pace.",
        ],
        high: [
            "Develop natural intonation and stress patterns, use complex sentence structures, and seek real-life conversations.",
        ],
    },
    WORD_CHOICE: {
        low: [
            "Expand your word bank and use context clues.",
        ],
        okay: [
            "Learn synonyms, word families, and common collocations.",
        ],
        high: [
            "Focus on precision, idiomatic expressions, and technical vocabulary.",
        ],
    },
    DELIVERY: {
        low: [
            "Focus on articulation, volume, and pace.",
        ],
        okay: [
            "Work on intonation, clarity, and expressiveness.",
        ],
        high: [
            "Master voice modulation, fluency, and nonverbal communication.",
        ],
    },
    CONFIDENCE: {
        low: [
            "Practice speaking in front of a mirror to boost self-assurance.",
        ],
        okay: [
            "Focus on positive self-talk and believe in your abilities.",
        ],
        high: [
            "Embrace challenges, celebrate your achievements, and maintain a positive mindset.",
        ],
    },
};


export const ContentTips = {
    SIMILARITY: {
        low: [
            "Stay on the main topic and avoid unrelated information.",
        ],
        okay: [
            "Provide relevant examples and maintain a consistent focus.",
        ],
        high: [
            "Explore different aspects, provide in-depth analysis, and present contrasting viewpoints.",
        ],
    },
    RELEVANCE: {
        low: [
            "Stay on topic and eliminate digressions.",
        ],
        okay: [
            "Align with the main theme, support your points, and check for consistency.",
        ],
        high: [
            "Offer depth of relevance, provide specific details, and show a nuanced understanding.",
        ],
    },
    LOGIC_FLOW: {
        low: [
            "Use clear and concise sentences, organize your content, and avoid abrupt transitions.",
        ],
        okay: [
            "Use logical connectors, present ideas sequentially, and provide smooth transitions.",
        ],
        high: [
            "Develop a logical argument, show cause-and-effect relationships, and create a well-structured narrative.",
        ],
    },
};

export const FeedbackTourSteps = [
    {
        id: "overall-score",
        title: "Overall score",
        message: "View your overall performance for your particular given assessment.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {},
    },
    {
        id: "your-progress",
        title: "Your Progress Card",
        message: "View and track your progress from your previous 3 attempts to stay on track and improve.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {},
    },
    {
        id: "q-and-a",
        title: "Reviewing Questions and Answers",
        message: "Select questions and view the question and transcript of your answer.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {},
    },
    {
        id: "sample-answer-button",
        title: "Sample Answer",
        message: "Refer to a sample answer for each question for reference and improvement.",
        skipText: null,
        action: "Got it",
        hideClose: false,
        data: {},
    },
    {
        id: "personalized-feedback",
        title: "Personalized Feedback",
        message: "View our customized feedback for your response, to help you understand your mistakes and how can you improve your response.",
        action: "Got it",
        hideClose: false,
        data: {},
    },
    {
        id: "speech-analysis",
        title: "Detailed Speech Analysis",
        message: "View detailed parameter analysis for your speech. Each parameter has certain categories that can be reviewed as well. Additionally, receive personalized tips to improve.",
        action: "Got it",
        hideClose: false,
        data: {},
    },
    {
        id: "content-analysis",
        title: "Detailed Content Analysis",
        message: "View detailed parameter analysis for your content. Each parameter has certain categories that can be reviewed as well. Additionally, receive personalized tips to improve. ",
        action: "Got it",
        data: {}
    },
];

export const templateTypes = {
    ASSIGNMENT: "assignment",
    PRACTICE: "practice"
}

export const Tags = {
    WORD_CHOICE : "Vocabulary consists of words and terms used in communication, typically with specific meanings.",
    CONFIDENCE : "Confidence is the unwavering assurance in one's speech, conveying self-assuredness and conviction without unnecessary additions.",
    SIMILARITY: "Degree of resemblance or likeness between the content presented by you and the expected or desired content.",
    RELEVANCE: "The extent to which the information provided aligns with the expected or desired context.",
    LOGIC_FLOW: "The organized sequence of steps that lead to a desired outcome, ensuring a logical and coherent sentence.",
}

export const DemoApplications = {
    inprep: "inprep",
    teachAndTrain: "teach-n-train"
}