import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getCommentAndColor } from './helpers';

function CircularProgressWithLabel({ innerBgColor, ...props }) {

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                width: props.size || 140,
                height: props.size || 140
            }}

        >
            <CircularProgress
                variant="determinate"
                {...props}
                sx={{ color: props.bgcolor || "red" }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: innerBgColor,
                    borderRadius: "50%",
                    margin: "10%"
                }}
                display={"flex"}
                alignItems={"center"}
            >
                <Typography
                    variant="h4"
                    component="div"
                    color={props.bgcolor || "red"}
                    fontSize={(props.size)/2.8 || "12px"}
                    display={"flex"}
                >
                    {`${Math.round(props.value)}`}
                    <span style={{ fontSize: "20px" }}></span>
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CircleProgressbar({ value, size, ...props }) {
    const data = getCommentAndColor(value);

    return <CircularProgressWithLabel
        value={value || 0}
        bgcolor={data.color || "red"}
        innerBgColor={data.backgroundColor}
        style={{
            backgroundColor: '#F4F5F5',
            borderRadius: "50%",
        }}
        thickness={4}
        size={size || 180}
        {...props}
    />;
}
