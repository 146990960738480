import Client from "./_client";

export const generateAssessment = async ({
  domain,
  difficulty,
  subTopics = [],
  keywords = [],
  subjectiveCount = 0,
  objectiveCount = 0,
  temperature = 0.5,
}) => {
  const result = await new Client({
    path: "/contentAPI/generate-assessment",
    payload: {
      data: {
        domain, difficulty, subTopics, keywords,
        subjectiveCount, objectiveCount
      },
      temperature: temperature,
      maxTokens: 2000,
    }
  }).post();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to generate assessment!");

  return result.data || {};
}

export const generateTechnicalAnswer = async ({ question, difficulty }) => {
  const result = await new Client({
    path: "/contentAPI/generate-ideal-answer",
    payload: {
      data: { question, difficulty },
      temperature: 0.5,
      maxTokens: 500,
    }
  }).post();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to generate technical answer!");

  return result.data || {};
}

export const generateSituationalAnswer = async ({ domain, question, difficulty }) => {
  const result = await new Client({
    path: "/contentAPI/generate-sample-answer",
    payload: {
      data: { domain, question, difficulty },
      temperature: 0.3,
      maxTokens: 500,
    }
  }).post();

  if (!(result.ok || result.code === 200))
    throw new Error(result.message || "Unable to generate situational answer!");

  return result.data || {};
}
