import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import CustomLink from "components/CustomLink";
import ShadowBox from "components/ShadowBox";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "auto",
    overflow: "hidden",
  },
  seeMore: {
    fontWeight: "500",
    fontSize: "15px",
    zIndex: "100",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    position: "absolute",
    bottom: "10px",
    right: "30px",
    transition: "background-color 0.3s ease-out 200ms",
  },
  cardContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'column',
    padding: 8,
    minWidth: "300px",
    maxWidth: "300px",
    height: "150px",
    maxHeight: "150px",
    background: "#FFFFFF",
    border: "1px solid #02569D",
    borderRadius: 6,
    cursor: "pointer",
    transition: "all 400ms ease-in-out",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      boxShadow: "4px 4px 10px 2px rgba(0, 0, 0, 0.3)",
    }
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
    letterSpacing: "0",
    margin: "2px 0px",
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#535353",
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  content: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#535353",
  },
  tag: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    backgroundColor: "#C5D8E8",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "10.5px",
    color: "#02569D",
    width: "fit-content",
    height: "fit-content",
    padding: "4px 8px",
    marginRight: 5
  },
  expire: {
    color: "#E52151",
    background: "#EFD5DA"
  },
  editButton: {
    position: "absolute",
    right: 8,
    bottom: 8,
    zIndex: 1,
  },
  emptyCard: {
    padding: 32, alignItems: "center",
    justifyContent: "center", display: "flex",
    flexDirection: "column",
    textDecoration: 'none', color: '#02569D',
  }
});

export function Tag({ name, expire = false }) {
  const classes = useStyles();
  const tagClasses = classNames({
    [classes.tag]: true,
    [classes.expire]: expire,
  })
  return (<Box className={tagClasses}> {name} </Box>);
}

export function Card({ item, title }) {
  const classes = useStyles();

  return (
    <Grid
      item
      className={classes.cardContainer}
      onClick={item?.onClick}
      title={title}
    >
      {item?.actions?.map(({ Icon, ...action }, index) => action?.hide ? null : (
        <IconButton
          key={index}
          className={classes.editButton}
          color={action?.color || "primary"}
          onClick={action?.onClick}
        >
          <Icon />
        </IconButton>
      ))}

      <Box display={"flex"} flexWrap="wrap" alignItems="start">
        {item?.tags?.map(({ name, expire }, index) => (
          <Tag name={name} expire={expire} key={index} />
        ))}
      </Box>
      <Box
        className={classes.cardContent}
        justifyContent="flex-end"
        flexGrow={1}
        pl={'4px'}
        pb={0}
      >
        {item?.type && (<Typography className={classes.subtitle}>
          {item.type}
        </Typography>)}

        <Typography className={classes.title}>
          {item?.name || ""}
        </Typography>

        {item?.infos?.length > 0 && (
          <Box
            display="flex"
            fontSize={12}
            alignItems="center"
            color="gray"
            flexWrap="wrap"
            columnGap={'4px'}
          >
            {item?.infos.map((info, index) => (
              <div
                key={index}
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                {info}&nbsp;
              </div>
            ))}
          </Box>
        )}
      </Box>
    </Grid>
  );
}

export function CardList({
  items = [], title, newPath = '', seeMorePath = '', cardTitle = ''
}) {
  const classes = useStyles();

  return (
    <ShadowBox className={classes.root} p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={0}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography
            variant="h6"
            fontWeight={600}
            mr={2}
            mb={1}
          >
            {title}
          </Typography>
        </Box>
        <CustomLink to={seeMorePath} style={{ textDecoration: 'none', color: '#02569D' }}>
          See all
        </CustomLink>
      </Box>
      <Box height={'auto'} maxHeight={180} overflow="hidden" py={2}>
        <Grid container gap={2} justifyContent="flex-start">
          <Grid
            item
            className={classes.cardContainer}
            style={{ border: "1px solid #02569D" }}
          >
            <CustomLink to={newPath} className={classes.emptyCard} >
              <AddIcon fontSize="large" color="primary" />
              <Typography
                textAlign={"center"}
                variant="h6"
                mt={0}
                color="primary"
              >
                Create new
              </Typography>
            </CustomLink>
          </Grid>
          {items.map((item, index) => (
            <Card key={index} item={item} title={cardTitle} />
          ))}
        </Grid>
      </Box>
    </ShadowBox>
  );
}

export default CardList;