import React from 'react';

import ViewPerformanceIcon from '@mui/icons-material/QueryStats';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ShadowBox from 'components/ShadowBox';
import { Link, useParams } from 'react-router-dom';
import { getBestPerformersOfBatch } from 'services';
import { Box } from '@mui/material';
import Select from "components/Select";

const Categories = {
  BEST_PERFORMER : 1, TOP_PERFORMER: 2
}

const LeaderboardOptions = [
  {_id: 1, name: 'Best Scores'},
  {_id: 2, name: 'Most Dedicated'}
]

const Rows = ({row, LeaderboardCategory}) => {
  return (
    <TableRow
      key={row.name}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="td" scope="row">
        {row.name}
      </TableCell>
      <TableCell component="td" scope="row" align='center'>
        {
          LeaderboardCategory === Categories.BEST_PERFORMER 
          ? Math.round(row.average) : row.totalAttempts
        }
      </TableCell>
      <TableCell component="td" scope="row" align="center">
        <Link to={`/students/${row._id}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 22 24" fill="none">
            <rect y="15.0645" width="6" height="8.60787" rx="1" fill="#025093"/>
            <rect x="8" y="8.60742" width="6" height="15.0638" rx="1" fill="#5856D6"/>
            <rect x="16" width="6" height="23.6716" rx="1" fill="#007AFF"/>
          </svg>
        </Link>
      </TableCell>
    </TableRow>
  )
}

function BestPerformers({ batch, forPersonalizedTemplate=false }) {
  const { id } = useParams();

  const [bestPerformers, setBestPerformers] = React.useState([]);
  const [topPerformers, setTopPerformers] = React.useState([]);
  const [LeaderboardCategory, setLeaderboardCategory] = React.useState(Categories.BEST_PERFORMER);

  const handleChange =(e)=>{
    e.preventDefault();
    setLeaderboardCategory(e.target.value)
  }

  React.useEffect(() => {
    if (id && batch) {
      getBestPerformersOfBatch(batch, 
        forPersonalizedTemplate ? {personalizedTemplateDraftId: id} : {templateId: id}
      ).then(users => {
          setBestPerformers(users?.bestPerformers);
          setTopPerformers(users?.topPerformers);
        })
        .catch(console.error);
    }
  }, [id, batch]);



  return (
    <ShadowBox width="35%">
      <Typography variant="h5" fontWeight={600}>Leaderboard</Typography>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' gap='5px' alignItems='center' >
          <img src="https://assets.languify.in/images/badgegold.svg" 
            alt="bronze" style={{width: "15px", height: "15px"}}
          />
          <Typography fontSize='12px' color='#02569D'>
            {LeaderboardOptions[LeaderboardCategory-1].name}
          </Typography>
        </Box>
        <Box>
        <Select
          options={LeaderboardOptions}
          value={LeaderboardCategory}
          onChange={handleChange}
        />
        </Box>
      </Box>
      <TableContainer sx={{ border: "1px solid lightgray", borderRadius: 1, mt: '10px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Student Name</TableCell>
              <TableCell align='center'>{ LeaderboardCategory === 1? 'Scores' : 'Attempts'}</TableCell>
              <TableCell align="center">Analytics</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              LeaderboardCategory === Categories.BEST_PERFORMER 
              ? 
              bestPerformers?.length ? 
                bestPerformers.map((row) => (
                <Rows row={row} LeaderboardCategory={LeaderboardCategory} />
                )) : (
                  <TableRow>
                    <TableCell component="td" scope="row">
                      No attempts yet!
                    </TableCell>
                  </TableRow>
                )
              : 
              topPerformers?.length ? 
                topPerformers.map((row) => (
                <Rows row={row} LeaderboardCategory={LeaderboardCategory} />
              )) : (
                <TableRow>
                  <TableCell component="td" scope="row">
                    No attempts yet!
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </ShadowBox>
  );
}

export default BestPerformers;