import Client from "./_client";

export const getInterviewDomains = async (domainFilters = {}) => {
  let url = "/interviewDomain?";

  Object.keys(domainFilters).forEach((key) => {
    if (domainFilters[key] !== undefined) url += `${key}=${domainFilters[key]}&`;
  });

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to fetch domains. Please try again"
    );

  return result.data || {};
}

export const getInterviewDomainById = async (domainId) => {
  const url = `/interviewDomain/${domainId}`;

  let result = await new Client({
    path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get domain. Please try again."
    );
  }

  return result.data;
}

export const getMostUsedDomain = async (batchId) => {
  const url = `/interviewDomain/most-used?batchId=${batchId}`;

  let result = await new Client({
    path: url,
  }).get();
  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get domain. Please try again."
    );
  }

  return result.data;
}
