import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { makeStyles } from '@mui/styles';

import ScreenHeading from 'components/ScreenHeading';
import Banner from 'components/PersonalizedInterview/Banner';
import CreateInterview from '../components/PersonalizedInterview/CreateInterview';
import PersonalizedInterviewList from 'components/PersonalizedInterview/PersonalizedInterviewList';


const useStyle = makeStyles(theme=>({
  box:{
    display:"flex",
    flexDirection:"column",
    gap:theme.spacing(2)
  }
}));

function PersonalizedInterviewScreen(props) {
  const classes=useStyle()

  const [refresh,setRefresh] = useState(false)

  return (
    <Box minHeight='calc(100vh - 76px)' className={classes.box}>
      <ScreenHeading title="Mock Interview" id="mock-interview"/>
      <Banner/>
      <CreateInterview refresh={refresh} setRefresh={setRefresh}/>
      <PersonalizedInterviewList refresh={refresh} setRefresh={setRefresh}/>
    </Box>
  );
}

export default PersonalizedInterviewScreen;