import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";

const ButtonMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 0,
        marginTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),
        minWidth: 180,
        backgroundColor: "white",
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: 0
        },
        "& .MuiMenuItem-root": {
            padding: "0px 16px",
            color: theme.palette.primary.main,
            backgroundColor: "white",
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                )
            }
        }
    }
}));

export default ButtonMenu;