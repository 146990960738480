import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DoughnutGraph from "components/Home/DoughnutGraph";
import ShadowBox from "components/ShadowBox";
import React from "react";
import { getAttendanceAndAverageScoreStats } from "services";
import {
  AverageScoresBgColors,
  AverageScoresBorderColors,
} from "components/Home/constants";
import { getAverageScore } from "utils";
import VerticalProgressBar from "components/BatchAnalytics/VerticalProgressBar";

const sx = {
  rangeBox: {
    display: "flex",
    mt:'15px', 
    justifyContent:'center',
    flexDirection:'column',
    px:'50px',
    gap:'10px'
  },
  avgBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px 12px',
    border: '1px solid #02569D',
    backgroundColor: '#E8F0FD'
  }
}

const AverageScoreLegends = [
  { range: "0-30", color: "#FF2D55" },
  { range: "31-60", color: "#FFCC00" },
  { range: "61-100", color: "#41D7A1" },
];

function BatchStats(props) {
  const { id } = useParams();

  const [attandanceStats, setAttandanceStats] = React.useState({
    "0-30": 0,
    "31-60": 0,
    "61-100": 0,
  });
  const [averageScoreStats, setAverageScoreStats] = React.useState({
    "0-30": 0,
    "31-60": 0,
    "61-100": 0,
  });
  const [averageScore, setAverageScore] = React.useState(0);
  const [averageAttandance, setAverageAttandance] = React.useState(0);

  const totalStudents = React.useMemo(()=>{
    let total = 0;
    AverageScoreLegends.map(data=>
      total += averageScoreStats[data.range]
    )

    return total;
  },[averageScoreStats, AverageScoreLegends])

  React.useEffect(() => {
    getAttendanceAndAverageScoreStats(id)
      .then(({ attendanceStats, averageScoreStats }) => {
        setAttandanceStats(attendanceStats);
        setAverageScoreStats(averageScoreStats);
        setAverageAttandance(attendanceStats.averageAttendance);
        setAverageScore(() =>
          getAverageScore(
            averageScoreStats?.score?.communication || 0,
            averageScoreStats?.score?.content || 0
          )
        );
      })
      .catch(console.errror);
  }, [id]);

  return (
    <ShadowBox width="65%" display="flex" justifyContent="space-evenly">
      <Box width='50%' display='flex' justifyContent='center' flexDirection='column'>
        <DoughnutGraph
          rootStyle={{ padding: 0 }}
          color="#0263FF"
          data={[
            attandanceStats["0-30"] || 0,
            attandanceStats["31-60"] || 0,
            attandanceStats["61-100"] || 0,
          ]}
          bgColors={AverageScoresBgColors}
          borderColors={AverageScoresBorderColors}
          labels={["No. of Students", "No. of Students", "No. of Students"]}
          title="Attendance"
          description="Avg attempt rate of all students in batch"
          score={averageAttandance || 0}
          isPercentage
        />
        <Box sx={sx.rangeBox}>
          <Typography fontSize={15} fontWeight={600} textAlign='center' whiteSpace='nowrap'>
            No. of students in the attendance range
          </Typography>
          {AverageScoreLegends.map((x,index) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent='space-between'
            >
              <Box display="flex" alignItems="center" gap='10px'>
                <Box width={20} bgcolor={x.color} p={0.5} borderRadius={8} />
                <Typography fontSize={12} fontWeight={600}>
                  {x.range}
                </Typography>
              </Box>
              <Typography fontSize={12}>
                {attandanceStats[x.range]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width='50%' display='flex' justifyContent='center'>
        <Box height='60%'>
          <VerticalProgressBar
            averageScoreStats={averageScoreStats || {}}
            averageScoreLegends={AverageScoreLegends || []}
            xAsix={'Score Range'}
            yAsix={'No. of Students'}
            totalStudents={totalStudents}
          />
          <Box display='flex' justifyContent='center' mt='20px'>
            <Box sx={sx.avgBox}>
              <Typography color='#02569D' fontWeight={600} fontSize='16px'>
                {averageScore || 0}
              </Typography>
              <Typography fontSize='15px' fontWeight={600}>
                Batch Average
              </Typography>
              <Typography fontSize='12px' fontWeight={500} mt={0}>
                Score consisting of {totalStudents} students
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ShadowBox>
  );
}

export default BatchStats;
