import * as Yup from "yup";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PasswordField from 'components/PasswordField';

import { useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'contexts';
import { LoadingButton } from '@mui/lab';
import { changePassword } from 'services';
import { useNavigate } from 'react-router-dom';

const sx = {
    label: {
        fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: 15,
        lineHeight: "25px", width: "40%", flexShrink: 0,
    },
    heading: {
        fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: 16,
        mr: 1,
    },
    buttonContainer: {
        display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2
    }
};

function Password(props) {
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        errors,
        touched
    } = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: "",
            confirmedPassword: "",
        },
        validationSchema: new Yup.ObjectSchema({
            currentPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .required("Required"),
            newPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .notOneOf([Yup.ref('currentPassword'), null], "New Password has to be different than current Password")
                .required("Required"),
            confirmedPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .oneOf([Yup.ref('newPassword'), null], "Does not match with new password!")
                .required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);

                await changePassword({
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword
                });

                resetForm();
                snackbar.showSnackbar("Password is changed successfully!");
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error');
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <Box >
            <form onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                <Typography sx={sx.label}>Current password*</Typography>
                <PasswordField
                    id="currentPassword"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleChange}
                    spellCheck="false"
                    error={touched.currentPassword && errors.currentPassword}
                    helperText={(touched.currentPassword && errors.currentPassword)}
                    sx={{ width: '50%', }}
                /> <br />

                <Typography sx={sx.label}>New password*</Typography>
                <PasswordField
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    spellCheck="false"
                    error={touched.newPassword && errors.newPassword}
                    helperText={(touched.newPassword && errors.newPassword)}
                    sx={{ width: '50%', }}
                /> <br />

                <Typography sx={sx.label}>Confirmed Password*</Typography>
                <PasswordField
                    id="confirmedPassword"
                    name="confirmedPassword"
                    value={values.confirmedPassword}
                    onChange={handleChange}
                    spellCheck="false"
                    error={touched.confirmedPassword && errors.confirmedPassword}
                    helperText={(touched.confirmedPassword && errors.confirmedPassword)}
                    sx={{ width: '50%', }}
                />

                <LoadingButton
                    sx={{ mt: 4, textTransform: "none", width: 100 }}
                    loading={loading}
                    variant="contained"
                    type='submit'
                    size="small"
                >
                    Submit
                </LoadingButton>
            </form>
        </Box>
    );
}

export default Password;