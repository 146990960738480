import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Typography from '@mui/material/Typography';
import CenterFlexBox from 'components/CenterFlexBox';
import { Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip);

const styles = {
    root: {
        padding: '16px', borderRadius: 8,
    },
    container: { position: 'relative', display: 'flex', justifyContent: 'center' },
    doughnut: {
        background: `radial-gradient(circle at center, rgba(0,0,0,0) 0, 
        rgba(0,0,0,0) 52%, rgba(0,0,0,0.2) 52%, rgba(0,0,0,0.2) 60%, 
        rgba(0,0,0,0.2) 69%, rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%)`
    },
    percentageContainer: {
        width: "100%", position: "absolute", top: 0, height: "100%",
        pointerEvents: 'none', zIndex: 0,
    },
    percentage: {
        textAlign: 'center',
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column'
    },
    percentageSign: {
        fontSize: "50%",
    },
    label: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: '12px',
        marginTop: 14,
    }
};

const options = {
    plugins: {
        tooltip: {
            display: true,
        },
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: true,
    responsive: true,
};

export default function DoughnutGraph({
    muiltiProgress = true, data, bgColors, borderColors, labels, score, color, title,
    description, rootStyle = {}, isPercentage
}) {
    const _data = React.useMemo(() => {
        const colors = [color, `${color}22`];

        return {
            labels: labels,
            datasets: [
                {
                    data: data === undefined ? [+score, 100 - score] : data,
                    backgroundColor: (muiltiProgress) ? bgColors : colors,
                    borderColor: (muiltiProgress) ? borderColors : colors,
                    borderWidth: 1,
                    cutout: "75%"
                },
            ],
        };
    }, [data, bgColors, borderColors, labels, score, color, muiltiProgress]);

    return (
        <div style={{ ...styles.root, ...rootStyle }}>
            <div style={styles.container}>
                <Box width='140px' display='flex' justifyContent='center'>
                  <Doughnut
                      data={_data}
                      options={options}
                      style={data ? styles.doughnut : {}}
                  />
                {(score >= 0) && <CenterFlexBox sx={styles.percentageContainer}>
                    <Box style={styles.percentage}> 
                        <Typography fontSize='20px' fontWeight='600'>
                            {score}
                            <span>
                              {isPercentage && "%"}
                            </span>
                        </Typography>
                        <Typography fontSize='12px' fontWeight='600'>
                          {title || ''}
                        </Typography>
                    </Box>
                </CenterFlexBox>}
                </Box>
            </div>
            <Typography textAlign="center" style={styles.label}>
                {description}
            </Typography>
        </div>
    );
}