import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CenterFlexBox from 'components/CenterFlexBox';

import { Box, IconButton, Typography } from '@mui/material';
import { formatDate } from 'utils';

export function ImageProctorDialog({
    open,
    onClose,
    proctoringRecords = []
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth={'md'} style={{ borderRadius: 16 }}
        >
            <DialogTitle id="responsive-dialog-title" >
                <CenterFlexBox justifyContent='space-between'>
                    Face Proctoring
                    <IconButton onClick={onClose} >
                        <CloseIcon />
                    </IconButton>
                </CenterFlexBox>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    <Box display='flex' flexWrap='wrap' gap={2} maxWidth={630}>
                        {
                            proctoringRecords.map((proctoringRecord, index) => (
                                (proctoringRecord.type === 'IMAGE_PROCTOR') ?
                                    <Box key={index}>
                                        <img
                                            width={300} style={{ borderRadius: 16 }}
                                            src={proctoringRecord.location}
                                            alt="capturedImage"
                                        />
                                        <CenterFlexBox justifyContent='flex-start'>
                                            <AccessTimeIcon fontSize='small' /> &nbsp;
                                            <Typography variant='body2' >
                                                Timestamp : &nbsp;
                                                {formatDate(proctoringRecord.timestamp, 'DD-MM-YYYY, hh:mm:ss a')}
                                            </Typography>
                                        </CenterFlexBox>
                                    </Box> : null
                            ))
                        }
                    </Box>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}

export default ImageProctorDialog;