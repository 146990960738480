import Client from "./_client";
import Session from "utils/Session";

export const getDraftForUser = async (draftFilters = {}) => {
    let url = `/draft/${Session.userId}?`;

    Object.keys(draftFilters).forEach((key) => {
        if (draftFilters[key]) url += `${key}=${draftFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
};

export const saveDraft = async (id, draft = {}) => {
    let result;

    if (id) {
        result = await new Client({
            path: `/draft/${id}?`,
            payload: draft
        }).put();
    } else {
        result = await new Client({
            path: "/draft",
            payload: draft,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
};

export const saveTemplateFromDraft = async (draftId, templateId) => {
    const result = await new Client({
        path: `/draft/${draftId}/${templateId}`,
        payload: {}
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to save the template. Please try again."
        );
    }

    return result?.data;
};

export const getDrafts = async (draftFilters = {}) => {
    let url = `/draft?include=domain,user&`;

    Object.keys(draftFilters).forEach((key) => {
        if (draftFilters[key]) url += `${key}=${draftFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
};

export const deleteDraftById = async (id) => {
    const result = await new Client({
        path: `/draft/${id}?`,
    }).delete();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to delete the draft. Please try again."
        );
    }

    return result?.data;
};

export const getCompletedSteps = async (id) => {
    const result = await new Client({
        path: `/draft/${id}/completed-steps`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get completed steps for draft"
        );
    }

    return result?.data;
};

export const getDraftById = async (draftId) => {
    let url = `/draft/getDraft/${draftId}`;

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }

    return result?.data;
}

export const getPersonalizedInterview = async (draftFilters = {}) => {
    let url = `/interviewTemplate/personalized?`;
    Object.keys(draftFilters).forEach((key) => {
        if (draftFilters[key]) url += `${key}=${draftFilters[key]}&`;
    });
    const result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get draft. Please try again."
        );
    }
    return result?.data;
  };