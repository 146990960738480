import { Session } from "utils";
import Client from "./_client";

export const getFeedbacks = async (feedbackFilters = {}) => {
    let url = "/feedback?";

    Object.keys(feedbackFilters).forEach((key) => {
        if (feedbackFilters[key]) url += `${key}=${feedbackFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to fetch templates!");

    return result.data || [];
}

export const addFeedback = async (suggestion) => {
    let url = "/feedback?";

    const result = await new Client({
        path: url,
        payload: {
            user: Session.userId,
            rating: 3,
            comment: suggestion,
            application: 'client',
        }
    }).post();

    if (!(result.ok || result.code === 200))
        throw new Error(result.message || "unable to save feedback!");

    return result.data || [];
}