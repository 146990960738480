import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Typography from '@mui/material/Typography';

import CenterFlexBox from 'components/CenterFlexBox';

ChartJS.register(ArcElement);
ChartJS.register(Tooltip);

const styles = {
  root: {
    boxShadow: "0px 0px 2px rgba(0,0,0,0.25)", padding: '8px 32px',
    borderRadius: 8,
  },
  container: { position: 'relative', },
  percentageContainer: {
    width: "100%", position: "absolute", top: 0, height: "100%",
    pointerEvents: 'none',
  },
  percentage: {
    fontSize: '1.5vw', fontWeight: 600, width: 'fit-content',
  },
  percentageSign: { fontSize: "70%" },
  label: {
    fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: 15,
    marginTop: 8,
  },
  description: {
    fontFamily: "Inter", fontStyle: "normal", fontWeight: 500, fontSize: 13,
    marginTop: 8, color: "#767676",
  }
};

function ScoreCard({
  tooltip, label, score, color, description, style = {}, showTooltip = false,
  isPercentage = false,
}) {
  const data = React.useMemo(() => {
    const colors = [color, `${color}22`];
    return {
      datasets: [
        {
          data: [+score, 100 - score],
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 0,
          cutout: "75%"
        },
      ],
    };
  }, [score, color]);

  return (
    <div style={{ ...styles.root, ...style }} title={tooltip}>
      <div style={styles.container}>
        <Doughnut
          data={data}
          options={{
            plugins: { tooltip: showTooltip, legend: false },
            maintainAspectRatio: true,
            responsive: true
          }
          }
        />
        <CenterFlexBox sx={styles.percentageContainer}>
          <p style={styles.percentage}>
            {score}{isPercentage && <span style={styles.percentageSign}>%</span>}
          </p>
        </CenterFlexBox>
      </div>
      <Typography textAlign="center" style={styles.label}>
        {label}
      </Typography>
      {description && (
        <Typography textAlign="center" style={styles.description}>
          {description}
        </Typography>
      )}
    </div>
  );
}

export default ScoreCard;