import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CenterFlexBox from 'components/CenterFlexBox';
import CSVReaderInput from 'components/CSVReaderInput';
import CustomButton from 'components/CustomButton';
import GSheetImportButton from 'components/GSheetImportButton';
import Table from 'components/SimpleTable';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

import { useLoading, useSnackbar } from 'contexts';
import { addStudentsInBatch, calculateAccessInBatch, getUserBalanceInfo, updateStudentSubscription } from 'services';
import { MenuItem, Select, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { Session } from 'utils';
import { AccessControl, AccessControlData } from 'editors/Batch/constants';
import InfoIconWithTooltip from 'components/InfoIconWithTooltip';

const sx = {
  reduceBtn: { 
    fontSize: '12px', 
    textDecoration: 'underline', 
    paddingLeft: '10px',
    color: '#013B6B',
    cursor: 'pointer',
  }
}

const UserSchema = Yup.object().shape({
  users: Yup.array(Yup.object().shape({
    name: Yup
      .string()
      .min(3, "Minimum 3 characters required")
      .max(50, "You can enter upto 50 characters")
      .required('Name is required'),
    email: Yup
      .string()
      .email('Invalid email')
      .required('Email is required')
  }))
  .test({
    test: array => {
      if (!array) return true;
      const emailSet = new Set(); 
      for (const user of array) {
        if (emailSet.has(user.email)) {
          return false; 
        }
        emailSet.add(user.email);
      }
      return true; 
    },
    message: 'Emails must be unique'
  })
});

function InviteUsers({ open, onClose, batchId, inviteUsersData=[], setInviteUsersData, action, isPayPerUser,setFailedEmailData}) {
  const loading = useLoading();
  const snackbar = useSnackbar();
  
  const [amount, setAmount] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [selectSameAccess, setSelectSameAccess] = React.useState(false);
  const [sameAccess, setSameAccess] = React.useState(1);
  const [disableInvite, setDisableInvite] = React.useState(false);
  const [showCalculateBtn, setShowCalculateBtn] = React.useState(false);
  const [userBalance, setUserBalance] = React.useState(0);
  const [oldUsers, setOldUsers] = React.useState(false);
  const [reduce, setReduce] = React.useState(false);
  const [showUserCredits, setShowUserCredits] = React.useState(false);
  const [errorRows, setErrorRows] = React.useState([]);

  const setErrors = (error) => {
    let errorArray = [];
    error.inner.forEach((err,index)=>{
      const rowIndex = parseInt((err.path).split('[')[1].split(']')[0]);
      errorArray.push(rowIndex);
    });
    setErrorRows(errorArray);
  }

  const sendInvites = async () => {
    loading.show();
    try {
      const valid = await UserSchema.validate({ users: inviteUsersData }, { abortEarly: false});
      if (!valid) {
        snackbar.error(
          "Seems like you have some invalid data!! Please check the student list"
        );
        return;
      }

      const modifiedUsers = inviteUsersData.map((userData) => {
        const user = (userData.groupId) ?
          { ...userData, metadata: { groupId: userData.groupId } } :
          userData;
        delete user.groupId;
        return user;
      });

      const { failedEmails } =
        await addStudentsInBatch(batchId, modifiedUsers, amount);

      if (failedEmails.length) {
        setError({ failedEmails });
        setFailedEmailData(failedEmails);
        snackbar.showSnackbar(
          `${failedEmails.length} Invitations not sent!`,
          "info"
        );
      } else {
        snackbar.showSnackbar("Invitations sent successfully!!", "success");
      }

      setErrorRows([]);
      handleClose(null, true);
    } catch (error) {
      console.error("DEBUG::sendMail", error);
      snackbar.showSnackbar(error.inner[0]?.errors[0] || error.message, "error");
      setErrors(error);
    } finally {
      loading.hide();
    }
  };

  const upgradeSubscription = async () => {
    loading.show();
    try {
      const valid = await UserSchema.validate({ users: inviteUsersData }, { abortEarly: false});
      if (!valid) {
        snackbar.error(
          "Seems like you have some invalid data!! Please check the student list"
        );
        return;
      }

      await updateStudentSubscription(
          batchId, inviteUsersData, amount, action === AccessControl.UPGRADE, reduce
          );
      snackbar.showSnackbar("Subscription Updated successfully!!", "success");

      handleClose(null, true);
    } catch (error) {
      snackbar.showSnackbar(error.inner[0]?.errors[0] || error.message, "error");
      setErrors(error);
    } finally {
      loading.hide();
    }
  };

  const calculateAccess = async () => {
    loading.show();
    try {
      const valid = await UserSchema.validate({ users: inviteUsersData }, { abortEarly: false});
      if (!valid) {
        snackbar.error(
          "Seems like you have some invalid data!! Please check the student list"
        );
        return;
      }

      const accessResult = await calculateAccessInBatch(
          batchId, inviteUsersData, action !== AccessControl.GRANT, reduce
          );
          
      setInviteUsersData([...accessResult.newStudents, ...accessResult.existingStudents]);
      accessResult?.existingStudents.length ? setOldUsers(true) : setOldUsers(false);
      setAmount(accessResult.amount);
      setShowUserCredits(true);
      setDisableInvite(false);
      setShowCalculateBtn(false);
      setErrorRows([]);
    } catch (error) {
      snackbar.showSnackbar(error.inner[0]?.errors[0] || error.message, "error");
      setErrors(error);
    } finally {
      loading.hide();
    }
  };

  const getBalance = async () => {
    loading.show();
    try {
      if(!isPayPerUser){
        setUserBalance(-99)
      }
      else{
        const balanceInfo = await getUserBalanceInfo(Session.userId);
        setUserBalance(balanceInfo.remainingAmount)
      }
    } catch (error) {
      snackbar.showSnackbar(error.message, "error");
    } finally {
      loading.hide();
    }
  };

  const handleImportSuccess = (rows) => {
    setErrorRows([]);
    rows = rows.map(row=>{
      const accessPeriod = parseInt(row?.access) || 1
      return { 
        ...row, accessPeriod: accessPeriod, onboardedAt: dayjs().format(),
        accessExpiresOn: dayjs().add(accessPeriod * 30,'day').format(),
        credits: accessPeriod
      }
    })
    setInviteUsersData([...inviteUsersData, ...rows]);
    if(isPayPerUser){
      setDisableInvite(true);
      setShowCalculateBtn(true);
      setShowUserCredits(false);
    }
  };

  const handleClose = (_, reason) => {
    if (reason !== "backdropClick") onClose(reason);
  }

  const handleCellEdit = (edit) => {
    setErrorRows([]);
    if(isPayPerUser){
      setDisableInvite(true);
      setShowCalculateBtn(true);
      setShowUserCredits(false);
    }
    setInviteUsersData((ud) => {
      const { index, field, value } = edit;
      const newUsersData = [...ud];

      newUsersData[index][field] = value;

      return newUsersData;
    });
  };

  const handleMonthChange = (index,field,value) => {
    setErrorRows([]);
    if(isPayPerUser){
      setDisableInvite(true);
      setShowCalculateBtn(true);
      setShowUserCredits(false);
    }

    setInviteUsersData((ud) => {
      const newUsersData = [...ud];

      newUsersData[index][field] = value;
      setSelectSameAccess(false);
      newUsersData[index]['credits'] = value;
      newUsersData[index]['accessPeriod'] = value;
      if(action !== AccessControl.GRANT){
        newUsersData[index]['accessPeriod'] = value;
        newUsersData[index]['previousExpiry'] = 
          dayjs(newUsersData[index]['previousExpiry']).isBefore(dayjs()) 
            ? dayjs() : newUsersData[index]['previousExpiry']
      }
      newUsersData[index]['accessExpiresOn'] = 
        dayjs(
          (action !== AccessControl.GRANT && dayjs(newUsersData[index]['previousExpiry']).isBefore(dayjs()))
            ? new Date()
            :
            newUsersData[index][action !== AccessControl.GRANT 
              ? 'previousExpiry' : 'onboardedDate']
            )
            [ action === AccessControl.UPGRADE && reduce 
              ? 'subtract' : 'add'
            ](value*30,'day').format();

      return newUsersData;
    });
  }

  const handleReduce = () => {
    isPayPerUser && setDisableInvite(true);
    setInviteUsersData((ud) => {
      const newUsersData = []
      ud.forEach(user => {
        newUsersData.push({
          ...user, accessExpiresOn: user.previousExpiry, accessPeriod: 0, 
          credits: 0, upgradePeriod: 0
        });
      });

      return newUsersData;
    });
    setReduce(!reduce);
  }

  const handleImportError = (error) => setError(error.message);

  const deleteRow = (index) => {
    setErrorRows([]);
    setInviteUsersData((ud) => {
      const newUsersData = [...ud];

      if (index === 0) {
        newUsersData.shift();
      } else if (index === ud.length - 1) {
        newUsersData.pop();
      } else {
        newUsersData.splice(index, 1);
      }

      return newUsersData;
    });
  };

  React.useEffect(()=>{
    if(!inviteUsersData.length && action !== AccessControl.GRANT){
      handleClose();
    }
  },[inviteUsersData])

  const handleSameAccess = (value) => {
    if(isPayPerUser){
      setDisableInvite(true);
      setShowCalculateBtn(true);
      setShowUserCredits(false);
    }

    setSameAccess(value);
    const newData =  inviteUsersData.map((userObj) => {
      const upgradePeriod = 
        reduce 
          ? dayjs(userObj?.previousExpiry).subtract(value*30,'day').isBefore(dayjs())
            ? Math.floor(dayjs(userObj?.previousExpiry).diff(dayjs(),'day')/30) : value
          : value

      return {
        ...userObj,
        accessPeriod: upgradePeriod,
        credits: upgradePeriod,
        upgradePeriod: upgradePeriod,

        accessExpiresOn: dayjs(userObj[action === AccessControl.UPGRADE 
          ? 'previousExpiry' : 'onboardedDate']
          )
          [action === AccessControl.UPGRADE  && reduce 
            ? 'subtract' : 'add'
          ](upgradePeriod*30,'day').format()
        }
      })
      setErrorRows([]);
      setInviteUsersData(newData)
  }

  const addRow = () => {
    setErrorRows([]);
    setInviteUsersData(ud => {
      return [...ud, 
        { 
          name: "", 
          email: "", 
          accessPeriod: 1, 
          onboardedAt: dayjs().format(), 
          accessExpiresOn: dayjs().add(30,'day').format(),
          credits: 1
        }
      ];
    });

    if(isPayPerUser){
      setDisableInvite(true);
      setShowCalculateBtn(true);
      setShowUserCredits(false);
    }
  };
  
  React.useEffect(() => {
    if (open) {
      getBalance();
      setSelectSameAccess(false);
      setReduce(false);
      setError(null);
      setOldUsers(false);
      setShowUserCredits(false);
      setAmount(0);
      setErrorRows([]);
      isPayPerUser && setDisableInvite(action !== AccessControl.GRANT);
      action === AccessControl.RESUME && setShowCalculateBtn(true);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="responsive-dialog-title">
        <Box>
          { AccessControlData[action]?.title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography color="#747575" variant="caption">
          { AccessControlData[action]?.description}
          </Typography>
          {
            isPayPerUser ?
            <Typography variant='caption' textAlign='center'>
              <IconButton>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 22 17" fill="none">
                <path d="M14.0005 0.5C15.5827 0.5 17.1294 0.969192 18.445 1.84824C19.7606 2.72729 20.786 3.97672 21.3915 5.43853C21.997 6.90034 22.1554 8.50887 21.8468 10.0607C21.5381 11.6126 20.7761 13.038 19.6573 14.1569C18.5385 15.2757 17.113 16.0376 15.5612 16.3463C14.0093 16.655 12.4008 16.4965 10.939 15.891C9.47719 15.2855 8.22776 14.2602 7.34871 12.9446C6.46966 11.629 6.00047 10.0823 6.00047 8.5C6.00047 6.37827 6.84332 4.34344 8.34361 2.84315C9.84391 1.34285 11.8787 0.5 14.0005 0.5ZM2.00047 8.5C2.00189 9.7396 2.38721 10.9483 3.10347 11.96C3.81973 12.9718 4.83177 13.7368 6.00047 14.15V16.24C4.28848 15.7925 2.77314 14.7901 1.69162 13.3896C0.610105 11.9891 0.0234375 10.2695 0.0234375 8.5C0.0234375 6.7305 0.610105 5.01093 1.69162 3.61041C2.77314 2.20989 4.28848 1.20746 6.00047 0.76V2.85C4.83177 3.2632 3.81973 4.02824 3.10347 5.03995C2.38721 6.05167 2.00189 7.2604 2.00047 8.5Z" fill="#21649D"/>
                </svg>
              </IconButton>
              <span style={{fontWeight : 600}}>Total Balance: </span> 
              <span style={{ color: amount>userBalance && !reduce ? '#FF2D55' : '#02569D', fontWeight: 500 }}>
                {userBalance} Licence
              </span>
              <span style={{ marginLeft: '5px'}}>
                <InfoIconWithTooltip
                    placement='right'
                    title={
                      amount > userBalance 
                      ? 'To get more Credits, reach out to us at growth@languify.in or contact us at +91-9969854525'
                      : ''
                    }
                    style={{ fontSize: '14px'}}
                />
              </span>
            </Typography>
            : null
          }
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 380 }}>
        <Table
          cols={
            [
              'S.No.', 'Name*', 'E-mail*', 'Access Period', 
              action !== AccessControl.GRANT 
              ? action === AccessControl.UPGRADE && reduce 
                ? 'Reduce Access By' : 'Increase Access By'
              : null,
              action === AccessControl.UPGRADE ? 'Current Access'  : 'Onboarding Date', 
              action === AccessControl.UPGRADE ? 'Updated Access' : 'End Date', 
              isPayPerUser ? 'Licence' : null
            ]
          }
          rows={inviteUsersData}
          onCellEdit={handleCellEdit}
          handleMonthChange={handleMonthChange}
          keepSameAccess={ selectSameAccess? sameAccess : false}
          inviteAction={action}
          reduce={reduce}
          isPayPerUser={isPayPerUser}
          showUserCredits={showUserCredits}
          errorRows={errorRows}
          TableContainerProps={{
            style: { border: '1px solid #00569deb', maxHeight: 300 }
          }}
          Actions={({ index, row }) => (
            <IconButton
              onClick={() => deleteRow(index)}
              type="button"
            >
              <DeleteIcon color="error" style={{ fontSize: '20px'}} />
            </IconButton>
          )}
        />
        <Box mt='10px' display='flex' justifyContent='space-between' alignItems='top'>
          <Box>
          <Typography fontSize='12px' style={{display:'flex'}}>
          {
            oldUsers ? 
            <Box display='flex' alignItems='start'>
              <span style={{ display:'flex', alignItems: 'center' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.1987 3.77954C14.032 4.79288 14.5454 6.02621 14.6654 7.33288H13.292C13.1587 6.37954 12.772 5.47954 12.172 4.73288L13.1987 3.77954ZM8.66536 1.36621C9.97203 1.49288 11.2054 1.99954 12.2187 2.83954L11.2654 3.79288C10.512 3.19954 9.61203 2.82621 8.66536 2.69954V1.36621ZM7.33203 1.37288C6.02536 1.50621 4.79203 2.01954 3.7787 2.84621L4.73203 3.79288C5.48536 3.20621 6.38536 2.82621 7.33203 2.70621V1.37288ZM2.8387 3.77954L3.75203 4.70621V4.73288C3.16536 5.48621 2.78536 6.38621 2.66536 7.33288H1.33203C1.47203 6.02621 1.9987 4.78621 2.8387 3.77954ZM1.33203 9.33288V12.6662L2.3987 11.5995C3.58536 13.4462 5.64536 14.6662 7.9987 14.6662C11.212 14.6662 13.912 12.3662 14.532 9.33288H13.1654C12.572 11.6329 10.4787 13.3329 7.9987 13.3329C6.03203 13.3329 4.2587 12.2595 3.33203 10.6662L4.66536 9.33288H1.33203ZM7.9987 11.3329L9.0387 9.05288L11.332 7.99954L9.0387 6.95954L7.9987 4.66621L6.95203 6.95954L4.66536 7.99954L6.95203 9.05288L7.9987 11.3329Z" fill="#02569D"/>
              </svg>
              </span>
              {
                !reduce ?
                <>&nbsp;The user already has access to other batches for either partial or full specified period, resulting in fewer credits being deducted</>
                : 
                <>&nbsp;The user already has access to other batches for either partial or full specified period, resulting in fewer credits being refunded</>
              }
            </Box>
            : null
          }
          </Typography>
          <Typography fontSize='12px' style={{display:'flex', alignItems: 'center' }}>
          {
            errorRows.length ? 
            <>
              <span style={{ color:'#FF2D55', display:'flex', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M7.33203 10.4997H8.66536V11.833H7.33203V10.4997ZM7.33203 5.16634H8.66536V9.16634H7.33203V5.16634ZM7.9987 1.83301C4.31203 1.83301 1.33203 4.83301 1.33203 8.49967C1.33203 10.2678 2.03441 11.9635 3.28465 13.2137C3.90371 13.8328 4.63864 14.3238 5.44747 14.6589C6.25631 14.9939 7.12322 15.1663 7.9987 15.1663C9.76681 15.1663 11.4625 14.464 12.7127 13.2137C13.963 11.9635 14.6654 10.2678 14.6654 8.49967C14.6654 7.6242 14.4929 6.75729 14.1579 5.94845C13.8229 5.13961 13.3318 4.40469 12.7127 3.78563C12.0937 3.16657 11.3588 2.67551 10.5499 2.34048C9.74108 2.00545 8.87418 1.83301 7.9987 1.83301ZM7.9987 13.833C6.58421 13.833 5.22766 13.2711 4.22746 12.2709C3.22727 11.2707 2.66536 9.91416 2.66536 8.49967C2.66536 7.08519 3.22727 5.72863 4.22746 4.72844C5.22766 3.72824 6.58421 3.16634 7.9987 3.16634C9.41319 3.16634 10.7697 3.72824 11.7699 4.72844C12.7701 5.72863 13.332 7.08519 13.332 8.49967C13.332 9.91416 12.7701 11.2707 11.7699 12.2709C10.7697 13.2711 9.41319 13.833 7.9987 13.833Z" fill="#FF0031"/>
                </svg>
                &nbsp;Error: &nbsp;
              </span>
              Either empty fields or invalid/ duplicate email. Please check and correct.
            </>
            : null
          }
          </Typography>
          </Box>
          {
            action === AccessControl.UPGRADE ?
              <span style={sx.reduceBtn} 
                onClick={handleReduce}
              >
                {reduce
                  ? <>Increase&nbsp;Access&nbsp;Instead?</>
                  : <>Reduce&nbsp;Access&nbsp;instead?</>
                }
              </span>
              : null
          }
        </Box>
        {
          isPayPerUser && inviteUsersData.length && showCalculateBtn ?
          <Box mt='10px' display='flex' justifyContent='flex-end' alignItems='top'>
            <CustomButton
              type="button"
              variant="contained"
              style={{
                padding: '5px 8px', borderRadius: '5px',
                display: "flex", fontSize: '17px', fontWeight: 500
              }}
              disabled={!inviteUsersData.length}
              onClick={calculateAccess}
              title="Check Utilization"
            >
              <CalculateOutlinedIcon style={{ fontSize: '18px', marginRight: '5px'}}/>
              Check&nbsp;Utilization
            </CustomButton>
          </Box>
          : null
        }
        {
          action === AccessControl.GRANT ?
            <Box display="flex" alignItems="center">
              <Box border="1px dashed #747575" flexGrow={1} />
              <CustomButton
                type="button"
                variant="contained"
                style={{
                  borderRadius: "50%", minWidth: 30, padding: 5,
                  display: "flex"
                }}
                onClick={addRow}
                title="Add User"
              >
                <AddIcon />
              </CustomButton>
              <Box border="1px dashed #747575" flexGrow={1} />
            </Box>
            : null
        }
        <Box display='flex' justifyContent='space-between' alignItems='center' my='5px'>
          <Typography fontSize='12px' display='flex' alignItems='center'>
            <input 
              type='checkbox' name='allUser' id='allUser' checked={selectSameAccess}
              onChange={()=>{
                setSameAccess(1);
                setSelectSameAccess(!selectSameAccess);
                handleSameAccess(1);
              }}
            />
            <label htmlFor='allUser'>
              {
                action !== AccessControl.GRANT 
                ? reduce ? 'Reduce same access for all Users'
                  : 'Increase same access for all Users'

                : 'Keep same access for all Users'
              }
            </label>
          </Typography>
          {
            isPayPerUser && (inviteUsersData.length && !showCalculateBtn) ? 
            <Typography fontSize='12px'>
              <IconButton>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 22 17" fill="none">
                <path d="M14.0005 0.5C15.5827 0.5 17.1294 0.969192 18.445 1.84824C19.7606 2.72729 20.786 3.97672 21.3915 5.43853C21.997 6.90034 22.1554 8.50887 21.8468 10.0607C21.5381 11.6126 20.7761 13.038 19.6573 14.1569C18.5385 15.2757 17.113 16.0376 15.5612 16.3463C14.0093 16.655 12.4008 16.4965 10.939 15.891C9.47719 15.2855 8.22776 14.2602 7.34871 12.9446C6.46966 11.629 6.00047 10.0823 6.00047 8.5C6.00047 6.37827 6.84332 4.34344 8.34361 2.84315C9.84391 1.34285 11.8787 0.5 14.0005 0.5ZM2.00047 8.5C2.00189 9.7396 2.38721 10.9483 3.10347 11.96C3.81973 12.9718 4.83177 13.7368 6.00047 14.15V16.24C4.28848 15.7925 2.77314 14.7901 1.69162 13.3896C0.610105 11.9891 0.0234375 10.2695 0.0234375 8.5C0.0234375 6.7305 0.610105 5.01093 1.69162 3.61041C2.77314 2.20989 4.28848 1.20746 6.00047 0.76V2.85C4.83177 3.2632 3.81973 4.02824 3.10347 5.03995C2.38721 6.05167 2.00189 7.2604 2.00047 8.5Z" fill="#21649D"/>
                </svg>
              </IconButton>
              <span style={{fontWeight : 600}}>
                { reduce 
                  ? 'Refunded Total- '
                  : 'Utilization Total- '
                }
              </span> 
              <span style={{ color: '#02569D', fontWeight: 500 }}>{amount || 0} Licence</span>
            </Typography>
            : null
          }
        </Box>
        {
          selectSameAccess &&
          <Box display='flex' gap='5px' alignItems='center'>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={sameAccess}
              sx={{ height: '28px', width: '66px', fontSize: '14px', backgroundColor: '#F4F5F5' }}
              size="small"
              onChange={(e)=>handleSameAccess(e.target.value)}
            >
              {Array.from({ length: 12 }, (_, index) => index + 1).map((v, i) => (
                  <MenuItem key={i} value={v} style={{ fontSize: '12px'}}>
                      {v}
                  </MenuItem>
              ))}
            </Select>
            <Typography color='#02569D' fontWeight={500} fontSize='12px'> Months </Typography>
          </Box>
        }
        {
          action === AccessControl.GRANT ?
          <>
            <Typography variant="h6" mt='10px'>Import Students</Typography>
            <Typography color="#747575" variant="caption" component="p">
              Import students from google sheets/ CSV file. Please ensure:
            </Typography>
            <Typography fontSize={12} fontWeight={400} color="primary">
              <ul style={{ margin: '0px' }}>
                <li>There must be columns named: <b>name, email, access</b></li>
              </ul>
            </Typography>
            <DialogActions style={{
              display: 'flex', justifyContent: 'space-between', padding:'5px 0px'
            }}>
              <CenterFlexBox gap={1}>
                <GSheetImportButton
                  sheetProps={{
                    onSuccess: handleImportSuccess,
                    onError: setError,
                    title: "Import Users",
                    sampleSheetUrl: "https://docs.google.com/spreadsheets/d/1q7yMn2prA2Awh1FpHGrASPMUWFtI4scKIfpS9Yc0wsY/edit#gid=0"
                  }}
                />
                <div>
                  <CSVReaderInput
                    onData={handleImportSuccess}
                    onError={handleImportError}
                    label="Import from CSV"
                    size='small'
                  />
                </div>
              </CenterFlexBox>
            </DialogActions>
          </>
          : null
        }
        <Box px={2}>
          {error && (
            <details>
              <summary>
                <Typography color="error" component="span" variant="body2">
                  Following invites not sent:
                </Typography>
              </summary>
              <ul>
                {error?.unsuccessful?.map((data, index) => (
                  <details key={index}>
                    <summary>{data.email}</summary>
                    <span>Error: {data.reason.errorMessage}</span>
                  </details>
                ))}
              </ul>
            </details>
          )}
        </Box>
          <Tooltip
            title={
              disableInvite && isPayPerUser
                ? "Calculate access for adding students to the batch"
                : ( isPayPerUser && amount>userBalance)
                  ? 'To get more Credits, reach out to us at growth@languify.in or contact us at +91-9969854525' 
                  : ''
            }
          >
          <DialogActions style={{ padding:'5px 0px'}}>
            <CustomButton
              variant='contained'
              color={'primary'}
              width='100%'
              disabled={
                (!inviteUsersData.length || disableInvite) 
                ? true : (isPayPerUser && !reduce)? amount > userBalance : false
                }
              onClick={action === AccessControl.UPGRADE ? upgradeSubscription : sendInvites}
            >
              { AccessControlData[action]?.buttonText }
            </CustomButton>
          </DialogActions>
          </Tooltip>
        <Typography fontSize='12px' textAlign='center'>
          { AccessControlData[action]?.instruction }
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default InviteUsers;