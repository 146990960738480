import * as yup from 'yup';

import CopyIcon from '@mui/icons-material/ContentCopy';
import ImageIcon from '@mui/icons-material/CameraAltOutlined';
import TabMoveIcon from '@mui/icons-material/ExitToAppOutlined';

export const TemplateTypes = [
    { label: "Practice", value: "practice", info: "Can be attempted multiple times" },
    { label: "Assignment", value: "assignment", info: "Can be attempted only once" },
];

export const QuestionTypes = [
    {
        value: "technical",
        label: "Technical"
    },
    {
        value: "situational",
        label: "Situational"
    },
];

export const steps = [
    {
        name: "METHOD",
        label: 'Choose creation method',
    },
    {
        name: "TEMPLATE_FILTERS",
        label: 'Enter details',
    },
    {
        name: "QUESTIONS",
        label: 'Add questions',
    },
    {
        name: "TEMPLATE_DETAILS",
        label: 'Save template'
    },
];

export const DifficultyLevels = [
    { label: "Advance", value: "advance" },
    { label: "Intermediate", value: "intermediate" },
    { label: "Easy", value: "easy" },
];

export const FieldConfig = {
    question: {
        schema: yup.string().required("Question is required"),
        placeholder: "Type your Question Here...e.g. Tell me about yourself?",
        label: "Q*",
    },
    expectedAnswer: {
        schema: {
            "technical": yup.string().required("Answer is required"),
            "situational": yup.string().optional(),
        },
        placeholder: "Write your ideal answer here...",
        label: "Ans:*",
    },
    allottedTime: {
        schema: yup
            .number()
            .min(1, "Should be greater than 0")
            .max(10, "Should be less than 10 mins")
            .required("Must be greater than 1 and less than 10mins"),
        placeholder: "",
        label: "Time for question (mins)*",
    },
    resources: {
        schema: yup.string(),
        placeholder: "This learning resource will be visible after the assessment",
        label: "Res:",
    },
    type: {
        schema: yup
            .string()
            .oneOf(QuestionTypes.map(type => type.value))
            .required("Question type is required"),
        placeholder: "",
        label: "Type of question*",
    },
    hint: {
        schema: yup.string(),
        placeholder: "Add a hint... (remember the day we met)",
        label: "Hint:",
    },
    sampleAnswers: {
        schema: yup.array(yup.string().required()).min(0).max(1),
        // label
    },
};

export const AvailableProctorSettings = [
    {
        id: "copyPasteProctoring",
        name: "proctorSettings.copyPasteProctoring",
        label: "Copy-Paste Tracking",
        description: "Enabling this will prohibit student to copy-paste any text/code in the input field.",
        Icon: CopyIcon,
    },
    {
        id: "generalProctoring",
        name: "proctorSettings.generalProctoring",
        label: "Tab Proctoring",
        description: "It will warn user whenever they try to switch tabs or their mouse goes outside of the test window.",
        Icon: TabMoveIcon,
    },
    {
        id: "imageCaptureProctoring",
        name: "proctorSettings.imageCaptureProctoring",
        label: "Image capture",
        description: "Enabling this will allow you to take picture of candidate after every 20 seconds.",
        Icon: ImageIcon,
    },
];

export const InterviewTemplateTypes = {
  PRACTICE : "practice",
  ASSIGNMENT : "assignment",
  FOLLOW_UP : "follow_up",
}