import React from 'react';
import Box from '@mui/material/Box';
import ShadowBox from 'components/ShadowBox';
import UserAnalytics from 'components/BatchAnalytics/UserAnalytics';
import BestPerformers from 'components/BatchAnalytics/BestPerformers';
import BatchInfo from 'components/BatchAnalytics/BatchInfo';
import RecentTemplatesAnalytics from 'components/BatchAnalytics/RecentTemplatesAnalytics';
import BatchStats from 'components/BatchAnalytics/BatchStats';
import CustomButton from 'components/CustomButton';

import { getBatchById } from 'services';
import { makeStyles } from "@mui/styles"
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AddCircleOutline } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  followupContainer: {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#E9F5FF",
    padding: "2rem", rowGap: "1rem",
    border: 'none', marginBottom: 20,
    borderRadius: 4, position: 'relative',
    marginTop: 5,
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "1rem",
    fontSize: "1.5rem",
    fontWeight: "600",
    textTransform: "capitalize",
  },
  subHeading: {
    fontSize: "1rem",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  headingIcon: {
    height: "2rem",
  },
  ecllipse: {
    position: 'absolute',
    right: 0, bottom: 0,
    height: 140
  }
}));

function FollowupCard({ batchId }) {
  const classess = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={classess.followupContainer}>
      <img
        src={'https://assets.languify.in/images/ecllipse.svg'}
        alt="followup" className={classess.ecllipse}
      />
      <Box className={classess.heading}>
        <img
          src={'https://assets.languify.in/images/rocket.svg'}
          alt="Boost" className={classess.headingIcon}
        />
        <span>
          Boost Your student with follow up assessment
        </span>
      </Box>
      <Box className={classess.subHeading}>
        Personalized assessment focusing weak points of every learner
      </Box>
      <CustomButton
        variant="contained"
        startIcon={<AddCircleOutline />}
        onClick={() => navigate(`/batches/${batchId}/create-followup`)}
      >
        Create Follow Up Assessment
      </CustomButton>
    </Box>
  );
}

function BatchAnalytics(props) {
  const { id } = useParams();

  const [batch, setBatch] = React.useState({});

  React.useEffect(() => {
    getBatchById(id, { domain: true })
      .then(template => setBatch(template))
      .catch(console.error)
  }, [id]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight='calc(100vh - 56px)'
      maxWidth='calc(100% - 8px)'
      pb={6}
    >
      <BatchInfo batch={batch} />
      <Box display="flex" gap={2}>
        <BestPerformers />
        <BatchStats />
      </Box>

      <FollowupCard batchId={id} />

      <ShadowBox sx={{ mt: 0 }}>
        <RecentTemplatesAnalytics />
        <UserAnalytics batch={batch} />
      </ShadowBox>
    </Box>
  );
}

export default BatchAnalytics;