import React from 'react';

import { useNavigate } from "react-router-dom";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Logout from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { signOut } from 'services';

function LogoutButton(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleLogout = () => {
    signOut();
    navigate(`/auth/login`);
  };

  return (
    <div>
      <MUIMenuItem onClick={handleClick} style={{ mx: 'auto' }}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MUIMenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box p={2} minWidth={140}>
          <Typography mb={1}>Confirm?</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="success"
              size="small"
              startIcon={<CheckIcon />}
              onClick={handleLogout}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<ClearIcon />}
              onClick={handleClose}
              size="small"
            >
              No
            </Button>
          </Stack>
        </Box>
      </Popover>
    </div>
  );
}

export default LogoutButton;