import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Link, Button, Stack, Skeleton } from '@mui/material';
import useStore from './store';
import shallow from 'zustand/shallow';
import { useSnackbar } from 'contexts';
import { getFeedbackAndAnswer } from 'services/interviewFeedback';


const sx = {
    root:{
        backgroundColor: "#E3F4FF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        border: "3px solid #02569D",
        borderRadius: "5px",
        padding: "10px"
    },
    feedbackHeading: {
        color: "#02569D",
        fontSize: "15px",
        fontWeight: "600"
    },
    idealAnswer: {
        fontWeight: 500,
        fontSize: 14,
        mb: 1,
    },
    generateBtn:{
        display: "inline-flex",
        color: "#02569D",
        fontSize: "12px",
        fontWeight: "600",
        border: "1px solid #02569D",
        borderRadius: "5px",
        p: "5px",
    }
};

function PersonalizedAnswer({
    personalizedAnswer
}) {
    return personalizedAnswer ? (
        <Box>
            <Typography sx={sx.feedbackHeading} mb={1}>
                Personalized Answer
            </Typography>
            <Typography sx={sx.idealAnswer}>
                { personalizedAnswer || ''}
            </Typography>
        </Box>
    )
    : null
}


function PersonalizedFeedback({question}) {
    const snackbar = useSnackbar();
    const {
        answerId,
    } = useStore(state => state.questions?.[state.currentQuestion]);
    const currentQuestion = useStore(state => state.currentQuestion, shallow);
    const questions = useStore(state => state.questions, shallow);
    const setQuestions = useStore(state => state.setQuestions, shallow);
    const template = useStore(state => state.template, shallow);
    const [isLoading, setLoading] = React.useState(false);

    const scrollToDetailedFeedback = (targetId) => {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const generateFeedback=async()=>{
        if(!answerId) return
        setLoading(true);
        try {
            const result = await getFeedbackAndAnswer(
                answerId,
                template?.domain,
                template.pathway ? true : false
              );
            if(result){
                const updatedQuestions = [...questions];
                updatedQuestions[currentQuestion].personalizedAnswer = result.personalizedAnswer;
                updatedQuestions[currentQuestion].content.feedback = result.personalizedFeedback;
                setQuestions(updatedQuestions);
            }
        } catch (error) {
            console.error("generate feedback error :- ", error);
            snackbar.error('Oo oh! unable to generate feedback, try again');
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(()=>{
        if( !question?.isError && (!question?.content?.feedback || !question?.personalizedAnswer) ){
            generateFeedback();
        }
        else{
            setLoading(false);
        }
    },[currentQuestion, answerId]);

    return !isLoading?  (
        (!question?.isError && question?.content?.feedback && question.personalizedAnswer !== undefined) ? 
        
        <Box 
            id="personalized-feedback-container" 
            display="flex" 
            flexDirection={"column"} 
            mb={2} 
            gap={1} 
            sx={sx.root}
        >
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography id="personalized-feedback" sx={sx.feedbackHeading}>
                    Personalized Feedback
                </Typography>
                <Link onClick={e=> scrollToDetailedFeedback("detailed_feedback")} 
                    sx={sx.feedbackHeading} 
                    style={{cursor:"pointer"}}>
                    Detailed Analysis
                </Link>
            </Box>
            <Box>
                <Typography sx={sx.idealAnswer}>
                    {question?.content?.feedback}
                </Typography>
            </Box>
            
            <PersonalizedAnswer
                personalizedAnswer={question.personalizedAnswer}
            />
        </Box>
        :
        !question?.isError? 
            // try again
            <Box>
                <Button
                    sx={sx.generateBtn} 
                    style={{cursor:"pointer", textTransform:"capitalize"}}
                    onClick={generateFeedback}
                >
                    Retry to Generate Feedback
                </Button>
            </Box> : null
    ) : 
    
    <Stack spacing={1}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="rectangular" width={200} height={20} />
        <Skeleton variant="rectangular" width={"100%"} height={60} />
        {
        question?.type !== "technical" ?
        <>
            <Skeleton variant="rectangular" width={200} height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
        </>
        : null
        }
        
    </Stack>
    ;
}

export default PersonalizedFeedback;