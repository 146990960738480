import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { getBatches, getQuickHiglights } from "services";
import { Session } from "utils";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "components/Select";
import achieverImg from "../../assets/quickHighlight/achiever.svg";
import documentImg from "../../assets/quickHighlight/Document_4.svg";
import loserImg from "../../assets/quickHighlight/loser.svg";
import studyImg from "../../assets/quickHighlight/study.svg";
import HorizontalScrollNavigator from "components/HorizontalScrollNavigator";
import lock from "../../assets/quickHighlight/lock.svg"

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        overflow: "scroll",
        height: 150,
        columnGap: "2rem",
        padding: '8px 0px'
    },
    card: {
        display: "flex",
        columnGap: "2rem",
        height: "100%",
        borderRadius: "0.45rem",
        width: "fit-content",
        padding: "2.75rem",
        justifyContent: "center",
        alignItems: "center"
    },
    cardBody: {
        display: "flex",
        flexDirection: "column",
        rowGap: "0.75rem",
    },
    cardTitle: {
        fontWeight: "500",
        textTransform: "capitalize",
    },
    cardImageContainer: {
        height: "100%",
        aspectRatio: "1",
    },
    cardImage: {
        height: "100%",
        objectFit: "cover",
    },
    unlockCard: {
        display: 'flex',
        flexDirection: "row",
        background: "#1767A7",
        padding: 0,
        margin: '24px 0px',
        color: "white",
        borderRadius: 8,
        height: 150,
        boxShadow: '4px -4px 8px 0px rgba(0, 0, 0, 0.25) inset',
    },
    heading: {
        fontSize: "24px",
        fontWeight: "600",
        textTransform: "capitalize",
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    subHeading: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#F4F5F5",
        lineHeight: '26px',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: 18,
        rowGap: 12,
    }
}));

const QuickHighlightDetails = {
    'ACTIVE_ASSESSMENT': { icon: documentImg, bgColor: '#FFE9E1' },
    'TOPPER': { icon: achieverImg, bgColor: '#F0D7ED' },
    'TAIL_ENDER': { icon: loserImg, bgColor: '#F5F3E4' },
    'MOST_DEDICATED': { icon: studyImg, bgColor: '#EAEFE8' }
}

function HighlightsCard({ primary, secondary, bgColor, imageSrc }) {
    const { card, cardImage, cardImageContainer, cardTitle, cardBody } = useStyles()
    return (
        <Box bgcolor={bgColor} className={card}>
            <Box className={cardBody}>
                <Typography variant="h5" className={cardTitle}>{primary}</Typography>
                <Typography variant="body1" whiteSpace={"nowrap"} color='#292929'>
                    {secondary}
                </Typography>
            </Box>
            <Box className={cardImageContainer}>
                <img src={imageSrc} alt="" className={cardImage} />
            </Box>
        </Box>
    );
}

function CardCarasoul({ items }) {
    const { container } = useStyles();
    return <HorizontalScrollNavigator className={container}>
        {
            items.map((item) => (
                <HighlightsCard
                    primary={item.title}
                    secondary={item.subtitle}
                    bgColor={item.bgColor}
                    imageSrc={item.icon}
                ></HighlightsCard>
            ))
        }
    </HorizontalScrollNavigator>
}

function QuickHighlight() {
    const { unlockCard, heading, subHeading, content } = useStyles();
    const [batches, setBatches] = useState([]);
    const [filter, setFilter] = useState('');
    const [items, setItems] = useState([]);

    const handleFilterChange = (e) => setFilter(e.target.value);

    useEffect(() => {
        getBatches({ client: Session.userId })
            .then(({ batches }) => { setBatches(batches); })
            .catch((error) => { console.error(error); })
    }, []);

    useEffect(() => {
        setFilter(batches[0]?._id || '')
    }, [batches]);

    useEffect(() => {
        (async () => {
            const quickHighlights = await getQuickHiglights(filter);

            const _items = quickHighlights.map((qhData) => {
                const id = qhData.id;
                return { ...qhData, ...QuickHighlightDetails[id] }
            });

            setItems(_items);
        })()
    }, [filter])

    return (
        <Box width={'100%'}>
            <Box display="flex"
                py={1}
                alignItems={"center"}
                columnGap={"1.5rem"}>
                <Typography variant="h5" fontWeight={600}>
                    Quick Highlights
                </Typography>
                <Select
                    options={batches}
                    value={filter}
                    disabled={batches.length === 0}
                    onChange={handleFilterChange}
                />
            </Box>

            {
                (items.length) ?
                    <CardCarasoul items={items} /> :
                    <Box className={unlockCard}>
                        <Box className={content}>
                            <Box className={heading}>
                                Unlock Quick Highlights
                            </Box>
                            <Box className={subHeading}>
                                Quick Highlights is almost there! We just need 5 students to take an
                                attempt in this batch. Simple, right? Help us out – ask your students
                                to take more attempts and let the insights flow! 🌟
                            </Box>
                        </Box>
                        <img src={lock} alt="lock" style={{ borderRadius: '0px 8px 8px 0px' }} />
                    </Box>
            }

        </Box>
    )
}




export default QuickHighlight