import React from 'react';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

export const PrimaryActionVariants = {
  error: 'error', success: 'success', warning: 'warning', info: 'info',
};

export function ActionButtons({
  loading, primaryAction, secondaryAction, onPrimaryClick, onSecondaryClick,
  primaryActionVariant, secondaryActionVariant, primaryFirst, primaryType,
  secondaryType, ...props
}) {
  return (
    <Box display='flex' alignItems="center" width="fit-content" {...props}>
      {primaryFirst && (
        <>
          <LoadingButton
            fullWidth
            loading={loading}
            style={{ textTransform: 'none', borderRadius: 8 }}
            size="small"
            type={primaryType}
            onClick={onPrimaryClick}
            variant='contained'
            color={PrimaryActionVariants[primaryActionVariant] || 'primary'}
          >
            {primaryAction}
          </LoadingButton>
          &nbsp;&nbsp;
        </>
      )}
      <LoadingButton
        fullWidth
        style={{ textTransform: 'none', borderRadius: 8 }}
        size="medium"
        onClick={onSecondaryClick}
        type={secondaryType}
        color={PrimaryActionVariants[secondaryActionVariant] || 'primary'}
        variant='outlined'
      >
        {secondaryAction}
      </LoadingButton>
      {!primaryFirst && (
        <>
          &nbsp;&nbsp;
          <LoadingButton
            fullWidth
            loading={loading}
            style={{ textTransform: 'none', borderRadius: 8 }}
            size="medium"
            onClick={onPrimaryClick}
            variant='contained'
            color={PrimaryActionVariants[primaryActionVariant] || 'primary'}
          >
            {primaryAction}
          </LoadingButton>
        </>
      )}
    </Box>
  );
}

export default ActionButtons;