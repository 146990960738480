import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import theme from "theme";
import NotFound from "./NotFound";

import PrivateRoute from "components/PrivateRoute";
import SessionHelper from "components/SessionHelper";
import { LoadingProvider, SnackbarProvider, TourProvider } from "contexts";
import Batches from "routes/Batches";
import Home from "routes/Home";
import Settings from "routes/Settings";
import Students from "routes/Students";
import Templates from "routes/Templates";
import PersonalizedInterview from "routes/PersonalizedInterview";
import Auth from "./Auth";
import DeactivatedProfile from "./DeactivatedProfile";
import Workspace from "./Workspace.jsx";
import ErrorBoundary from "../components/ErrorBoundary";

export default function Screen() {
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <ThemeProvider theme={theme}>
                    <LoadingProvider>
                        <TourProvider>
                            <SnackbarProvider>
                                <SessionHelper>
                                    <Routes>
                                        <Route index path='/home/*' element={<PrivateRoute component={Home} />} />
                                        <Route path='/batches/*' element={<PrivateRoute component={Batches} />} />
                                        <Route path='/assessments/*' element={<PrivateRoute component={Templates} />} />
                                        <Route path='/students/*' element={<PrivateRoute component={Students} />} />
                                        <Route path='/settings/*' element={<PrivateRoute component={Settings} />} />
                                        <Route
                                            path='/personalizedInterview/*'
                                            element={<PrivateRoute component={PersonalizedInterview} />} />
                                        <Route path='/auth/*' element={<Auth />} />
                                        <Route path='/deactivated' element={<DeactivatedProfile />} />
                                        <Route path="/workspace" element={<Workspace />} />
                                        <Route path='/' element={<Navigate to='/home' />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </SessionHelper>
                            </SnackbarProvider>
                        </TourProvider>
                    </LoadingProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </BrowserRouter>
    );
}