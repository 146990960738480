import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ShadowBox from "./ShadowBox";
import BackButton from "./BackButton";

const useStyles = makeStyles({
  screenHeadingRoot: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '4px solid #02569d',
    backgroundColor: 'white',
  },
  contentTitle: {
    flexShrink: 0,
    minWidth: 200,
    alignSelf: "center",
  },
  contentActions: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    paddingRight: 16,
  }
});

function ScreenHeading({ id = '', title, children, hideBack = false }) {
  const classes = useStyles();

  return (
    <ShadowBox
      className={classes.screenHeadingRoot}
      style={{ borderRadius: 4, padding: hideBack ? 16 : 0 , marginBottom : 0 }}
    >
      {!hideBack && <BackButton />}
      <Typography
        id={id}
        className={classes.contentTitle}
        fontSize={18}
        fontWeight={600}
        component="div"
        textTransform='uppercase'
        letterSpacing={2}
      >
        {title}
      </Typography>
      <Box className={classes.contentActions}>
        {children}
      </Box>
    </ShadowBox>
  );
}

export default ScreenHeading;