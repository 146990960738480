import React from 'react';
import { debounce } from 'lodash';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { TextField } from 'components/TextField';

function DebouncedTextField({
  label = "",
  placeholder = "Search...",
  onChange,
  InputProps,
  startIcon,
  endIcon,
  ...props
}) {
  const handleChange = (e) => onChange(e.target.value);

  const handleInput = debounce(handleChange, 500);

  return (
    <TextField
      {...props}
      label={label}
      placeholder={placeholder}
      sx={{ maxWidth: props?.width || 200, ...props?.sx }}
      InputProps={{
        ...InputProps,
        startAdornment: startIcon ? (
          <SearchOutlinedIcon fontSize="small" style={{ marginRight: 3 }} />
        ) : null,
        endAdornment: endIcon ? (
          <SearchOutlinedIcon fontSize="small" />
        ) : null,
      }}
      onInput={handleInput}
    />
  );
}

export default DebouncedTextField;