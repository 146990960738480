import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';

import { ActionButtons } from "components/ActionButtons";
import { Box, Typography } from '@mui/material';


const useStyles = makeStyles({
  paper: {
    borderRadius: 16,
    padding: 8,
  }
});


export function ConfirmationDialog({
  open,
  onPrimaryClick,
  onSecondaryClick,
  primaryActionVariant,
  secondaryActionVariant,
  title = "Confirm",
  message = "Are you sure?",
  multipleMessage = null,
  primaryAction = "Yes",
  secondaryAction = "No",
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onPrimaryClick}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        textAlign='center'
        fontWeight={600}
        fontSize={24}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 300, maxWidth: 500 }}>
        <DialogContentText>
          {
            multipleMessage ?
              <Box display='flex' flexDirection='column' gap='10px'>
              {
                multipleMessage.map((data)=>{
                  return (
                    <Typography color='#000'>
                    {data}
                  </Typography>
                  )
                })
              }
              </Box>
              : message
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButtons
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          onSecondaryClick={onSecondaryClick}
          onPrimaryClick={onPrimaryClick}
          primaryActionVariant={primaryActionVariant}
          secondaryActionVariant={secondaryActionVariant}
          width="100%"
        />
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;