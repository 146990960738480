import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FieldWrapper from './FieldWrapper';
import TextField from 'components/TextField';
import Box from '@mui/material/Box';

function AllowedAttempts({ disabled, value, onChange, error, helperText, width }) {
  const handleChange = (e) => {
    onChange({
      target: { name: e.target.name, value: e.target.checked ? -1 : 2 }
    });
  };

  const checked = value === -1;

  return (
    <FieldWrapper
      title="Number of Attempts*"
      description="This field determines how many times a student can take the assessment before it expires"
      width={width ?? undefined}
    >
      <FormControlLabel
        disabled={disabled}
        label="Unlimited attempts"

        control={<Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          checked={checked}
          onChange={handleChange}
          name="maxAllowedAttempts"
        />}
      />
      <TextField
        disabled={disabled}
        type="number"
        name="maxAllowedAttempts"
        defaultValue={3}
        style={{ maxWidth: 400, visibility: checked ? 'hidden' : 'visible' }}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
    </FieldWrapper >
  );
}

export default AllowedAttempts;