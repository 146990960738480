import React from "react";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import * as Yup from "yup";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useFormik } from "formik";
import { ActionButtons } from "components/ActionButtons";
import { useLoading, useSnackbar } from "contexts";
import { TemplateTypes } from "editors/TemplateEditor/constants";
import { duplicateTemplate } from "services";
import { Session } from "utils";
import DatePickerField from "editors/TemplateEditor/DatePickerField";
import BatchSelection from "editors/TemplateEditor/BatchSelection";
import TextField from "components/TextField";
import FieldWrapper from "editors/TemplateEditor/FieldWrapper";
import OptionChips from "editors/TemplateEditor/OptionChips";
import { useSearchParams } from "react-router-dom";
import AllowedAttempts from "editors/TemplateEditor/AllowedAttempts";

dayjs.extend(advancedFormat);

const ValidationSchema = Yup.object({
  name: Yup.string().trim().required("Give a name to your assessment"),
  type: Yup.string().required("Select type of assessment"),
  batch: Yup.string().required("Select the batch"),
  publishOn: Yup.date().required(),
  expiresOn: Yup.date().required(),
  maxAllowedAttempts: Yup.number()
    .when("type", {
      is: (type) => type === "practice",
      then: () => Yup.number().max(10, "Cannot be more than 10"),
      otherwise: () => Yup.number(),
    })
    .required("Required")
    .notOneOf([0], "Cannot be zero"),
});

export function DuplicateTemplateDialog({
  template,
  open,
  onClose,
  onSuccess,
}) {
  const loading = useLoading();
  const snackbar = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    handleSubmit,
    values,
    handleChange,
    setValues,
    errors,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: template,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      loading.show();
      duplicateTemplate(template._id, { ...values, user: Session.userId })
        .then(() => {
          snackbar.success("Duplicated template successfully!");
          onSuccess();
        })
        .catch((e) => snackbar.error(e.message))
        .finally(() => loading.hide());
    },
  });

  React.useEffect(() => {
    if (template && setValues) {
      setValues({
        name: template.name.concat(" - copy"),
        type: template.type,
        batch: searchParams.get("fb") || "",
        publishOn: new Date(),
        expiresOn: template.expiresOn,
        maxAllowedAttempts: template.maxAllowedAttempts || -1,
      });
    }
  }, [template, setValues]);

  const handleDateChange = React.useCallback(
    (name, date) => setFieldValue(name, date),
    [setFieldValue]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="responsive-dialog-title">
        Duplicate - {template.name}
        <br />
        <Typography variant="caption" component="div">
          All the questions & other details will be duplicated
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container pt={0}>
          <FieldWrapper
            title="Assessment name*"
            description="Give a name to this assessment, Students will see this as assessment heading"
          >
            <TextField
              name="name"
              placeholder="Enter name..."
              style={{ maxWidth: 400 }}
              value={values?.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.name && errors.name}
              helperText={touched.name && errors.name}
            />
          </FieldWrapper>
          <FieldWrapper
            title="Assessment Type*"
            description="Select the type of assessment"
          >
            <OptionChips
              options={TemplateTypes}
              name="type"
              onChange={handleChange}
              defaultValue={values.type}
            />
          </FieldWrapper>
          <BatchSelection
            onChange={handleChange}
            value={values?.batch}
            error={touched.batch && errors.batch}
            helperText={touched.batch && errors.batch}
          />
          <AllowedAttempts
            disabled={values?.type === "assignment"}
            onChange={handleChange}
            value={values?.maxAllowedAttempts}
            error={touched.maxAllowedAttempts && errors.maxAllowedAttempts}
            helperText={touched.maxAllowedAttempts && errors.maxAllowedAttempts}
          />
        </Grid>

        <Grid container pt={0}>
          <DatePickerField
            name="publishOn"
            label="Publish On*"
            value={values.publishOn}
            onChange={handleDateChange}
            defaultValue={dayjs().toISOString()}
          />
          <DatePickerField
            name="expiresOn"
            label="Expires On*"
            value={values.expiresOn}
            onChange={handleDateChange}
            disabled={!!!values.publishOn}
            minDate={values?.publishOn ? values.publishOn : undefined}
            defaultValue={values.expiresOn}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ActionButtons
          primaryAction={"Duplicate"}
          secondaryAction={"Cancel"}
          onSecondaryClick={onClose}
          onPrimaryClick={handleSubmit}
          primaryActionVariant={"contained"}
        />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateTemplateDialog;
