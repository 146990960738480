export const TransactionType = {
  CREDIT : "credit",
  DEBIT : "debit",
  REFUND : "refund"
}

export const TransactionStatus = {
  COMPLETED : "completed",
  PENDING : "pending",
  FAILED : "failed"
}

