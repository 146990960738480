import Box from '@mui/material/Box';
import React from 'react';
import ShadowBox from 'components/ShadowBox';
import BestPerformers from 'components/TemplatePerformance/BestPerformers';
import QuestionsAnalytics from 'components/TemplatePerformance/QuestionsAnalytics';
import TemplateInfo from 'components/TemplatePerformance/TemplateInfo';
import TemplateStats from 'components/TemplatePerformance/TemplateStats';
import UsersAnalytics from 'components/TemplatePerformance/UsersAnalytics';
import Divider from "@mui/material/Divider";

import { useParams } from 'react-router-dom';
import { getTemplateById } from 'services';

function TemplatePerformance(props) {
  const { id } = useParams();

  const [template, setTemplate] = React.useState({});

  React.useEffect(() => {
    getTemplateById(id, { domain: true, batch: true })
      .then(template => setTemplate(template))
      .catch(console.error)
  }, [id]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight='calc(100vh - 56px)'
      maxWidth='calc(100% - 8px)'
      pb={6}
    >
      <TemplateInfo template={template} />
      <Box display="flex" mb={2} gap={2}>
        <BestPerformers batch={template?.batch?._id} />
        <TemplateStats template={template} />
      </Box>
      <ShadowBox sx={{ mt: 0 }}>
        {
          (template.type !== 'follow_up') ?
            <>
              <QuestionsAnalytics template={template} />
              <br />
              <Divider light flexItem />
            </> :
            null
        }
        <UsersAnalytics
          batch={template?.batch?._id}
          template={template}
          forTemplateAnalytics={true}
        />
      </ShadowBox>
    </Box>
  );
}

export default TemplatePerformance;