import { Box } from '@mui/material'
import TemplateInfo from 'components/TemplatePerformance/TemplateInfo';
import React from 'react'
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ShadowBox from 'components/ShadowBox';
import TemplateStats from 'components/TemplatePerformance/TemplateStats';
import UsersAnalytics from 'components/TemplatePerformance/UsersAnalytics';
import BestPerformers from 'components/TemplatePerformance/BestPerformers';
import { getPersonalizedInterview } from 'services';

const useStyle = makeStyles(theme=>({
    boxContainer:{
        display:"flex",
        flexDirection:"column",
        minHeight:'calc(100vh - 56px)',
        maxWidth:'calc(100% - 8px)',
        paddingBottom:theme.spacing(6)
    },
    statsContainer:{
        display:'flex',
        marginBottom:theme.spacing(2),
        gap:theme.spacing(2)
    }
}))

const PersonalizedInterviewPerformance = () => {
    const { id } = useParams();
    const classes = useStyle()

    const [template, setTemplate] = React.useState({});
  
    React.useEffect(() => {
      getPersonalizedInterview({_id: id})
        .then(({templates}) => setTemplate(templates[0]))
        .catch(console.error)
    }, [id]);

  return (
    <Box className={classes.boxContainer}>
      <TemplateInfo template={template} />
      <Box className={classes.statsContainer}>
        <BestPerformers batch={template?.batch?._id} forPersonalizedTemplate={true}/>
        <TemplateStats template={template} forPersonalizedTemplate={true}/>
      </Box>
      <ShadowBox sx={{ mt: 0 }}>
        <UsersAnalytics
          batch={template?.batch?._id}
          template={template}
          forTemplateAnalytics={true}
          forPersonalizedTemplate={true}
        />
      </ShadowBox>
    </Box>
  )
}

export default PersonalizedInterviewPerformance;