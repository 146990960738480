import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CenterFlexBox from 'components/CenterFlexBox';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import { DummyContentFeedback, ContentTips, Tags } from './constants';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    errorBox: {
        // backgroundColor:"#E3F4FF",
        display: "flex",
        minHeight: "230px",
        [theme.breakpoints.down(980)]: {
            flexDirection: "column"
        }
    },
}))

const sx = {
    root: {
        width: '100%',
        position: "relative",
        px: 2,
        mt: "25px"
    },
    flexBox: {
        display: 'flex',
        flexDirection: "column",
        gap: 2,
    },
    number: {
        border: "1px solid white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 22,
        height: 22,
        borderRadius: 22,
        ml: 1,
        backgroundColor: "white",
        color: "#02569D",
        fontFamily: "inter",
        fontSize: 12,
        fontWeight: 600,
    },
    subheadings: {
        color: "#02569D",
        fontSize: "13px",
        fontWeight: "600",
        mb:"2px"
    },
    description: {
        color: "#5B5C5C",
        fontSize: "13px",
        fontWeight: "500"
    },
}

function Tip({ tip }) {
    return (
        <Typography sx={sx.description}>
            {tip}
        </Typography>
    );
}

function AnalysisError() {
    const classes = useStyles();
    return (
        <CenterFlexBox className={classes.errorBox} p={2} gap={4}>
            <img
                src={"https://assets.languify.in/images/speaker-in-pc.svg"}
                alt="Happy Student"
                height={100}
            />
            <div>
                <Typography fontWeight={600} fontSize={16} lineHeight={"100%"}>
                    Parameter-wise analysis<br />not generated
                </Typography> <br />

                <Typography fontWeight={500} fontSize={12} >
                    Either you didn’t attempt the question <br />
                    or your answer was not recorded properly
                </Typography>
            </div>
        </CenterFlexBox>
    );
}

export default function ContentFeedback({
    feedback = DummyContentFeedback,
    error, category = '', score = 0
}) {

    const level = React.useMemo(() => (
        score >= 70 ? "high" : score >= 40 ? "okay" : "low"
    ), [score]);

    return (
        <Box sx={sx.root}>
        {error? <AnalysisError/> :
        <>
            <Box>
                <Typography color= "#02569D" fontSize= "15px" fontWeight= "600">
                    Parameter wise Analysis
                </Typography>
            </Box>
            <Box mt="8px">
                <Typography sx={sx.subheadings} style={{textTransform: "capitalize"}}>
                    {category.toLowerCase().replace("_", " ")}
                </Typography>
                <Typography sx={sx.description}>
                    { Tags?.[category] }                
                </Typography>
            </Box>
            <Box mt="8px">
                <Typography sx={sx.subheadings}>
                <AutoFixHighIcon sx={{ fontSize: "14px" }}/> Tips to improve
                </Typography>
                {  ContentTips?.[category]?.[level]?.map((tip, i) => (
                    <Tip key={i} tip={tip} />
                ))}
            </Box>
        </>}
        </Box>
    );
}