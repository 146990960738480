import Session from "../utils/Session";
import Client from "./_client";

export const restoreSession = async () => {
  const result = await new Client({
    path: "/auth/restore",
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to restore you session. Please sign in."
    );
  } else {
    Session.onRestoreSession(result.data);
    return result;
  }
};

export const signOut = async () => {
  Session.clearSession();
};

export const signIn = async ({ email, password, role }) => {
  let url = '/signin?';

  const result = await new Client({
    path: url,
    payload: { email, password, role },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
};

export const overwriteSession = async () => {
  console.log(Session.userId);
  const result = await new Client({
    path: `/auth/overwrite-session`,
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || "Uh Oh! Unable to log you in.");
  }
};

export const generateResetPasswordLink = async (email) => {
  const result = await new Client({
    path: "/auth/reset-password-link",
    payload: {
      email: email,
      redirectUrl: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to generate reset password link.');
  }
  return result.message;
}

export const resetPassword = async (password, token) => {
  const result = await new Client({
    path: "/auth/reset-password",
    payload: {
      token,
      password
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to reset your password.');
  }
  return result.message;
}