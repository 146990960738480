export const SubscriptionModel = {
  PAY_PER_USER: "pay_per_user",
  PAY_PER_ATTEMPT: "pay_per_attempt"
}

export const AccessControl = {
  GRANT: 'grant',
  UPGRADE: 'upgrade',
  RESUME: 'resume',
}

export const AccessControlData = {
  [AccessControl.GRANT]: {
    title: "Invite Students",
    description: "Invite multiple user one-by-one or import from google sheets/ CSV file",
    buttonText: "Invite Students",
    instruction: "*By inviting students, they will receive a white-labeled email containing their credentials for accessing the user portal."
  },
  [AccessControl.UPGRADE]: {
    title: "Update Subscription",
    description: "Review students and increase their access for the Batch",
    buttonText: "Confirm Changes",
  },
  [AccessControl.RESUME]: {
    title: "Add Back",
    description: "Review students and add them back in the Batch",
    buttonText: "Add Students",
    instruction: "*By adding students, they will receive a white-labeled email containing their credentials for accessing the user portal."
  }
}