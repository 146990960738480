import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';

import TextField from 'components/TextField';
import PasswordField from 'components/PasswordField';
import { signIn } from "services";
import { useSnackbar } from 'contexts';
// import Toggle from 'components/Toggle';
// import CenterFlexBox from 'components/CenterFlexBox';
import { Box, Typography } from '@mui/material';

// const ClientRoles = [
//   { id: "CLIENT", label: "In-Prep" },
//   { id: "SEP_CLIENT", label: "SEP" },
// ];

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Password must be 8 characters or more')
    .required('Required')
});

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await signIn({
          email: values.email, password: values.password,
          // Uncomment this once SEP client dashboard is added
          // role: values.role          
          role: "CLIENT"
        });
        navigate('/home');
      } catch (error) {
        snackbar.showSnackbar(error.message, 'error', false);
      } finally {
        setLoading(false);
      }
    }
  });

  // const handleRoleChange = React.useCallback((option) => {
  //   formik.setFieldValue('role', option.id)
  // }, [formik]);

  return (
    <Box width='60%'>
      <Typography
        color='black' fontFamily='inter'
        fontWeight={600} fontSize={32} mb={4}
      >
        Login
      </Typography>
      <form onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>

        <TextField
          id="email"
          label="Email"
          variant="outlined"
          sx={{ width: '100%' }}
          value={formik.values.email}
          spellCheck="false"
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email}
          helperText={(formik.touched.email && formik.errors.email)}
        />
        <PasswordField
          id="password"
          name="password"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          spellCheck="false"
          error={formik.touched.password && formik.errors.password}
          helperText={(formik.touched.password && formik.errors.password)}
          sx={{ width: '100%', }}
        />

        <Typography
          fontFamily='inter' alignSelf='flex-end' style={{ cursor: 'pointer' }}
          fontSize={14} fontWeight={500}
        >
          <a
            href='/auth/reset-link'
            style={{ color: '#02569d', textDecoration: 'none' }}
          >
            Forgotten password?
          </a>
        </Typography>

        <LoadingButton
          sx={{ mt: 5, textTransform: "none", width: 100 }}
          loading={loading}
          variant="contained"
          type='submit'
          size="small"
        >
          Log in
        </LoadingButton>
      </form>
    </Box>
  );
}