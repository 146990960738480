import React from 'react';
import { Outlet, Route, Routes } from "react-router-dom";
import AppShell from 'components/AppShell';
import SettingsScreen from 'screens/Settings';

function Settings(props) {
  return (
    <Routes>
      <Route path="/" element={<SettingsLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <SettingsScreen />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default Settings;

function SettingsLayout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}