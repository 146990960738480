import * as React from 'react';

import ArrowForward from '@mui/icons-material/ArrowForward';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import MUITabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import useStore from './store';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'contexts';
import { getPersonalizedAnswer } from 'services/interviewFeedback';

const sx = {
    label: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 500,
    },
    button: {
        p: "4px 10px",
        height: 'fit-content',
        background: "#FFFFFF",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "3px",
        fontSize: 16,
        fontWeight: 600,
        color: '#02569D',
        "&:hover": {
            background: "#FFFFFF",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
            cursor: "default"
        }
    },
    disabled: {
        p: "4px 10px",
        height: 'fit-content',
        background: "transparent",
        boxShadow: "none",
        fontSize: 16,
        fontWeight: 600,
        color: 'black',
        "&:hover": {
            background: "transparent",
        }
    },
    secondaryTab: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 2,
        marginRight: 2,
        position: "absolute",
        right: 0,
        top: 4,
    },
    editable: {
        padding: 14,
        height: 100,
        background: "#FAFAFB",
        outline: "0px solid transparent",
    }
}

function TabPanel({ answer = "", value }) {
    return (
        <MUITabPanel value={value.toString()} sx={{padding: "24px 24px 5px 24px"}}>
            <Typography component="div" id="sample-answer"
                style={{ fontSize: "15px" }}
            >
                {answer}
            </Typography>
        </MUITabPanel>
    );
}

function GeneratePersonalizedAnswer({
    personalizedAnswer, value, answerId, questionIndex
}) {
    const snackbar = useSnackbar();

    const [loading, setLoading] = React.useState(false);
    const [questionAnswerMap, setQuestionAnswerMap] = React.useState({});

    React.useEffect(() => {
        if (personalizedAnswer) setQuestionAnswerMap({
            ...questionAnswerMap, [questionIndex]: personalizedAnswer
        });
    }, [personalizedAnswer, questionIndex]);

    const generatePersonalizedAnswer = async () => {
        setLoading(true);
        getPersonalizedAnswer(answerId)
            .then((pa) => { setQuestionAnswerMap({ ...questionAnswerMap, [questionIndex]: pa }) })
            .catch((error) => {
                console.error(error);
                snackbar.error('Oo oh! unable to generate personalised answer, try again');
            })
            .finally(() => setLoading(false))
    }

    return questionAnswerMap[questionIndex] ? (
        <TabPanel answer={questionAnswerMap[questionIndex] || ''} value={value} />
    ) : (
        <MUITabPanel value={value} >
            <Box bgcolor="#F4F5F5" p={2} borderRadius={1}>
                <Typography color="GrayText" fontWeight={500}>
                    You can generate one personalized answer for this question.
                    Click on generate personalized answer button.
                </Typography>
                <br />
                <LoadingButton
                    endIcon={<ArrowForward />}
                    loading={loading}
                    variant="outlined"
                    sx={{ textTransform: "none" }}
                    onClick={generatePersonalizedAnswer}
                >
                    Generate Personalized Answer
                </LoadingButton>
            </Box>
        </MUITabPanel>
    );
}

export default function SampleAnswerForm() {
    const {
        expectedAnswer,
        type
    } = useStore(state => state.questions?.[state.currentQuestion]);

    const [value, setValue] = React.useState("0");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isSituational = type === "situational";

    return (
        <Box
            sx={{ width: 'auto', mt: 2 }}
            id="sample-answer-container"
        >
            <TabContext value={value}>
                <Box sx={{ borderBottom: 0.5, borderColor: 'divider', position: "relative" }}>
                    <TabList onChange={handleChange}>
                        {<Tab
                            label={isSituational ? "Sample Answer" : "Ideal Answer"}
                            value={"0"}
                            sx={sx.label}
                        />}
                    </TabList>
                </Box>
                <TabPanel value={"0"} answer={expectedAnswer} />
            </TabContext>
        </Box>
    );
}